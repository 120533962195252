function Logo(){
    return(
        <svg viewBox="0 0 1644 300" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M212.374 105.963L156.64 127.982L100.906 105.963C100.906 105.963 104.347 76.3761 121.204 47.4771C138.062 18.578 156.64 0 156.64 0C156.64 0 175.906 18.578 192.076 47.4771C208.246 76.3761 212.374 105.963 212.374 105.963Z" fill="#4169E1"/>
            <path d="M8.0164 91.5138L133.934 141.055C133.934 141.055 108.819 159.289 92.9935 187.5C77.1678 215.711 75.4476 247.706 75.4476 247.706C75.4476 247.706 29.6907 233.257 9.39255 183.028C-10.9056 132.798 8.0164 91.5138 8.0164 91.5138Z" fill="#4169E1"/>
            <path d="M104.347 300L237.833 247.706C237.833 247.706 282.558 231.881 303.2 184.06C323.842 136.239 305.264 91.5138 305.264 91.5138L172.466 143.463C172.466 143.463 127.843 157.569 106.411 207.454C84.9786 257.339 104.347 300 104.347 300Z" fill="#4169E1"/>
            <path d="M184.851 290.711L215.127 279.014C215.127 279.014 213.062 287.271 212.374 289.679C211.686 292.087 208.934 300 208.934 300L184.851 290.711Z" fill="#4169E1"/>
            <path d="M545.131 92.2962C545.131 86.7734 549.608 82.2962 555.131 82.2962H580.937C586.46 82.2962 590.937 86.7734 590.937 92.2962V268.889C590.937 274.411 586.46 278.889 580.937 278.889H550.806C545.283 278.889 540.806 274.411 540.806 268.889V254.511C535.432 263.292 528.289 270.435 519.377 275.94C510.596 281.313 500.177 284 488.119 284C473.571 284 459.941 281.248 447.228 275.743C434.515 270.239 423.309 262.637 413.61 252.938C404.043 243.24 396.507 232.034 391.002 219.321C385.629 206.608 382.942 192.978 382.942 178.43C382.942 164.406 385.498 151.235 390.609 138.915C395.851 126.595 403.125 115.782 412.431 106.477C421.736 97.1717 432.483 89.8978 444.672 84.6553C456.861 79.4129 469.967 76.7916 483.99 76.7916C496.965 76.7916 508.564 79.675 518.787 85.4417C529.141 91.2084 537.922 98.5478 545.131 107.46V92.2962ZM486.939 237.801C497.162 237.801 506.205 235.245 514.069 230.134C521.933 225.022 528.093 218.142 532.549 209.492C537.005 200.71 539.233 191.077 539.233 180.592C539.233 169.976 537.005 160.343 532.549 151.693C528.093 142.912 521.867 135.966 513.872 130.855C506.009 125.743 497.031 123.187 486.939 123.187C476.848 123.187 467.608 125.809 459.22 131.051C450.963 136.163 444.344 143.043 439.364 151.693C434.515 160.343 432.09 169.976 432.09 180.592C432.09 191.208 434.58 200.841 439.561 209.492C444.541 218.142 451.159 225.022 459.416 230.134C467.804 235.245 476.979 237.801 486.939 237.801Z" fill="#4169E1"/>
            <path d="M712.772 284C694.685 284 678.171 279.347 663.23 270.042C648.42 260.737 636.559 248.286 627.647 232.689C618.866 216.962 614.476 199.596 614.476 180.592C614.476 166.176 617.031 152.742 622.143 140.291C627.254 127.709 634.266 116.7 643.178 107.263C652.221 97.696 662.706 90.2254 674.633 84.8519C686.559 79.4784 699.272 76.7916 712.772 76.7916C730.858 76.7916 747.306 81.4443 762.116 90.7497C777.057 100.055 788.918 112.571 797.7 128.299C806.612 144.026 811.068 161.457 811.068 180.592C811.068 194.878 808.512 208.246 803.401 220.697C798.289 233.148 791.212 244.157 782.169 253.725C773.257 263.161 762.837 270.566 750.911 275.94C739.115 281.313 726.402 284 712.772 284ZM712.772 236.031C722.339 236.031 730.924 233.476 738.525 228.364C746.127 223.253 752.09 216.503 756.415 208.115C760.871 199.727 763.099 190.553 763.099 180.592C763.099 170.37 760.806 161.064 756.219 152.676C751.763 144.157 745.668 137.408 737.936 132.427C730.334 127.316 721.946 124.76 712.772 124.76C703.335 124.76 694.816 127.316 687.215 132.427C679.613 137.539 673.584 144.354 669.128 152.873C664.672 161.392 662.444 170.632 662.444 180.592C662.444 190.946 664.738 200.317 669.325 208.705C673.912 217.093 680.006 223.777 687.608 228.758C695.341 233.607 703.728 236.031 712.772 236.031Z" fill="#4169E1"/>
            <path d="M991.07 25C991.07 19.4771 995.547 15 1001.07 15H1029.04C1034.56 15 1039.04 19.4772 1039.04 25V268.889C1039.04 274.411 1034.56 278.889 1029.04 278.889H1001.07C995.547 278.889 991.07 274.411 991.07 268.889V254.315C984.779 263.096 976.85 270.239 967.282 275.743C957.715 281.248 946.64 284 934.058 284C919.772 284 906.404 281.313 893.953 275.94C881.502 270.566 870.493 263.161 860.926 253.725C851.489 244.157 844.084 233.148 838.711 220.697C833.337 208.246 830.65 194.878 830.65 180.592C830.65 166.307 833.337 152.938 838.711 140.488C844.084 128.037 851.489 117.093 860.926 107.657C870.493 98.0891 881.502 90.6186 893.953 85.2451C906.404 79.8716 919.772 77.1848 934.058 77.1848C946.64 77.1848 957.715 79.9371 967.282 85.4417C976.85 90.8152 984.779 97.9581 991.07 106.87V25ZM934.648 237.801C944.871 237.801 953.914 235.245 961.777 230.134C969.641 225.022 975.801 218.142 980.257 209.492C984.713 200.71 986.941 191.077 986.941 180.592C986.941 169.976 984.713 160.343 980.257 151.693C975.801 142.912 969.576 135.966 961.581 130.855C953.717 125.743 944.74 123.187 934.648 123.187C924.556 123.187 915.316 125.809 906.928 131.051C898.671 136.163 892.053 143.043 887.072 151.693C882.223 160.343 879.798 169.976 879.798 180.592C879.798 191.208 882.289 200.841 887.269 209.492C892.249 218.142 898.868 225.022 907.125 230.134C915.513 235.245 924.687 237.801 934.648 237.801Z" fill="#4169E1"/>
            <path d="M1169.7 284C1151.61 284 1135.1 279.347 1120.15 270.042C1105.34 260.737 1093.48 248.22 1084.57 232.493C1075.79 216.765 1071.4 199.4 1071.4 180.396C1071.4 166.11 1073.95 152.742 1079.07 140.291C1084.18 127.709 1091.19 116.7 1100.1 107.263C1109.14 97.696 1119.63 90.2254 1131.56 84.8519C1143.48 79.4784 1156.2 76.7916 1169.7 76.7916C1185.03 76.7916 1199.05 80.0682 1211.77 86.6212C1224.61 93.0433 1235.49 101.89 1244.4 113.161C1253.31 124.433 1259.8 137.277 1263.86 151.693C1267.18 163.452 1268.4 175.646 1267.53 188.276C1267.18 193.245 1262.91 196.91 1257.93 196.91H1122.91C1124.74 204.249 1127.76 210.868 1131.95 216.765C1136.14 222.532 1141.45 227.185 1147.87 230.723C1154.3 234.131 1161.57 235.9 1169.7 236.031C1178.08 236.163 1185.68 234.197 1192.5 230.134C1197.49 227.124 1201.86 223.371 1205.63 218.875C1208.34 215.646 1212.54 213.864 1216.64 214.82L1246.83 221.851C1252.99 223.286 1256.3 230.035 1253.04 235.453C1245.44 248.068 1235.42 258.745 1222.97 267.486C1207.11 278.495 1189.35 284 1169.7 284ZM1121.33 160.933H1218.06C1216.62 153.069 1213.54 145.992 1208.82 139.701C1204.23 133.279 1198.53 128.168 1191.71 124.367C1184.9 120.566 1177.56 118.666 1169.7 118.666C1161.83 118.666 1154.56 120.566 1147.87 124.367C1141.19 128.037 1135.49 133.083 1130.77 139.505C1126.18 145.796 1123.04 152.938 1121.33 160.933Z" fill="#4169E1"/>
            <path d="M1358.13 282.427C1347.91 281.772 1338.01 279.741 1328.45 276.333C1319.01 272.794 1310.62 268.01 1303.28 261.982C1298.7 258.22 1294.79 253.999 1291.53 249.318C1288.3 244.672 1290.69 238.513 1295.9 236.29L1318.86 226.481C1323.45 224.519 1328.85 226.273 1332.49 229.686C1333.14 230.285 1333.82 230.894 1334.54 231.51C1338.08 234.393 1342.27 236.818 1347.12 238.784C1352.1 240.75 1357.54 241.733 1363.44 241.733C1368.42 241.733 1373.14 241.077 1377.59 239.767C1382.18 238.325 1385.85 236.163 1388.6 233.279C1391.49 230.396 1392.93 226.661 1392.93 222.073C1392.93 217.224 1391.23 213.489 1387.82 210.868C1384.54 208.115 1380.35 206.084 1375.24 204.773C1370.26 203.332 1365.41 202.021 1360.69 200.841C1348.24 198.351 1336.64 194.485 1325.89 189.242C1315.28 184 1306.69 177.25 1300.14 168.993C1293.72 160.606 1290.5 150.514 1290.5 138.718C1290.5 125.743 1293.91 114.537 1300.73 105.101C1307.67 95.6645 1316.65 88.3906 1327.66 83.2792C1338.8 78.1678 1350.79 75.6121 1363.64 75.6121C1379.23 75.6121 1393.52 78.8886 1406.49 85.4417C1416.33 90.2606 1424.61 96.5924 1431.35 104.437C1435.05 108.743 1433.49 115.169 1428.6 118.056L1408.27 130.066C1403.5 132.886 1397.34 131.17 1393.15 127.534C1392.76 127.195 1392.36 126.86 1391.95 126.53C1388.67 123.777 1384.93 121.549 1380.74 119.845C1376.55 118.011 1372.16 116.962 1367.57 116.7C1361.67 116.438 1356.3 116.962 1351.45 118.273C1346.6 119.583 1342.67 121.811 1339.65 124.957C1336.77 128.102 1335.33 132.296 1335.33 137.539C1335.33 142.519 1337.29 146.254 1341.23 148.744C1345.16 151.104 1349.88 152.938 1355.38 154.249C1361.02 155.56 1366.39 157.001 1371.5 158.574C1383.03 162.375 1393.85 167.028 1403.94 172.532C1414.16 178.037 1422.35 184.786 1428.51 192.781C1434.67 200.776 1437.62 210.343 1437.36 221.484C1437.36 234.197 1433.56 245.337 1425.96 254.904C1418.36 264.341 1408.53 271.549 1396.47 276.53C1384.54 281.51 1371.76 283.476 1358.13 282.427Z" fill="#4169E1"/>
            <path d="M1463.14 206.149V92.2962C1463.14 86.7734 1467.62 82.2962 1473.14 82.2962H1501.11C1506.63 82.2962 1511.11 86.7734 1511.11 92.2962V193.568C1511.11 201.3 1513.01 208.377 1516.81 214.799C1520.61 221.09 1525.66 226.136 1531.95 229.937C1538.37 233.607 1545.38 235.442 1552.98 235.442C1560.85 235.442 1567.92 233.607 1574.22 229.937C1580.51 226.136 1585.55 221.09 1589.35 214.799C1593.15 208.377 1595.05 201.3 1595.05 193.568V92.2962C1595.05 86.7734 1599.53 82.2962 1605.05 82.2962H1633.03C1638.55 82.2962 1643.03 86.7673 1643.03 92.2862L1643.21 268.879C1643.21 274.405 1638.74 278.889 1633.21 278.889H1605.05C1599.53 278.889 1595.05 274.411 1595.05 268.889V260.999C1588.24 268.076 1580.18 273.712 1570.87 277.906C1561.57 281.969 1551.61 284 1540.99 284C1526.71 284 1513.66 280.527 1501.87 273.581C1490.07 266.503 1480.64 257.132 1473.56 245.468C1466.61 233.672 1463.14 220.566 1463.14 206.149Z" fill="#4169E1"/>
        </svg>
    )
}

export default Logo;