import { signOut } from "firebase/auth";
import { auth } from "../../services/firebase/firebase";

interface signOutProps {
    setAccount: Function;
    setProfile: Function;
}

async function signOutFn({ setAccount, setProfile }: signOutProps) {
    signOut(auth)
    .then(() => {
        localStorage.removeItem('current_profile');
        localStorage.removeItem('profiles');
        setAccount(null);
        setProfile(null);
    })
}

export default signOutFn;