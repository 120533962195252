import React, { useEffect, useState } from 'react'
import { EpisodesLazy, HeaderLazy, SeasonsLazy } from './lazyComponent';
import '../index.scss'
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { getAnimeDatabase, getDataAndCache } from '../../../utils/firebase';
import { LazyLoadImage } from '../../../components/loading';
import { Rating } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import AnimeHeader from './AnimeHeader';
import AnimeMoreInfo from './AnimeMoreInfo';
import getDBLang from '../../../components/hooks/getDBLang';
import { createExpiration, hasExpired } from '../../../utils/time';
import AnimeSeasons from './AnimeSeasons';
import verifyCacheResponse from '../../../utils/verify/verifyCacheResponse';
import AnimeEpisodes from './AnimeEpisodes';
import AnimeOpinion from './opinions/AnimeOpinion';

import { AnimeHeader } from '../Layout';
import AdsTerra from '../../../components/ads/AdsTerra';
import SEO from '../../../services/seo';

export default function AnimeLanding() {
    const params = useParams();
    const { t } = useTranslation();
    const [ anime, setAnime ] = useState([]);
    const [ currentSeason, setCurrentSeason ] = useState([]);

    const [ episodes, setEpisodes ] = useState([]);
    const [ filtered, setFiltered ] = useState('ascending')

    const [ infoLoading, setInfoLoading ] = useState(true);
    const [ seasonsLoading, setSeasonsLoading ] = useState(true);
    const [ episodesLoading, setEpisodesLoading ] = useState(true);

    async function getAnime() {
        const id = params.id.split('-')[0];

        try {
            const { res } = await getDataAndCache({
                cacheLocation: 'anime',
                cacheID: `${id}`,
                fetchLocation: `animes/${id}`,
                expirationDate: {
                    value: 6,
                    unit: 'months'
                },
            })

            setAnime(res.info[0]);
            setInfoLoading(false);
        } catch (error) {
            console.error('Error obteniendo anime:', error)
        }
    }

    const AnimeData = ({ isMobile }) => {
        return (
            <div className={`data ${isMobile ? 'mobile' : ''}`}>
                <h1 className='anime-title'>{anime.name}</h1>
               {/*  <Rating 
                    name="simple"
                /> */}
            </div>
        )
    }

    useEffect(() => {
        if (infoLoading) {
            getAnime();
        }
    }, [infoLoading])

    return (
        <>
            {anime.name &&
                <SEO title={anime.name} />
            }
            <div className='anime-landing'>
                <div className="sticky-handler">
                    <AnimeHeader loading={infoLoading} anime={anime} animeData={<AnimeData />} />
                    <div className="anime-landing-body">
                        <div className="anime-landing-body-layout">
                            {!infoLoading && <AnimeData isMobile />}
                            {infoLoading || seasonsLoading ?
                                <div className="info">
                                    <div className="more-info"></div>
                                </div>
                                :
                                <AnimeMoreInfo season={currentSeason} anime={anime} />
                            }
                            <div className="caps">
                                <div className="episode-layout">
                                    <AnimeSeasons 
                                        season={currentSeason}
                                        setSeason={setCurrentSeason}
                                        loading={seasonsLoading}
                                        setLoading={setSeasonsLoading}
                                    />
                                    <AnimeEpisodes 
                                        episodes={episodes}
                                        setEpisodes={setEpisodes}
                                        filtered={filtered}
                                        season={currentSeason}
                                        loading={seasonsLoading || episodesLoading}
                                        setLoading={setEpisodesLoading}
                                    />
                                </div>
                            </div>
                            <div className="divider"></div>
                            <AdsTerra />
                            <AnimeOpinion anime={anime} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
