import { enqueueSnackbar } from "notistack";
import { selectProfile } from "./index";

interface loadProfileProps {
    profile: {
        bannerURL: string;
        displayName: string;
        photoURL: string;
        puid: string;
        auid: string;
        info: object;
    };
    setRefreshing?: Function;
}

interface loadProfileResult {

}

const loadProfile = (() => {
    return ({ profile, setRefreshing }: loadProfileProps): Promise<loadProfileResult> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await selectProfile({ profile: profile, noMessage: true, setRefreshing: setRefreshing ? setRefreshing : undefined });
                enqueueSnackbar(`¡Te damos la bienvenida ${profile.displayName}!`, { variant: 'default' });
                if (setRefreshing) {
                    setRefreshing(false);
                }
                return resolve(res.profile)
            } catch (error) {

            }
        })
    }
})();

export default loadProfile;