const duration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    if (hours > 0) {
        if (seconds > 0){
            return `${hours}h ${minutes}m ${seconds}s`;
        }
        return `${hours}h ${minutes}m`;
    } else {
        if (seconds > 0) {
            return `${minutes}m ${seconds}s`;
        }
        return `${minutes}m`;
    }
}

export default duration;