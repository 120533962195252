import React, { useContext, useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from '../../components/loading';
import { Input } from '../../components/form';
import { Button, CircularProgress, TextField } from '@mui/material';
import { AuthContext } from '../../context/Auth';
import { DatePicker } from '@mui/x-date-pickers';
import CountrySelector from '../../utils/input/CountrySelector';
import dayjs from 'dayjs';
import { verifiyName, verifyUserName } from '../../utils/verify';
import { AddAPhoto, Edit, Person } from '@mui/icons-material';
import { Modal, useModalManager } from '../../components/modals';
import { AvatarPicker } from '../../components/modals/dist/user-image';
import CircularLoading from '../../components/loading/CircularLoading';
import { createProfile } from '../../utils/firebase/profile';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AoButton from '../../components/buttons/Button';
import { auth } from '../../services/firebase/firebase';

const StepOne = ({ userName, displayName, setUserName, setDisplayName}) => {
    const { t } = useTranslation();
    const [ displayNameV, setDisplayNameV ] = useState('');
    const [ displayNameCheck, setDisplayNameCheck ] = useState(null);
    const [ displayNameValidating, setDisplayNameValidating ] = useState(false);
    const [ displayNameError, setDisplayNameError ] = useState('') 

    const [ userNameV, setUserNameV ] = useState('');
    const [ userNameCheck, setUserNameCheck ] = useState(null);
    const [ userNameValidating, setUserNameValidating ] = useState(false);
    const [ userNameError, setUserNameError ] = useState('') 

    const handleDisplayname = async (value) => {
        setDisplayNameV(value);
        setDisplayName('');

        try {
            setDisplayNameValidating(true);
            const verification  = await verifiyName({ text: value });
            if (verification.code === 0) {
                setDisplayName(value);
                setDisplayNameCheck(true);
            }
            setDisplayNameValidating(false);
            setDisplayNameError('');
        } catch (error) {
            setDisplayNameValidating(false);
            if (error.code !== 4) {
                setDisplayNameCheck(false);
                setDisplayNameError(error.message);
            } else {
                setDisplayNameError('');
                setDisplayNameCheck(null);
            }
        }
    }

    const handleUserName = async (value) => {
        setUserNameV(value);
        setUserName('');
        try {
            setUserNameValidating(true);
            const verification  = await verifyUserName({ text: value });
            if (verification.code === 0) {
                setUserName(value);
                setUserNameCheck(true);
            }
            setUserNameError('');
            setUserNameValidating(false);
        } catch (error) {
            setUserNameValidating(false);
            if (error.code !== 4) {
                setUserNameCheck(false);
                setUserNameError(error.message);
            } else {
                setUserNameCheck(null);
                setUserNameError('');
            }
        }
    }
    
    return (
        <>
            <div className='flex flow-column gap-15 full-width'>
                <div className='flex flow-column gap-20'>
                    <div className='flex flow-column gap-5'>
                        <Input 
                            placeholder={`${t('words.nickname')}`}
                            label={`${t('words.nickname')}`}
                            helperText='Este es el nombre del grupo de tu hogar y puede cambiarse en cualquier momento.'
                            value={displayNameV ? displayNameV : displayName}
                            onChange={(e) => handleDisplayname(e.target.value)}
                            loading={displayNameValidating}
                            success={displayNameCheck}
                            required
                            error={displayNameCheck === false ? true : false}
                        />
                        {displayNameError && 
                            <span className='font-style color-negative small'>
                                {displayNameError}
                            </span>
                        }
                    </div>
                    <div className='flex flow-column gap-5'>
                        <Input 
                            placeholder={userName ? userName : t('words.username')}
                            label={`${t('words.username')}`}
                            helperText='Crea un nombre de usuario para estar listo para futuras experiencias que compartirán tu amor por el anime. ¡Elige uno que te encante, no se puede cambiar!'
                            value={userNameV ? userNameV : userName}
                            onChange={(e) => handleUserName(e.target.value)}
                            loading={userNameValidating}
                            success={userNameCheck}
                            required
                            error={userNameCheck === false ? true : false}
                        />
                        {userNameError && 
                            <span className='font-style color-negative small'>
                                {userNameError}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const StepTwo = ({ birthdate, setBirthdate, country, setCountry}) => {

    const handleChange = (value) => {
        setCountry(value);
    }

    return (
        <div className='flex flow-column padding-bottom-10 gap-15 full-width'>
            <DatePicker 
                timezone='UTC'
                className='input date-picker'
                label="Fecha de nacimiento"
                value={dayjs(birthdate)}
                onChange={(newValue) => setBirthdate(newValue.valueOf())} 
                slotProps={{
                    textField: {
                        helperText: 'Agrega tu fecha de nacimiento para recomendarte mejor contenido ;)'
                    }
                }}
            />
            <CountrySelector 
                value={country}
                setValue={setCountry}
                onChange={handleChange}
            />
        </div>
    )
}

export default function CreateProfile() {
    const { t } = useTranslation();
    const { setProfile, setRefreshing } = useContext(AuthContext);
    const { openModal, closeModal } = useModalManager();
    const navigate = useNavigate();

    const [ stepper, setStepper ] = useState(0);

    const [ userAvatar, setUserAvatar ] = useState('');

    const [ displayName, setDisplayName ] = useState('');
    const [ userName, setUserName ] = useState('');

    const [ birthdate, setBirthdate ] = useState(Date.now());
    const [ country, setCountry ] = useState({
        label: '',
        value: ''
    });

    const [ error, setError ] = useState({
        displayNameTooLess: false,
        displayNameTooLong: false,
        userNameTooLess: false,
        userNameTooLong: false,
    })

    const [ loading, setLoading ] = useState(false)

    const [ disabled, setDisabled ] = useState(true);

    const handleOpen = () => {
        if (stepper >= 2) {
            return;
        }
        openModal('avatar-selector', (
            <Modal
                close={() => closeModal('avatar-selector')}
                variant='fh'
            >
                <AvatarPicker 
                    image={userAvatar && URL.createObjectURL(userAvatar)}
                    setImage={setUserAvatar}
                />
            </Modal>
        ))
    }

    const handleContinue = (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }

        setStepper(stepper + 1);
    }

    const handleCancel = () => {
        if (stepper < 1) {
            navigate('/')
        } else {
            setStepper(stepper - 1);
        }
    }

    useEffect(() => {
        if (stepper === 0) {
            if (displayName && userName && userAvatar) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
        
        if (stepper === 1) {
            setDisabled(true);
        }

        if (stepper === 1) {
            if (birthdate && country.label) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }

    }, [userAvatar, displayName, userName, stepper, birthdate, country])

    useEffect(() => {
        if (stepper > 1) {
            setLoading(true);

            async function createProfileFn() {
                try {
                    const response = await createProfile({ 
                        userName: userName,
                        displayName: displayName,
                        profilePhoto: userAvatar,
                        birthdate: birthdate,
                        country: country,
                        uid: auth.currentUser.uid
                    });

                    if (!response.success) {
                        console.error('Error', response.message);
                        return;
                    }
                    
                    setProfile(response.user);
                    navigate('/');
                } catch (error) {
                    console.error('Error', error)
                }
            }

            createProfileFn();
        }
    }, [stepper])

    return (
        <>
            <div className={`auth-with-background ${loading ? 'loading' : ''}`}>
                <div className="background">
                    <LazyLoadImage 
                        src='https://i.ibb.co/1zYdKFm/anime-girl-blue-hair-sunrise-hd-wallpaper-uhdpaper-com-730-2-b.jpg'
                    />
                </div>
                <div className="content">
                    {loading && <div className='loader'><CircularProgress className='progress circular' /></div>}
                    <form 
                        className="content-body"
                        onSubmit={handleContinue}
                    >
                        <h1>Crea tu perfil</h1>
                        <div className={`avatar ${stepper === 1 && 'stepOne'}`}>
                            <Button
                                onClick={handleOpen}
                                className='button profile-avatar'
                                style={{
                                    fontSize: stepper === 1 && '.675rem'
                                }}
                            >
                                {userAvatar ? 
                                    <LazyLoadImage 
                                        src={URL.createObjectURL(userAvatar)}
                                        isRadius
                                        cached
                                    />
                                    :
                                    <Person />
                                }
                                <div className="hover"><Edit /></div>
                                {stepper < 1 && <div className="badge"><AddAPhoto /></div>}
                            </Button>
                            <div className="user-data">
                                <span className='dn'>{displayName}</span>
                                <span className='un'>@{userName}</span>
                            </div>
                        </div>
                        <div className="flex flow-column gap-big">
                            {stepper === 0 && 
                                <StepOne 
                                    displayName={displayName} 
                                    setDisplayName={setDisplayName} 
                                    userName={userName} 
                                    setUserName={setUserName} 
                                />
                            }
                            {stepper === 1 && 
                                <StepTwo 
                                    birthdate={birthdate}
                                    setBirthdate={setBirthdate}
                                    country={country}
                                    setCountry={setCountry}
                                />
                            }
                        </div>
                        {stepper < 2 &&
                            <div 
                                className='buttons'
                                style={{
                                    maxWidth: 500,
                                    width: '100%'
                                }}
                            >
                                <Button onClick={handleCancel} className='button outline full-width rounded'>
                                    <span className='title'>{stepper === 0 ? 'Cancelar' : 'Volver'}</span>
                                </Button>
                                <AoButton 
                                    title={stepper === 1 ? 'Crear perfil' : t('words.continue')}
                                    variant="special-2 size-big"
                                    disabled={disabled}
                                    submit={true}
                                />
                            </div>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}
