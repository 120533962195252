import { addDoc, arrayUnion, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore, userFirestore, usersFirestore, userStorage } from '../../../services/firebase/firebase';
import { verifyUserName } from '../../verify';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import generateUid from '../../generateUID';
import { createExpiration, getAge } from '../../time';

interface countryProps {
    label: string;
    code: string;
}

interface createProfileProps {
    userName: string;
    displayName: string;
    profilePhoto: File;
    birthdate: number;
    country: countryProps;
    uid: string;
}

interface userProps {
    coverURL: string;
    displayName: string;
    avatarURL: string;
    auid: string;
    info?: object;
}

interface createProfileResult {
    success: boolean;
    user: userProps;
}

const createProfile = (() => {
    return ({ userName, displayName, profilePhoto, birthdate, country, uid }: createProfileProps): Promise<createProfileResult> => {
      return new Promise(async (resolve, reject) => {
        try {
          const PUID = `PUID${generateUid()}`;

          const AUIDRef = doc(firestore, `${uid}/advanced`);

          await updateDoc(AUIDRef, {
            puid: arrayUnion(PUID)
          })

          await verifyUserName({ text: userName });

          /* COVER UPLOAD */
          const defaultCoverURL = 'https://firebasestorage.googleapis.com/v0/b/rigu-yugashiki.appspot.com/o/users-default%2Fuser-pfb.png?alt=media&token=f07a6cd9-45a7-4bce-9899-716cd11900ef';
          const defaultCover = await fetch(defaultCoverURL).then(res => {
            return res.blob();
          }).then(blob => {
            return blob;
          })

          const coverRef = ref(userStorage, `${PUID}/cover.jpeg`);
          await uploadBytes(coverRef, defaultCover);

          const cover = await getDownloadURL(coverRef);

          /* AVATAR UPLOAD */
          const avatarRef = ref(userStorage, `${PUID}/avatar.jpeg`);
          await uploadBytes(avatarRef, profilePhoto);
  
          const avatar = await getDownloadURL(avatarRef);

          const AUIDSnap = await getDoc(AUIDRef);
          const AUID = AUIDSnap.exists() ? AUIDSnap.data().auid : undefined;

          if (!AUID) {
            reject({
              success: false,
              message: 'El usuario no tiene un AUID.'
            });
          }
  
          const userObject = {
            coverURL: cover,
            displayName: displayName,
            userName: userName,
            avatarURL: avatar,
            auid: AUID,
            puid: PUID
          };

          await setDoc(doc(usersFirestore, `users/${PUID}`), {
            data: [
              userObject,
              userName.toLowerCase()
            ]
          })
  
          const userRef = doc(firestore, `${auth.currentUser?.uid}/profiles`);
          const userSnap = await getDoc(userRef);
  
          if (userSnap.exists()) {
            await updateDoc(userRef, {
              list: arrayUnion(userObject)
            });
          } else {
            await setDoc(userRef, {
              list: arrayUnion(userObject)
            });
          }
  
          const profileData = {
            age: { private: true }, // Sin valor `value`
            birthdate: { private: true, value: birthdate },
            country: { label: country.label, private: true, value: country.code }
          };
  
          const profileRef = doc(firestore, `${auth.currentUser?.uid}/profiles/${PUID}/info`);
          await setDoc(profileRef, { data: arrayUnion(profileData) });
  
          localStorage.removeItem('profiles');

          localStorage.setItem('current_profile', JSON.stringify({ profile: userObject, expire: createExpiration({ value: 2, unit: 'days' }).expire}))
          resolve({
            success: true,
            user: { ...userObject, info: { ...profileData, age: { private: true, value: getAge({ timestamp: birthdate }).age } } }
          });
  
        } catch (error) {
          console.error('Error during profile creation:', error);
          reject({
            success: false,
            message: error.message || 'Unknown error occurred'
          });
        }
      });
    }
  })();
  

export default createProfile;