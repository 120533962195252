import { Button } from '@mui/material'
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import SEO from '../../../../services/seo'
import { useTranslation } from 'react-i18next'

export default function Request() {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <>
            <SEO title={`${t('settings.nav.community')} - ${t('settings.nav.request')}`} />
            <div className="sticky-buttons-container">
                <div className={`sticky-buttons ${location.pathname.split('/')[3]}`}>
                    <Button
                        className='button rounded private'
                        component={Link}
                        to="/settings/request/private"
                    >
                        <span>Petición privada</span>
                    </Button>
                    <Button
                        className='button rounded public'
                        component={Link}
                        to="/settings/request/public"
                        >
                        <span>Peticiones públicas</span>
                    </Button>
                </div>
            </div>
            <div className='margin-top-10'>
                <Outlet />
            </div>
        </>
    )
}
