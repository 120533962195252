import { AddAPhotoOutlined, Brush, Edit } from '@mui/icons-material'
import { Avatar, Button, IconButton } from '@mui/material'
import React from 'react'
import { LazyLoadImage } from '../loading';

interface AvatarButtonProps {
    src: string;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    variant?: string;
    fontSize?: number;
}

const AvatarButton: React.FC<AvatarButtonProps> = ({
    src,
    onClick,
    variant,
    fontSize
}) => {
    return (
        <Button 
            className={`button profile-avatar variant-${variant}`}
            onClick={onClick}
            style={{
                fontSize: fontSize && `${fontSize}rem`
            }}
        >
            <LazyLoadImage 
                src={src}
                isRadius
            />
            <div className='hover'><Brush /></div>
            <div className="badge"><Edit /></div>
        </Button>
    )
}

export default AvatarButton;