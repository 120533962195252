import { DiscussionEmbed } from 'disqus-react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import i18n from '../../../../services/i18n';
import { useTranslation } from 'react-i18next';

export default function PublicRequest() {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        <DiscussionEmbed 
            shortname='aodesu-community'
            config={
                {
                    url: `https://aodesu-community.com/${location.pathname}`,
                    identifier: t('settings.nav.request'),
                    title: `${t('settings.nav.request-public')} - aodesu`,
                    language: i18n.language
                }
            }
        />
    )
}
