import React, { useEffect, useState } from 'react'
import { EpisodesLazy } from './lazyComponent'
import getDBLang from '../../../components/hooks/getDBLang';
import verifyCacheResponse from '../../../utils/verify/verifyCacheResponse';
import { getAnimeDatabase, getDataAndCache } from '../../../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { createExpiration, duration } from '../../../utils/time';
import { Link, useParams } from 'react-router-dom';
import { LazyLoadImage } from '../../../components/loading';
import { useTranslation } from 'react-i18next';
import { PlayArrow, Replay } from '@mui/icons-material';

const Episode = ({ episode, season }) => {
    const { t } = useTranslation();
    const [ viewed, setViewed ] = useState('');

    const d = duration(episode.duration);

    return (
        <div className="card">
            <Link to={`/watch/${episode.id}`} className='link'>
                <div className="card-layout">
                    <div className="thumbnail-layout">
                        <div className="thumbnail-sized">
                            <div className={`thumbnail`} >
                                <LazyLoadImage 
                                    src={episode.cover}
                                    alt={`${t('words.shorts.season')}${season.season} ${t('words.shorts.episode')}${episode.id} - ${episode.name}`}
                                />
                            </div>
                        </div>
                        
                        {(season.rating === "Rx" || season.rating === "R+" || season.rating === "R-17+") &&
                            <span className={`rating ${season.rating}`}>
                                {season.rating}
                            </span>
                        }

                        <span className='duration'>
                            {viewed ?
                                <>
                                    {viewed === 'partial' && t('words.continue-watching')}
                                    {viewed === 'true' && t('words.watched')}
                                </>
                                :
                                <>{d}</>
                            }
                        </span>
                        
                        {viewed &&
                            <div className={`watched ${viewed}`}>
                                {viewed === 'partial' && 
                                <>
                                    <PlayArrow />
                                    <div className="border"></div>
                                </>
                                }
                                {viewed === 'true' && <Replay />}
                            </div>
                        }
                    </div>
                    <div className="data-layout">
                        <div className="data-body">
                            <h4 className="episode-title">
                                {`${t('words.shorts.season')}${season.season} ${t('words.shorts.episode')}${episode.episode} - ${episode.name}`}
                            </h4>
                            <div className="episode-footer">
                                <div className="episode-footer-meta">
                                    <div className="tags">
                                        {episode.tags.includes('dub') && <span className='tag'>{t('categories.langs.dub')}</span>}
                                        {episode.tags.includes('sub') && <span className='tag'>{t('categories.langs.sub')}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default function AnimeEpisodes({ filtered, episodes, setEpisodes, season, loading, setLoading }) {
    const params = useParams();
    const { t } = useTranslation();

    async function getEpisodes(animeID) {
        if (!animeID || !season) {
            console.error('Anime ID o Season no están definidos.');
            setLoading(false);
            return;
        }

        try {
            const { res } = await getDataAndCache({
                cacheLocation: 'anime',
                cacheID: `${animeID}/s/${season.id}`,
                fetchLocation: `animes/${animeID}/seasons/${season.id}`,
                expirationDate: {
                    value: 7,
                    unit: 'days'
                }
            });

            setEpisodes(res.episodes_list);
            setLoading(false);
        } catch (error) {
            console.error('No se pudieron obtener los episodios:', error)
        }
    }

    useEffect(() => {
        if (episodes.length > 0 || !loading) {
            console.log('No se necesita cargar episodios.');
            return;
        }

        if (season.length < 1) {
            console.log('No existe season.')
            return;
        }
        
        const animeID = params.id.split('-')[0];

        if (!animeID) {
            console.error('Anime ID no válido.');
            setLoading(false);
            return;
        }

        getEpisodes(animeID);
    }, [loading, season]);

    /* useEffect(() => {
        setLocalLoading(true);
    }, [season]) */

    const sortEpisodes = {
        ascending: { method: (a, b) => (a.episode - b.episode )},
        descending: { method: (a, b) => (b.episode - a.episode )},
    }

    if (loading) return <EpisodesLazy />
    
    return (
        <div className="episode-layout-body">
            {episodes.length < 1 ?
                <div className="episode-msg">
                    <h2>{t('error.caps/no-caps')}</h2>
                    <LazyLoadImage 
                        width={200}
                        height={200}
                        style={{
                            background: 'transparent'
                        }}
                        src='https://i.pinimg.com/originals/e8/d0/f1/e8d0f1794e2520ac2367c1d21c0966e9.gif'
                    />
                </div>  
                :
                <div className="episode-list">
                    {episodes.sort(sortEpisodes[filtered].method).map((episode) => (
                        <Episode 
                            key={episode.id}
                            season={season}
                            episode={episode}
                        />
                    ))}
                </div>  
            }
        </div>
    )
}
