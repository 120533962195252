import { CircularProgress } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import React from 'react'

export default function CircularLoading({ type, determinate }) {
  return (
    <div 
        style={{
            flex: 1,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: type === 'fs' && 'absolute',
            inset: type === 'fs' && 0,
            zIndex: 9,
            background: type === 'fs' && 'rgba(0,0,0,.5)'
        }}
    >
        <CircularProgress variant={determinate ? 'determinate' : 'indeterminate'} className='progress circular' />
    </div>
  )
}
