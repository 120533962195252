import React, { useEffect, useState } from 'react'
import Reproductor from './reproductor/Reproductor'
import { Link, useLocation, useParams } from 'react-router-dom'
import getDBLang from '../../components/hooks/getDBLang';
import verifyCacheResponse from '../../utils/verify/verifyCacheResponse';
import { getAnimeDatabase, getDataAndCache } from '../../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { createExpiration } from '../../utils/time';
import './Reproductor.scss';
import { useTranslation } from 'react-i18next';
import { Circle, Flag, IosShare, Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { BasicButton } from '../../components/buttons';
import ChangeEpisode from './reproductor/ChangeEpisode';
import SEO from '../../services/seo';
import Comments from '../../components/actions/Comments';
import AdsTerra from '../../components/ads/AdsTerra';
import { Modal, useModalManager } from '../../components/modals';
import Share from '../../components/modals/dist/Share';
import Report from '../../components/modals/dist/Report';

export default function Watch() {
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const { openModal, closeModal } = useModalManager();

    const [ episode, setEpisode ] = useState([]);
    const [ episodes, setEpisodes ] = useState([]);
    
    const [ season, setSeason ] = useState([]);
    const [ seasons, setSeasons ] = useState([]);

    const [ episodeLoading, setEpisodeLoading ] = useState(true);
    const [ episodesLoading, setEpisodesLoading ] = useState(true);
    const [ seasonLoading, setSeasonLoading ] = useState(true);

    async function getEpisodes(data, episode) {
        const id = params.id;

        try {
            const { res } = await getDataAndCache({
                cacheLocation: 'anime',
                cacheID: `${data.anime}/s/${data.season}`,
                fetchLocation: `animes/${data.anime}/seasons/${data.season}`,
                expirationDate: {
                    value: 7,
                    unit: 'days'
                }
            });

            console.log(3, res.episodes_list);

            if (Array.isArray(res.episodes_list)) {
                const episodeInfo = res.episodes_list.find(x => x.id === id);
                setEpisode({ ...episode, info: episodeInfo });
                setEpisodes(res);

                setTimeout(() => {
                    setEpisodesLoading(false);
                }, 100)
            } else {
                console.error("Esperaba un arreglo, pero recibí:", res.data);
            }
        } catch (error) {
            console.error('Error al obtener episodios:', error)
        }
    }

    async function getSeason(data) {
        try {
            const { res } = await getDataAndCache({
                cacheLocation: 'anime',
                cacheID: `${data.anime}/s`,
                fetchLocation: `animes/${data.anime}/seasons/info`,
                expirationDate: {
                    value: 3,
                    unit: 'months'
                }
            })

            console.log(2, res.data)

            if (Array.isArray(res.data)) {
                const seasonInfo = res.data.find(x => x.id === data.season);
                setSeason(seasonInfo);
                setSeasons(res.data);
                setSeasonLoading(false);
            } else {
                console.error("Esperaba un arreglo, pero recibí:", res.data);
            }
        } catch (error) {
            console.error('Error al obtener temporada:', error)
        }
    }

    async function getEpisode() {
        const id = params.id;

        try {
            const { res } = await getDataAndCache({
                cacheLocation: 'anime',
                cacheID: id,
                fetchLocation: `episodes/${id}`,
                expirationDate: {
                    value: 14,
                    unit: 'days'
                }
            })
            
            console.log(1, res);

            if (res.data) {
                await Promise.all([
                    getSeason(res.data[0]),
                    getEpisodes(res.data[0], res)
                ]);
            } else {
                console.error('Respuesta inesperada en getEpisode:', res.data)
            }

            setEpisodeLoading(false);
        } catch (error) {
            console.error("Error al obtener episodio:", error);
        }
    }

    const openModals = (type, id) => {
        openModal(id, (
            <Modal
                close={() => closeModal(id)}
                title={t(`words.${type} episode`)}
            >
                {type === 'share' && 
                    <Share 
                        link={`${location.pathname}`}
                    />
                }
                {type === 'report' &&
                    <Report 
                        episode={episode}
                        season={season}
                    />
                }
            </Modal>
        ))
    }

    useEffect(() => {
        if (episodeLoading) {
            getEpisode();
        }
    }, [episodeLoading]);

    useEffect(() => {
        setEpisodeLoading(true);
        setEpisodesLoading(true);
        setSeasonLoading(true);
    }, [location.pathname])

    return (
        <>
            <div className="reproductor-layout">
                <Reproductor 
                    episode={episode}
                    episodeLoading={episodeLoading}
                />
                {(episodesLoading && seasonLoading && episodeLoading && !episode.data) ?
                    <div className="reproductor-layout lazy">
                        <div className="data-reproductor-layout">
                            <div className="data-content">
                                <div className="data-content-title">
                                    <div className="upper">
                                        <div className="title"></div>
                                        <div className="options">
                                            <div className="IconButton"></div>
                                            <div className="IconButton"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {episode.data &&
                            <SEO title={`[${t('words.shorts.season')}${season.season} ${t('words.shorts.episode')}${episode.info.episode}] ${season.name} - ${episode.info.name}`} />
                        }
                        <div className="data-reproductor-layout">
                            <div className="data-content">
                                <div className="data-content-title">
                                    <div className="upper">
                                        <div className="title">
                                            {episode.data &&
                                                <Link to={`/anime/${episode.data[0].anime}`}><h1>{season.name}</h1></Link>
                                            }
                                        </div>
                                    </div>
                                    {episode.data &&
                                        <h2>{`${t('words.shorts.episode')}${episode.info.episode} - ${episode.info.name}`}</h2>
                                    }
                                    <div className="tags-and-rating">
                                        {season?.rating &&
                                            <>
                                                <span className={`rating ${season?.rating}`}>{t(`words.ratings.${season?.rating}`)}</span>
                                                <Circle className='font p-small' sx={{fontSize: '.3rem !important'}} />
                                            </>
                                        }
                                        {episode.info?.tags.map((tag, i) => (
                                            <span key={i}>{t(`categories.langs.${tag}`)}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className="options">
                                    <BasicButton 
                                        className='rounded'
                                        icon={<Visibility />}
                                        title='Marcar visto'
                                    />
                                    <BasicButton 
                                        className='rounded'
                                        icon={<IosShare />}
                                        onClick={() => openModals('share', 'share-modal')}
                                    />
                                    <BasicButton 
                                        className='rounded'
                                        icon={<Flag />}
                                        onClick={() => openModals('report', 'report-modal')}
                                    />
                                </div>
                                <div className="data-content-body">
                                    <div className="comments">
                                        {episode.data &&
                                            <Comments 
                                                id={params.id}
                                                name={`[${t('words.shorts.season')}${season.season} ${t('words.shorts.episode')}${episode.info.episode}] ${season.name} - ${episode.info.name}`}
                                            />
                                        }
                                    </div>
                                    <AdsTerra />
                                </div>
                            </div>
                            {episode && episodes && season && seasons &&
                                <ChangeEpisode 
                                    episode={episode}
                                    episodes={episodes.episodes_list}
                                    season={season}
                                    seasons={seasons}
                                    /* setReproductorNext={setReproductorNext}
                                    serie={serie}
                                    episodes={episodeData}
                                    currentIndex={episodeData.findIndex(x => x.id === episode.toString())}
                                    seasons={seasonData}
                                    currentSeason={seasonData.findIndex(x => x.id === season.toString())} */
                                />
                            }
                        </div>
                    </>
                }
            </div>   
        </>
    )
}
