import React, { useContext, useEffect, useState } from 'react'
import { LazyLoadImage } from '../../../loading';
import { Button } from '@mui/material';
import Compressor from 'compressorjs';
import SwiperAvatars from '../../../swiper/user-image/SwiperAvatar';
import CircularLoading from '../../../loading/CircularLoading';
import { ref, uploadBytes } from 'firebase/storage';
import { userStorage } from '../../../../services/firebase/firebase';
import { AuthContext } from '../../../../context/Auth';
import { enqueueSnackbar } from 'notistack';

interface AvatarPickerProps {
  image: string;
  setImage: Function;
  close: VoidFunction;
}

const AvatarPicker: React.FC<AvatarPickerProps> = ({ image, setImage, close }) => {
  const { profile } = useContext(AuthContext);
  const [ previewImage, setPreviewImage ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ disabled, setDisabled ] = useState(true);

  const handleImage = async () => {
    setLoading(true);
    try {
      const response = await fetch(previewImage ? previewImage : image);
      const blob = await response.blob();

      new Compressor(blob, {
        quality: .8,
        width: 200,
        height: 200,
        resize: 'cover',
        convertTypes: 'image/png',
        convertSize: 50000,
        success: async (compressedBlob) => {
          if (image || setImage) {
            setImage(compressedBlob);
            setPreviewImage(URL.createObjectURL(compressedBlob));
          } else {
            try {
              const avatarRef = ref(userStorage, `${profile.puid}/avatar.jpeg`);
              await uploadBytes(avatarRef, compressedBlob);
              enqueueSnackbar('¡Perfecto! Los cambios tardaran un poco en propagarse por completo.', { variant: 'default' })
            } catch (error){
              console.error('error', error)
              enqueueSnackbar('¡Algo ha salido mal!', { variant: 'error' })
              return;
            }
          }
          close();
        }
      })

      console.log(blob)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (previewImage) {
      setDisabled(false);
    } else {
      setDisabled(true)
    }
  }, [previewImage])

  return (
    <div className='flex flow-column gap-10' style={{ height: '100%'}}>
      {loading &&
        <div style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(0,0,0,.5)',
          zIndex: 2
        }}>
          <CircularLoading type='' determinate={false} />
        </div>
      }
      <div className='flex flow-column gap-10 padding-top-10'>
        <div className='flex gap-10 align-center'>
          <div>  
            <LazyLoadImage 
              width={100}
              height={100}
              isRadius
              src={previewImage ? previewImage : image}
            />
          </div>
          <div className='flex flow-column'>
            <h3 className='margin-bottom-5'>Selección de imagen de perfil</h3>
            <span className='font-style color-subdued small'>¡Escoge tu imagen de perfil! Podrás cambiarla siempre que quieras.</span>
            <span className='font-style color-subdued small'>Hay algunos iconos limitados 👀</span>
          </div>
        </div>
        <div className='flex gap-10 justify-end' style={{ maxWidth: 300, width: '100%', marginLeft: 'auto'}}>
          <Button className='button outline rounded full-width' onClick={close}>
            <span>Cancelar</span>
          </Button>
          <Button disabled={disabled} className='button high full-width' onClick={handleImage}>
            <span>Guardar</span>
          </Button>
        </div>
      </div>
      <div className="divider"></div>
      <div style={{ margin: '-1rem', marginBottom: '-.5rem', flex: 1, height: '100%', overflowY: 'auto'}}>
        <SwiperAvatars 
          image={previewImage}
          setImage={setPreviewImage}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  )
}

export default AvatarPicker;