export const SeasonsLazy = () => {
    return (
        <div className="episode-layout-header lazy">
            <div className="seasons"></div>
            <div className="sort-episode"></div>
        </div>
    )
}

export const EpisodesLazy = () => {
    return (
        <div className="episode-layout-body lazy">
            <div className="episode-list">
                {Array.from({ length: 12}, (_, index) => (
                    <div className="card" key={index}>
                        <div className="link">
                            <div className="card-layout">
                                <div className="thumbnail-layout"></div>
                                <div className="data-layout">
                                    <div className="data-body">
                                        <div className="episode-title"></div>
                                        <div className="episode-footer">
                                            <div className="tags"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const HeaderLazy = () => {
    return (
        <>
            <div className="background"></div>
            <div className="body">
                <div className="cover tall"></div>
                <div className="cover wide"></div>
                <div className="data">
                    <div className="h1"></div>
                </div>
            </div>
        </>
    )
}