import { doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../../../services/firebase/firebase";
import { createExpiration, getAge } from "../../time";
import { enqueueSnackbar } from "notistack";

interface profileProps {
    bannerURL: string;
    displayName: string;
    photoURL: string;
    puid: string;
    info: object;
}

interface selectProfileProps {
    profile: profileProps;
    noMessage?: boolean;
    setRefreshing?: Function;
}

interface selectProfileResult {
    profile: object;
}

const selectProfile = (() => {
    return ({ profile, noMessage, setRefreshing }: selectProfileProps): Promise<selectProfileResult> => {
        return new Promise(async (resolve, reject) => {
            let arr = profile;

            const profileRef = doc(firestore, `${auth.currentUser?.uid}/profiles/${profile.puid}/info`);
            const profileSnap = await getDoc(profileRef);

            if (profileSnap.exists()) {
                const data = profileSnap.data().data[0];
                const birthdate = data.birthdate.value;

                let infoArr = {
                    ...data
                }

                if (birthdate) {
                    const birthdateArr = {
                        age: {
                            value: getAge({ timestamp: birthdate }).age,
                            private: data.age.private
                        }
                    }

                    infoArr = {
                        ...infoArr,
                        ...birthdateArr
                    }
                }

                arr = {
                    ...arr,
                    info: infoArr
                }
            } else {
                arr.info = {
                    error: true
                }
                enqueueSnackbar('No se pudo cargar toda tu información.', { variant: 'error' })
            }

            if (!noMessage) {
                enqueueSnackbar(`Has cambiado a ${profile.displayName}`, { variant: 'default' })
            }

            localStorage.setItem('current_profile', JSON.stringify({ profile, expire: createExpiration({ value: 2, unit: 'days' }).expire}))
            
            if (setRefreshing) {
                setRefreshing(false);
            }
            
            return resolve({ profile: arr });
        })
    }
})();

export default selectProfile;