import React from 'react'
import { Outlet } from 'react-router-dom'
import { ModalManagerProvider } from '../components/modals'

export default function AppLayout() {
    return (
        <ModalManagerProvider>
            <Outlet />
        </ModalManagerProvider>
    )
}
