import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../services/firebase/accounts';
import AoButton from '../../components/buttons/Button';
import { AlertsContext } from '../../context/Alerts';
import {Input} from '../../components/form';
import AuthMethods from '../../components/form/AuthMethods';
import Form from '../../components/form/Form';
import { useTranslation } from 'react-i18next';
import SEO from '../../services/seo';
import { Button, TextField } from '@mui/material';

export default function Login() {
    const navigate = useNavigate();
    const { addAlert } = useContext(AlertsContext);
    const { t } = useTranslation();
    const location = useLocation();

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const [ disabled, setDisabled ] = useState(true);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ localError, setLocalError ] = useState('');

    const [ error, setError ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);

    const login = () => {
        setIsLoading(true);
        setFormLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                const searchParams = new URLSearchParams(location.search);
                const uri = searchParams.get('redirect_uri');

                if (uri) {
                    navigate(decodeURIComponent(uri));
                } else {
                    navigate('/');
                }
                setIsLoading(false);
                setFormLoading(false);
            })
            .catch((error) => {
                setError(error.code);
                setIsLoading(false);
                setFormLoading(false);
            })
    }

    useEffect(() => {
        const validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setDisabled(true);

        if (email.length > 0) {
            if (email.match(validRegex)) { 
                setLocalError(false);
                if (password.length > 7) {
                    setDisabled(false);
                }
            } else {
                setLocalError('auth/invalid-email');
            }
        } else {
            setLocalError(false);
        }
    }, [email, password]);

    return (
        <>
            <SEO title={t('SEO.login.title')} description={t('SEO.login.desc')} />
            <Form
                type="auth"
                action={() => {
                    if (!disabled) {
                        login();
                    }
                }}
                title={t('auth.login')}
                loading={formLoading}
                error={error}
                bg="https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/ao_login_image.webp?alt=media&token=e925f9d5-c4b4-47ca-86cf-7c15001fcf6a"
            >
                <div className="flex flow-column gap-big" style={{ marginTop: '1rem'}}>
                    <Input 
                        type="email"
                        value={email}
                        placeholder={t('auth.email.placeholder')}
                        onChange={(e) => setEmail(e.target.value)}
                        label={t('auth.email.label')}
                        required
                    />
                    <Input 
                        type="password"
                        value={password}
                        placeholder={t('auth.password.placeholder')}
                        onChange={(e) => setPassword(e.target.value)}
                        label={t('auth.password.label')}
                        required
                    />
                </div>
                {localError && <span className='font-style color-negative' style={{margin: '-1rem 0'}}>{t(`error.${localError}`)}</span>}
                
                <AoButton 
                    variant="special-2 rounded"
                    title={t('auth.login')}
                    loading={isLoading}
                    disabled={disabled}
                    submit={true}
                />
                <div className='flex flow-column gap-big align-center'>
                    <span>{t('auth.no account')}</span>
                    <AoButton 
                        to="/auth/register"
                        variant="secondary"
                        size="big"
                        type="link"
                        title={t('auth.register')}
                    />
                </div>
                <AuthMethods 
                    setFormError={setError}
                    setFormLoading={setFormLoading}
                />
            </Form>
        </>
    )
}
