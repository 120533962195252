import { AddCircleOutline, ArrowBack, ArrowBackIos, Close, Google } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react'
import AoButton from '../buttons/Button';
import { useTranslation } from 'react-i18next';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { auth } from '../../services/firebase/accounts';
import { ModalsContext } from '../../context/Modals';
import { AlertsContext } from '../../context/Alerts';
import Form from '../form/Form';
import {Input} from '../form';
import { Button } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AoIconButton from '../buttons/IconButton';

const googleProvider = new GoogleAuthProvider();

function LoginForm({ type, newEmail }) {
    const { t } = useTranslation();
    const { closeChangeAccount } = useContext(ModalsContext);
    const { addAlert } = useContext(AlertsContext);

    const [ email, setEmail ]  = useState(newEmail);
    const [ password, setPassword ] = useState('');

    const [ disabled, setDisabled ] = useState(true);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ localError, setLocalError ] = useState('');

    const loginWithEmailAndPassword = () => {
        setIsLoading(true);
        signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
            addAlert(t('msg.account-change.true'), "branch", "filled", "auto");
            closeChangeAccount();
            setIsLoading(false);
            const storage = localStorage.getItem('MultiAccountStore');

            if (storage) {
                let arr = JSON.parse(storage);

                if (arr.find(x => x.email === result.user.email)) {
                    console.log(result.user.email)
                    console.log(1)
                    return;
                } else {
                    console.log(2)
                    console.log(result.user.email)
                    let newAcc = 
                        {
                            email: result.user.email,
                            icon: result.user.photoURL,
                            name: result.user.displayName
                        }
                    arr.push(newAcc);
                    console.log(arr);
                    localStorage.setItem('MultiAccountStore', JSON.stringify(arr));
                }
            } else {
                console.log(3)
                let arr = [
                    {
                        email: result.user.email,
                        icon: result.user.photoURL,
                        name: result.user.displayName
                    }
                ]

                localStorage.setItem('MultiAccountStore', JSON.stringify(arr));
            }
        })
        .catch((error) => {
            setLocalError(error.code);
            setIsLoading(false);
        })
           
    }

    useEffect(() => {
        const validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setDisabled(true);

        if (email.length > 0) {
            if (email.match(validRegex)) { 
                setLocalError(false);
                if (password.length > 7) {
                    setDisabled(false);
                }
            } else {
                setLocalError('auth/invalid-email');
            }
        } else {
            setLocalError(false);
        }
    }, [email, password]);

    return (
        <div className='form'>
            <div className={`form-layout ${isLoading && "loading"}`}>
                <form onSubmit={(e) => {e.preventDefault();loginWithEmailAndPassword();}} className='form-body'>
                    <div className="flex flow-column gap-big">
                        {type === "emailAndPassword" && 
                            <Input 
                                type="email"
                                value={email}
                                variant={1}
                                placeholder={true}
                                placeholderText={t('auth.email.placeholder')}
                                name="email"
                                label={true}
                                labelText={t('auth.email.label')}
                                required={true}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        }
                        
                        <Input 
                            type="password"
                            value={password}
                            variant={1}
                            placeholder={true}
                            placeholderText={t('auth.password.placeholder')}
                            name="password"
                            label={true}
                            labelText={t('auth.password.label')}
                            required={true}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {localError && <span className='font-style color-negative' style={{margin: '-1rem 0'}}>{t(`error.${localError}`)}</span>}
                    <AoButton 
                        variant="special-2"
                        title={t('auth.login')}
                        loading={isLoading}
                        disabled={disabled}
                        submit={true}
                    />
                </form>
            </div>
        </div>
    )
}

export default function ChangeAccount() {
    const { t } = useTranslation();
    const { closeChangeAccount } = useContext(ModalsContext);
    const { addAlert } = useContext(AlertsContext);
    const [ accounts, setAccounts ] = useState([]);

    const [ loginType, setLoginType ] = useState('');

    const [ email, setEmail ] = useState('');

    const [ signInGoogle, setSignInGoogle ] = useState(false);

    const loginWithGoogle = () => {
        setSignInGoogle(true);
        signInWithPopup(auth, googleProvider)
            .then(() => {
                setSignInGoogle(false);
                addAlert(t('msg.account-change.true'), "branch", "filled", "auto");
                closeChangeAccount();
            })
    }

    

    useEffect(() => {
        const accounts = localStorage.getItem('MultiAccountStore');

        if (accounts) {
            setAccounts(JSON.parse(accounts));
        } else {
            setAccounts([]);
        }
    }, []);

    const handlePasswordLoging = (acc) => {
        setEmail(acc.email);
        setLoginType('password');
    }

    const deleteAccount = (email) => {
        let arr = accounts;
        let newArr = arr.filter((obj) => obj.email !== email);
        localStorage.setItem('MultiAccountStore', JSON.stringify(newArr));
        setAccounts(newArr);
    }

    const LoginTypes = () => {
        if (loginType === "") {
            return (
                <div className="accounts-cards">
                    {accounts.map((acc, i) => (
                        <div className="card" key={i}>
                            <AoIconButton 
                                className={"close"}
                                content={<Close />}
                                action={() => deleteAccount(acc.email)}
                            />
                            <Button 
                                onClick={() => handlePasswordLoging(acc)}
                                className={`button`} 
                            >
                                <div className="account-profile">
                                    <LazyLoadImage 
                                        src={acc.icon}
                                        alt={acc.name}
                                    />
                                </div>
                                <div className='account-name'>{acc.name}</div>
                            </Button>
                        </div>
                    ))} 
                    {accounts.length < 4 &&
                        <div className="card">
                            <Button 
                                onClick={() => setLoginType('emailAndPassword')}
                                className={`button`} 
                            >
                                <div className="account-profile">
                                    <AddCircleOutline />
                                </div>
                                <div className='account-name'>{t('words.add')}</div>
                            </Button>
                        </div>
                    }
                </div>
            )
        }

        return (
            <div className='flex flow-column gap-normal'>
                <Button 
                    style={{ margin: '-1px'}}
                    className='button left outline' 
                    onClick={() => {
                        setLoginType('');
                        setEmail('');
                    }}
                >
                    <ArrowBack sx={{fontSize: '1.25rem'}} />Volver
                </Button>
                <LoginForm newEmail={email} setEmail={setEmail} type={loginType} />
            </div>
        )
    }

    return (
        <div>
            <div className="accounts">
                {JSON.stringify(accounts) === '[]' ?
                    <LoginForm newEmail={email} setEmail={setEmail} type="emailAndPassword" />
                :
                    <LoginTypes />
                }
            </div>       
            <div className='flex gap-bigger flow-column'>
                <div className="divider with-o">
                    <span>{t('words.or')}</span>
                </div>
                <div className="flex flow-column gap-normal">
                    <AoButton 
                        icon={<Google />}
                        variant="secondary"
                        size={"big"}
                        title={t('auth.login google')}
                        action={loginWithGoogle}
                        loading={signInGoogle}
                    />
                </div>
            </div>
        </div>
    )
}
