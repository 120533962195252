import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/Auth'
import { Button } from '@mui/material';
import { LazyLoadImage } from '../../../../components/loading';
import { AddAPhoto, Brush, Edit } from '@mui/icons-material';
import { Modal, ModalButtons, useModalManager } from '../../../../components/modals';
import { ColorExtractor } from 'react-color-extractor';
import { BasicButton } from '../../../../components/buttons';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/form';
import { updateProfile } from 'firebase/auth';
import { auth } from '../../../../services/firebase/firebase';
import { enqueueSnackbar } from 'notistack';
import { verifiyName } from '../../../../utils/verify';
import { AvatarUpload } from '../../../../components/modals/dist/user-image';

const ModalContent = ({ close }) => {
  const { t } = useTranslation();
  const { account } = useContext(AuthContext);
  
  const [ nickName, setNickName ] = useState(account?.displayName)
  const [ disabled, setDisabled ] = useState(true);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);

  function handleSaveName() {
    setLoading(true);

    updateProfile(auth.currentUser, {
      displayName: nickName
    }).then(() => {
      setLoading(false);
      enqueueSnackbar(`¡Bien! Ahora te llamas ${nickName} 😉`, { variant: 'default' })
      close();
    })
  }

  async function handleVerify(value) {
    setNickName(value);
    if (nickName !== account?.displayName) {
      try {
        setDisabled(true);
        setLoading(true);

        const verify = await verifiyName({ text: value });
        
        if (verify.code === 0) {
          setDisabled(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error)
      }
    } else {
      setDisabled(true);
    }
  }

  return (
    <>
      <div>
        <Input 
          fullWidth
          value={nickName}
          type='text'
          label='Cambiar nombre de la cuenta'
          helperText='Este es el nombre del grupo de tu hogar y puede cambiarse en cualquier momento.'
          placeholder={account?.displayName}
          onChange={(e) => handleVerify(e.target.value)}
          error={error}
        />
      </div>
      <ModalButtons 
        buttons={[
          {
            label: t('words.cancel'),
            onClick: close
          },
          {
            label: t('words.save'),
            onClick: handleSaveName,
            variant: 'high',
            disabled: disabled,
            loading: loading
          }
        ]}
      />
    </>
  )
}

export default function AccountManager() {
  const { account } = useContext(AuthContext);
  const [ pfpColor, setPfpColor ] = useState();
  const { openModal, closeModal } = useModalManager();

  function handleChangeDisplayName() {
    openModal('account-name', (
      <Modal
        title='Cambiar nombre de la cuenta'
        severity='important'
        close={() => closeModal('account-name')}
      >
        <ModalContent />
      </Modal>
    ))
  }

  function handleOpenAvatarPicker() {
    openModal('avatar-picker', (
      <Modal
        title='Escoger Avatar'
        maxWidth={400}
        close={() => closeModal('avatar-picker')}
      >
        <AvatarUpload />
      </Modal>
    ))
  }

  console.log(pfpColor)

  return (
    <section className='settings-section'>
      <div className='profile-layout'>
        <div className='profile-header'>
          <div className='button div profile-banner' style={{ background: pfpColor && pfpColor[0]}}></div>
          <div className='flex gap-big' style={{ marginTop: '-3.25rem', marginLeft: '1rem'}}>
            <Button className='button profile-avatar' onClick={handleOpenAvatarPicker}>
              <ColorExtractor 
                src={account.photoURL}
                getColors={colors => setPfpColor(colors)}
              />
              <LazyLoadImage 
                src={account.photoURL}
                isRadius
              />
              <div className="hover"><Edit /></div>
              <div className="badge"><Brush /></div>
            </Button>
            <div className='flex align-center' style={{ paddingTop: '3.25rem', flex: 1}}>
              <BasicButton 
                onClick={handleChangeDisplayName}
                title={account.displayName}
                variant='text'
                fontSize={18}
                rightIcon={<Edit />}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
