import React, { useState } from 'react'
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import { CircularProgress, IconButton, TextareaAutosize, TextField } from '@mui/material';
import SearchIcon from '../../images/Search';

interface InputProps {
    type?: 'email' | 'password' | 'text' | 'text-area' | 'search';
    value?: string;
    defaultValue?: string;
    label?: string;
    placeholder?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    required?: boolean;
    maxRows?: number;
    minRows?: number;
    helperText?: string;
    loading?: boolean;
    error?: boolean;
    success?: boolean;
    fullWidth?: boolean;
}

const Input: React.FC<InputProps> = ({ 
    type,
    value,
    defaultValue,
    label,
    placeholder,
    onChange,
    required,
    maxRows,
    minRows,
    helperText,
    loading,
    error,
    success,
    fullWidth
}) => {
    const [ inputType, setInputType ] = useState(type ? type : 'text');

    const handlePasswordType = () => {
        if (inputType === "password"){
            setInputType("text");
        } else {
            setInputType("password");
        }
    }

    const EndAdornment = () => {
        if (loading) {
            return (
                <div style={{ marginRight: '-.5rem'}} className='flex margin-left-5 padding-5 justify-center align-center'>
                    <CircularProgress className='progress circular' size={'1.5em'} />
                </div>
            )
        }
        if (type === 'password') {
            return (
                <IconButton 
                    onClick={handlePasswordType} 
                    className='IconButton' 
                    style={{ 
                        marginRight: '-.5rem',
                        marginLeft: '.5rem'
                    }}
                >
                    {inputType === "password" && <VisibilityOff />}
                    {inputType === "text" && <RemoveRedEye />}
                </IconButton>
            )
        }

        if (type === 'search') {
            return (
                <IconButton
                    className='IconButton'
                    style={{ 
                        marginRight: '-.5rem',
                        marginLeft: '.5rem'
                    }}
                >
                    <SearchIcon className='' />
                </IconButton>
            )
        }
    }

    if (type === 'text-area') {
        return (
            <TextareaAutosize 
                maxRows={maxRows ? maxRows : 4}
                className={`input variant-1 text-area`}
                minRows={minRows ? minRows : 4}
                value={value}
                defaultValue={defaultValue}
                placeholder={label ? label : placeholder} 
                required={required}
                onChange={onChange}
            />
        )
    }

    return (
        <TextField 
            type={inputType}
            className={`input ${fullWidth ? 'w100' : ''} ${success ? 'success' : ''}`}
            label={label}
            placeholder={placeholder}
            required={required}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            InputProps={{
                endAdornment: (type === 'password') || (type === 'search') || (loading) ? <EndAdornment /> : false
            }}
            helperText={helperText}
            error={error}
        />
    )
}

export default Input;