import React, { useContext, useRef, useState } from 'react'
import { AvatarButton } from '../../../buttons';
import { Modal, ModalButtons, useModalManager } from '../..';
import { Delete, Edit } from '@mui/icons-material';
import { AuthContext } from '../../../../context/Auth';
import { AvatarEditor } from './';

interface AVatarUploadProps {
    close?: VoidFunction;
}

const AvatarUpload: React.FC<AVatarUploadProps> = ({
    close
}) => {
    const { account } = useContext(AuthContext);
    const { openModal, closeModal } = useModalManager();

    const pfpInputRef = useRef<HTMLInputElement | null>(null);

    const [ file, setFile ] = useState('');
    
    const [ loading, setLoading ] = useState(false);

    const handleCloseImage = () => {
        closeModal('edit-avatar')
    }

    const handleOpenImage = (file) => {
        openModal('edit-avatar', (
            <Modal 
                title='Ajusta la foto'
                close={handleCloseImage}
                maxWidth={500}
            >
                <AvatarEditor 
                    image={file}
                    closeParent={close}
                />
            </Modal>
        ))
    }

    const handleImageClick = () => {
        setLoading(true);
        if (pfpInputRef.current) {
            pfpInputRef.current.click();
        }

        setTimeout(() => {
            if (pfpInputRef.current && pfpInputRef.current.value === "") {
                setLoading(false);
            }
        }, 2000)
    }

    const handleImageChange = (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            setFile(URL.createObjectURL(e.target.files[0]));
            handleOpenImage(URL.createObjectURL(e.target.files[0]));

            setTimeout(() => {
                setLoading(false);
                if (pfpInputRef.current) {
                    pfpInputRef.current.value = '';
                }
            }, 50);
        } else {
            setLoading(false);
            if (pfpInputRef.current) {
                pfpInputRef.current.value = '';
            }
        }
    }

    return (
        <>
            <div className='margin-bottom-20'>
                <div className='font p-small'>
                    Una foto ayudará a diferenciarte de los demas ;)
                </div>
                <div className='margin-top-10 flex justify-center align-center'>
                    <AvatarButton 
                        onClick={handleImageClick}
                        src={account.photoURL}
                        fontSize={2}
                    />
                </div>
            </div>
            <ModalButtons 
                buttons={[
                    {
                        label: 'Cambiar',
                        icon: <Edit />,
                        variant: 'high',
                        onClick: handleImageClick,
                        loading: false,
                        disabled: false
                    },
                    {
                        label: 'Eliminar',
                        icon: <Delete />,
                        type: 'warning',
                        variant: "outlined",
                        onClick: handleImageChange,
                        loading: false,
                        disabled: false
                    }
                ]}
            />
            <input 
                type='file'
                accept='image/*'
                id="input-avatar"
                className='hide'
                onChange={handleImageChange}
                ref={pfpInputRef}
            />
        </>
    )
}

export default AvatarUpload;