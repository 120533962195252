import React from "react";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

interface SidebarSettingsButtonProps {
    title: string;
    icon: React.ReactNode;
    to: string;
    closeSidebar: VoidFunction;
}

const SidebarSettingsButton: React.FC<SidebarSettingsButtonProps> = ({ 
    icon, 
    title,
    to,
    closeSidebar
}) => {
    return (
        <Button
            className="button settings-sidebar"
            component={NavLink}
            to={to}
            onClick={closeSidebar}
        >
            {icon}
            <h3>{title}</h3>
        </Button>
    )
}

export default SidebarSettingsButton;