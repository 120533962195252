import { Autocomplete, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import countries from '../../services/countries/countries'
import { LazyLoadImage } from '../../components/loading'

export default function CountrySelector({ value, setValue, onChange }) {
    const [inputValue, setInputValue] = useState('');

    const options = countries?.map((option) => {
        const firstLetter = option.label[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option
        }
    });

    return (
        <>
            <Autocomplete 
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                autoHighlight
                value={value}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setValue(newValue.label)
                        onChange({
                            label: newValue.label,
                            code: newValue.code
                        })
                    } else {
                        setValue('');
                        onChange({
                            label: '',
                            code: ''
                        })
                    }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderOption={(props, option) => (
                    <Button {...props} className='button select-option'>
                        <LazyLoadImage 
                            width={30}
                            height={21}
                            src={`https://flagcdn.com/w80/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w160/${option.code.toLowerCase()}.png 2x`}
                        />
                        {option.label}
                    </Button>
                )}
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        className='input date-picker'
                        label="Escoge un país"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}  
                    />
                )}
            />
        </>
    )
}
