interface expiredProps {
    expirationTime: number;
}

interface expiredResult {
    expired: boolean;
}

function hasExpired({ expirationTime }: expiredProps): expiredResult {
    const currentTime = Date.now();
    return { expired: currentTime > expirationTime };
}

export default hasExpired;