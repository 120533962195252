import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Input } from '../../components/form';
import './index.scss';
import SearchIcon from '../../images/Search';
import { CircularProgress } from '@mui/material';
import Results from './Results';
import SEO from '../../services/seo';
import { useTranslation } from 'react-i18next';

export default function Search() {
  const params = useParams();
  const { t } = useTranslation();
  const [ title, setTitle ] = useState(t('SEO.searching.title'))
  const [ searchTerm, setSearchTerm ] = useState(params.search || "");
  const [ searching, setSearching ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSearching(true);
    if (searchTerm === "" || !searchTerm) {
      navigate(`/search`);
      setSearching(false);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      navigate(`/search/${encodeURIComponent(searchTerm)}`);
    }, 400);

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm]);

  useEffect(() => {
    if (params.search) {
      setTitle(`${t('SEO.searching.title')} "${params.search}"`);
    } else {
      setTitle(t('SEO.searching.title'))
    }
  }, [params.search])

  return (
    <>
      <SEO title={title} description={t('SEO.searching.desc')} />
      <div className='search'>
        <div className="search__input-layout">
          <Input 
            type='search'
            autoFocus
            value={searchTerm}
            label={t('form.search.placeholder')}
            placeholder='Busca el anime que quieras ;)'
            variant="search"
            loading={searching}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {searchTerm &&
          <>
            <Results
              searching={searching}
              setSearching={setSearching}
              type="relevant"
            />
            <Results 
              searching={searching}
              setSearching={setSearching}
              type="others"
            />
          </>
        }
      </div>
      </>
  )
}
