import React, { useContext, useEffect, useState } from 'react'
import Form from '../../../components/form/Form'
import {Input} from '../../../components/form'
import { AuthContext } from '../../../context/Auth'
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';
import { BasicButton } from '../../../components/buttons';
import i18n from '../../../services/i18n';

export default function Report() {
    const { t } = useTranslation();
    const { account, profile } = useContext(AuthContext);

    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ image, setImage ] = useState('');
    
    const [ sending, setSending ] = useState(false);
    const [ send, setSend ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);

    const handleSend = () => {
        setSending(true);
        const content = {
            "embeds": [{
              "title": `¡Nuevo reporte!`,
              "description": `## ${title} \n ${description}`,
              "color": 0xff5441,
              "fields": [
                  {
                    "name": "Usuario",
                    "value": account.displayName,
                    "inline": true
                  },
                  {
                    "name": "Correo",
                    "value": account.email,
                    "inline": true
                  },
                  {
                    "name": "uid",
                    "value": account.uid,
                    "inline": false
                  },
                  {
                    "name": "Perfil",
                    "value": profile.displayName,
                    "inline": true
                  },
                  {
                    "name": "Idioma",
                    "value": i18n.language,
                    "inline": true
                  }
              ],
              "thumbnail": {
                "url": `${account.photoURL}`,
                "height": 0,
                "width": 0
              }
            }]
          }

        fetch('https://discord.com/api/webhooks/1288030476371623977/NgZNDyK06du8AnVUKf2ObafyZ5c6RCcIV0Txbl8J3CAo9eKWLV3r2PVlqTs9no3c0P1_', {
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            setSending(false);
            setSend(true);
            setTitle('');
            setDescription('');
        }).catch((err) => {
            setSending(false);
        })
    }

    useEffect(() => {
        if (title.length > 3) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [title])

    if (send) {
        return (
            <div className="flex flow-column gap-10">
                <h2 className='font align-center'>{t('words.thanks')}</h2>
                <div className="buttons">
                    <BasicButton 
                        fullWidth
                        title={"Enviar otra petición"}
                        type='button'
                        variant='high'
                        onClick={() => setSend(false)}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <SEO title={`${t('settings.nav.community')} - ${t('settings.nav.report')}`} />
            <Form
                loading={sending}
                type={"send"}
                action={handleSend}
                disabled={disabled}
                bg={"https://i.ibb.co/6n9C60M/report-banner.png"}
            >
                <div className="flex flow-column gap-big" style={{marginBottom: '1rem'}}>
                    <Input 
                        type="text"
                        value={title}
                        variant={1}
                        required
                        label={'Reporte'}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <Input 
                        type='text-area'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant={1}
                        placeholder={'Descripción (Opcional)'}
                    />
                </div>
                <BasicButton 
                    fullWidth
                    title={t('words.send')}
                    type='submit'
                    variant='high'
                    loading={sending}
                    disabled={disabled}
                />
            </Form>
        </>
    )
}
