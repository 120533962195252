import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import getDBLang from '../../../components/hooks/getDBLang';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from '../../../components/loading';
import { duration } from '../../../utils/time';

const EpisodeButton = ({
    type,
    episodeObj,
    seasonObj
}) => {
    const { t } = useTranslation();
    const [ viewed, setViewed ] = useState(false);

    const actionType = (episodeObj && !seasonObj) ? 'episode' : (!episodeObj && seasonObj) ? 'season' : undefined;

    if (!episodeObj && !seasonObj) {
        return;
    }

    const formattedDuration = duration(episodeObj.duration)

    return (
        <div className="change-episode-body">
            <Link to={`/watch/${episodeObj.id}`} className='change-episode-body-title'>{t(`words.episode.${type}-${actionType}`)}</Link>
            <div className="card">
                <Link to={`/watch/${episodeObj.id}`} className='link'>
                    <div className="card-layout">
                        <div className="thumbnail-layout">
                            <div className="thumbnail-sized">
                                <div className="thumbnail">
                                    <LazyLoadImage 
                                        src={`${episodeObj.cover}`}
                                        key={`${episodeObj.cover}`}
                                    />
                                </div>
                            </div>
                            <span className='duration'>
                                {viewed ?
                                    <>
                                        {viewed === 'true' && t('words.watched')}
                                    </>
                                    :
                                    <>
                                        {formattedDuration}
                                    </>
                                }
                            </span>
                            {/* {viewed &&
                                <div className={`watched ${viewed}`}>
                                    {viewed === 'partial' && 
                                    <>
                                        <MoreHoriz />
                                        <div className="border"></div>
                                    </>
                                    }
                                    {viewed === 'true' && <Replay />}
                                </div>
                            } */}
                        </div>
                        <div className="data-layout">
                            <data className="data-body">
                                <h4 className='episode-title'>{`${t('words.shorts.episode')}${episodeObj.episode} - ${episodeObj.name}`}</h4>
                                <div className="episode-footer">
                                    <div className="episode-footer-meta">
                                        <div className="tags">
                                            {episodeObj.tags.map((tag, i) => (
                                                <span className='tag' key={i}>{t(`categories.langs.${tag}`)}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </data>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default function ChangeEpisode({
    episode,
    episodes,
    season,
    seasons
}) {

    if (!episode || !episodes || !season || !seasons) return;

    const currentEpisodeIndex = episodes.findIndex(x => x.episode === episode.info.episode);
    const currentSeasonIndex = seasons.findIndex(x => x.season === season.season);

    const nextEpisode = episodes[currentEpisodeIndex + 1];
    const previousEpisode = episodes[currentEpisodeIndex - 1];

    const nextSeason = seasons[currentSeasonIndex - 1];
    const previousSeason = seasons[currentSeasonIndex + 1]; 

    /* console.log('currentEpisodeIndex', currentEpisodeIndex);
    console.log('currentSeasonIndex', currentSeasonIndex);
    console.log('nextEpisode', nextEpisode);
    console.log('previousEpisode', previousEpisode);
    console.log('nextSeason', nextSeason);
    console.log('previousSeason', previousSeason);
 */
    return (
        <div className="data-sidebar">
            <div className="change-episode-layout">
                <EpisodeButton 
                    type="next"
                    episodeObj={nextEpisode}
                    seasonObj={nextSeason}
                    
                />
                <EpisodeButton 
                    type="prev"
                    episodeObj={previousEpisode}
                    seasonObj={previousSeason}
                />
            </div>
        </div>
    )

    /* if (seasons) {
        if ( next !== null || previous !== null ) {
            if (next === undefined && previous === undefined ) {
                if (seasons[currentSeasonIndex - 1] !== undefined || seasons[currentSeasonIndex + 1] !== undefined) {
                    return (
                        <div className="data-sidebar">
                            <div className="change-episode-layout">
                                {seasons[currentSeasonIndex + 1] &&
                                    <EpisodeButton title="next-season" serie={serie} type={"season"} season={seasons[currentSeasonIndex+ 1].id} />
                                }
                                {seasons[currentSeasonIndex - 1] &&
                                    <EpisodeButton title="prev-season" serie={serie} type={"season"} season={seasons[currentSeasonIndex- 1].id} />
                                }
                            </div>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="data-sidebar">
                        <div className='change-episode-layout'>
                            {next ? 
                                <EpisodeButton setReproductorNext={setReproductorNext} title="next" data={next} serie={serie} season={seasons[currentSeasonIndex].id} />
                                :
                                <>
                                    {seasons[currentSeasonIndex+ 1] &&
                                        <EpisodeButton setReproductorNext={setReproductorNext} title="next-season" serie={serie} type={"season"} season={seasons[currentSeasonIndex+ 1].id} />
                                    }
                                </>
                            }
                            {previous ?
                                <EpisodeButton title="prev" data={previous} serie={serie} season={seasons[currentSeasonIndex].id} />
                                :
                                <>
                                    {seasons[currentSeasonIndex- 1] &&
                                        <EpisodeButton title="prev-season" serie={serie} type={"season"} season={seasons[currentSeasonIndex- 1].id} />
                                    }
                                </>
                            }
                        </div>
                    </div>
                )
            }
        }
    } */
}
