import React from 'react';
import getDBLang from '../../components/hooks/getDBLang';
import verifyCacheResponse from '../verify/verifyCacheResponse';
import { getAnimeDatabase } from './';
import { doc, getDoc } from 'firebase/firestore';
import { createExpiration } from '../time';

interface dataAndCache {
  res: object | null;
}

interface ExpirationProps {
  value: number;
  unit: 'days' | 'months' | 'years';
}

interface getDataAndCacheProps {
  cacheLocation: 'anime';
  cacheID: string;
  fetchLocation: string;
  expirationDate: ExpirationProps;
  debug?: boolean;
}

async function getDataAndCache({
  cacheLocation,
  cacheID,
  fetchLocation,
  expirationDate,
  debug
}: getDataAndCacheProps): Promise<dataAndCache> {
  let cachedData: object | null = null;

  if (debug) {
    console.debug(`Debugging enabled`);
    console.debug(`Cache Location: ${cacheLocation}`);
    console.debug(`Cache ID: ${cacheID}`);
    console.debug(`Fetch Location: ${fetchLocation}`);
    console.debug(`Expiration Date:`, expirationDate);
  }

  if ('caches' in window) {
    const cache = await caches.open(`${cacheLocation}-${getDBLang()}`);
    if (debug) console.debug(`Cache opened: ${cacheLocation}-${getDBLang()}`);

    const response = await cache.match(`/${cacheID}`);
    if (debug) {
      console.debug(`Cache match response for /${cacheID}:`, response);
    }

    if (verifyCacheResponse(response)) {
      if (debug) console.debug(`Valid cache response found. Parsing JSON.`);
      cachedData = await response?.json();
      if (debug) console.debug(`Cached data retrieved:`, cachedData);
      return { res: cachedData };
    } else {
      if (debug) console.debug(`No valid cache found. Fetching from Firestore.`);
      try {
        const { firestore } = getAnimeDatabase();
        const dataRef = doc(firestore, `${fetchLocation}`);
        const dataSnap = await getDoc(dataRef);

        if (debug) console.debug(`Firestore document reference:`, dataRef);

        if (dataSnap.exists()) {
          const dataData = dataSnap.data();
          cachedData = dataData;

          if (debug) {
            console.debug(`Document snapshot exists. Data retrieved:`, dataData);
          }

          if (cache) {
            const headers = new Headers();
            headers.append(
              'expire',
              createExpiration({ value: expirationDate.value, unit: expirationDate.unit }).expire.toString()
            );

            if (debug) {
              console.debug(`Storing data in cache with expiration:`, headers.get('expire'));
            }

            await cache.put(
              `/${cacheID}`,
              new Response(JSON.stringify(dataData), {
                headers: headers,
              })
            );
          }
        } else if (debug) {
          console.debug(`Document snapshot does not exist.`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (debug) console.debug(`Error details:`, error);
      }
    }
  } else if (debug) {
    console.debug(`Caches not supported in the current environment.`);
  }

  if (debug) console.debug(`Final cachedData:`, cachedData);
  return { res: cachedData };
}

export default getDataAndCache;
