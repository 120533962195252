import React, { useEffect, useState } from 'react'
import Form from '../../components/form/Form'
import { useTranslation } from 'react-i18next'
import {Input} from '../../components/form';
import AoButton from '../../components/buttons/Button';
import AuthMethods from '../../components/form/AuthMethods';
import { sendEmailVerification } from 'firebase/auth';
import { auth, firestore } from '../../services/firebase/accounts';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import SEO from '../../services/seo';
import { createUserWithEmailAndPassword } from '../../services/firebase/auth';

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState(''); 

  const [ emailError, setEmailError ] = useState(false);
  const [ passwordError, setPasswordError ] = useState(false);
  const [ cPasswordError, setCPasswordError ] = useState(false);

  const [ disabled, setDisabled ] = useState(true);

  const [ isLoading, setLoading ] = useState(false);
  const [ localError, setLocalError ] = useState('');

  const [ error, setError ] = useState('');
  const [ formLoading, setFormLoading ] = useState(false);

  const register = async () => {
    if (disabled === false) {
      setLoading(true);
      setFormLoading(true);
      try {
        const newUser = await createUserWithEmailAndPassword(email, password)
        
        if (!newUser.success) {
          return { success: false, error: newUser.error }
        }
        
        navigate('/auth/complete-auth');
      } catch (error) {
        console.error('Error creando usuario.', error)
      }      

      /* createUserWithEmailAndPassword(auth, email, password)
        .then(async () => {
          const currentUser = auth.currentUser;
          const newUserRef = doc(firestore, `usuarios/${currentUser.uid}`);
          setDoc(newUserRef, {
            name: ["", ""],
            badges: ["private"]
          }).then(() => {
            sendEmailVerification(auth.currentUser);
          })
        })
        .catch((error) => {
          setError(error.code);
          setLoading(false);
          setFormLoading(false);
          setDisabled(true);
        }) */
    }
  }

  useEffect(() => {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (email.length < 1) {
        setEmailError(false);
        return;
    }

    if (email.match(validRegex)) {
        setEmailError(false);
    } else {
        setEmailError(true);
    }
  }, [email]);

  useEffect(() => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@!#$%&?._-]{8,30}$/
    
    if (password.length < 1) {
      setPasswordError(false);
      return;
    }

    if (password.length < 8) {
      setPasswordError(true);
      return;
    }

    if (password.length > 30) {
      setPasswordError(true);
      return;
    }

    if (password.match(passwordRegex)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword.length < 1) {
      setCPasswordError(false);
      return;
    }

    if (password === confirmPassword) {
      setCPasswordError(false);
    } else {
      setCPasswordError(true);
    }
  }, [confirmPassword]);

  useEffect(() => {
    if (email.length > 0 && password.length > 0 && confirmPassword.length > 0) {
      if (!emailError && !passwordError && !cPasswordError) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [email, password, confirmPassword, emailError, passwordError, cPasswordError])

  /* useEffect(() => {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@!#$%&?._-]{8,30}$/
    setDisabled(true);
    if (email.length > 0) {
      if (email.match(validRegex)) {
        setLocalError(false);
        if (password.length > 0) {
          if (password.length > 7) {
            if (password.length > 30) {
              setLocalError('auth/password-too-long')
            } else {
              if (password.match(passwordRegex)) {
                if (confirmPassword.length > 0) {
                  if (password !== confirmPassword) {
                    setLocalError('auth/password-dont-match');
                  } else {
                    setLocalError(false);
                    setDisabled(false);
                  }
                } else {
                  setLocalError(false);
                }
              } else {
                setLocalError('auth/password-insecure');
              }
            }
          } else {
            setLocalError('auth/password-too-less');
          }
        } else {
          setLocalError(false);
        }
      } else {
        setLocalError('auth/invalid-email');
      }
    } else {
      setLocalError(false);
    }
  }, [email, password, confirmPassword]) */

  return (
    <>
      <SEO title={t('SEO.register.title')} description={t('SEO.register.desc')} />
      <Form
        type="auth"
        action={register}
        title={t('auth.register')}
        loading={formLoading}
        error={error}
        bg="https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/ao_register_image.webp?alt=media&token=9392b210-711d-4c35-a6d3-a1f97ed64d98"
      >
        <div className="flex flow-column gap-big">
          <Input 
            type="email"
            value={email}
            placeholder={t('auth.email.placeholder')}
            onChange={(e) => setEmail(e.target.value)}
            label={t('auth.email.label')}
            required
            helperText={emailError && 'El email no es válido.'}
            error={emailError}
          />
          <Input 
            type="password"
            value={password}
            placeholder={t('auth.password.placeholder')}
            onChange={(e) => setPassword(e.target.value)}
            label={t('auth.password.label')}
            required
            error={passwordError}
            helperText={passwordError && 'La contraseña no puede tener menos de 8 carácteres o mas de 30. Debe tener una letra mayúscula, una minúscula, un número y un carácter especial (@, $, !, %, #, ?, &)'}
          />
          <Input 
            type="password"
            value={confirmPassword}
            variant={1}
            placeholder={t('auth.confirm password.placeholder')}
            onChange={(e) => setConfirmPassword(e.target.value)}
            label={t('auth.confirm password.label')}
            required
            error={cPasswordError}
            helperText={cPasswordError && 'Las contraseñas no coinciden.'}
          />
        </div>
        {localError && <span className='font-style color-negative' style={{margin: '-1rem 0'}}>{t(`error.${localError}`)}</span>}
        <AoButton 
          variant="special-2 rounded"
          title={t('auth.register')}
          loading={isLoading}
          submit={true}
          disabled={disabled}
        />
        <div className="flex flow-column gap-big align-center">
          <span>{t('auth.has account')}</span>
          <AoButton 
            to="/auth/login"
            type="link"
            variant="secondary"
            size="big"
            title={t('auth.login')}
          />
        </div>
        <AuthMethods 
          setFormError={setError}
          setFormLoading={setFormLoading}
        />
      </Form>
    </>
  )
}
