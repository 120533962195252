import React, { useState } from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import { LazyLoadImage } from '../../../components/loading'

export default function AnimeHeader({ loading, anime, animeData }) {
    
    if (loading) {
        return (
            <div className="anime-landing-header">
                <div className="anime-landing-header-layout">
                    <div className="background"></div>
                    <div className="body">
                        <div className="cover tall"></div>
                        <div className="cover wide"></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="anime-landing-header">
            <ParallaxBanner 
                layers={[
                    {
                        image: anime.images.background ? anime.images.background : anime.images.wide,
                        speed: -30
                    }
                ]}
                className='anime-landing-background'
            />
            <div className="gradient-transition"></div>
            <div className="anime-landing-header-layout">
                <div className="background">
                    <div className="image">
                        <LazyLoadImage 
                            src={anime.images.wide}
                            alt={`${anime.name} - aodesu`}
                        />
                    </div>
                </div>
                <div className="body">
                    <button className="cover tall">
                        <LazyLoadImage 
                            src={anime.images.tall}
                            alt={`${anime.name} - aodesu`}
                            borderRadius={8}
                        />
                    </button>
                    <button className="cover wide">
                        <LazyLoadImage 
                            src={anime.images.wide}
                            alt={`${anime.name} - aodesu`}
                        />
                    </button>
                    {animeData}
                </div>
            </div>
        </div>
    )
}
