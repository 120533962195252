const prefixes = [
    "Kaze", "Hikari", "Yami", "Tenshi", "Akuma", "Kuro", "Shiro", "Sakura", "Ryu", "Hana"
];
const suffixes = [
    "Kami", "No Yoru", "No Kaze", "Tora", "Senshi", "Shinigami", "No Tenshi", "Raito", "Hime", "Ryuu"
];

export function generateRandomName() {
    const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    const suffix = suffixes[Math.floor(Math.random() * suffixes.length)];

    return `${prefix}${suffix}`;
}
