import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AoButton from '../buttons/Button';
import { Google } from '@mui/icons-material';
import { GoogleAuthProvider, signInWithCustomToken, signInWithPopup } from 'firebase/auth';
import { auth, firestore, yugaAuth } from '../../services/firebase/accounts';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AlertsContext } from '../../context/Alerts';
import YuugaIcon from '../../images/YugaLogo';
import YugaIcon from '../../images/YugaLogo';
import { signInWithPopupYuga } from '@yugacore/auth';

const googleProvider = new GoogleAuthProvider();

export default function AuthMethods({ setFormLoading, setFormError, changeAccount }) {
  const { t } = useTranslation();
  const { addAlert } = useContext(AlertsContext);
  const navigate = useNavigate();
  const lastPath = sessionStorage.getItem('back-path');
  const [ signInGoogle, setSignInGoogle ] = useState(false);
  const [ signInYuga, setSignInYuga ] = useState(false);

  const successAlert = () => {
    addAlert("¡Bienvenido de vuelta!", "branch", "filled", "auto");
  }

  const loginWithYuga = async () => {
    setSignInYuga(true);
    setFormLoading(true);

    try {
      const response = await signInWithPopupYuga(yugaAuth, 'signInWithCustomToken');
    
      await signInWithCustomToken(auth, response.customToken);

      if (response.popup) {
        response.popup.close();
      } else {
        console.warn('Popup is already closed or not available.')
      }
      setFormLoading(false);
    } catch (error) {
      console.error('Login error:', error);
      setSignInYuga(false)
      setFormLoading(false);
  }
  }

  const loginWithGoogle = () => {
    setSignInGoogle(true);
    setFormLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        /* const credential = GoogleAuthProvider.credentialFromResult(result); */
        async function submitAccount() {
          const userRef = doc(firestore, `usuarios/${auth.currentUser.uid}`);
          const userData = await getDoc(userRef);

          if (userData.exists()) {
            if (lastPath) {
              navigate(lastPath);
              sessionStorage.removeItem('back-path');
            } else {
              navigate('/');
            }
            setSignInGoogle(false);
            setFormLoading(false);
            successAlert();
          } else {
            setDoc(userRef, {
              name: ["", ""],
              badges: ["private"]
            }).then(() => {
              navigate('/auth/complete-auth');
            })
          }
        }

        submitAccount();
      })
      .catch((error) => {
        setFormError(error.code);
        setSignInGoogle(false);
        setFormLoading(false);
      })
  }

  return (
    <div className='flex gap-bigger flow-column'>
      <div className="divider with-o">
          <span>{t('words.or')}</span>
      </div>
      <div className="flex flow-column gap-normal">
        <AoButton 
          icon={<Google />}
          variant="secondary"
          size={"big"}
          title={t('auth.login google')}
          action={loginWithGoogle}
          loading={signInGoogle}
        />
        <AoButton 
          icon={<YugaIcon />}
          variant="secondary"
          size={"big"}
          title={t('auth.login yuga')}
          action={loginWithYuga}
          loading={signInYuga}
        />
      </div>
    </div>
  )
}
