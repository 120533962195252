import React from 'react'
import LanguageSelector from './Language'
import SEO from '../../../services/seo'
import { useTranslation } from 'react-i18next'

export default function Language() {
    const { t } = useTranslation();
    return (
        <div>
            <SEO title={`${t('settings.nav.preferences')} - ${t('settings.nav.language')}`} />
            <LanguageSelector />
        </div>
    )
}
