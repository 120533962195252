import {
    createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
    updateProfile
} from 'firebase/auth'

import { doc, setDoc } from "firebase/firestore";
import generateUid from "../../utils/generateUID";
import { auth, firestore, userFirestore, usersFirestore } from "./firebase";
import { generateRandomName } from '../../utils/generateRandomName';

export async function createAccountProfile() {
    const PUID = `PUID${generateUid()}`;

    try {

    } catch (error) {
        console.error(error)
    }
}

export async function saveAccountData(userCredential) {
    const AUID = `AUID${generateUid()}`;
    const randomName = generateRandomName();

    try {
        const authenticationRef = doc(firestore, `${userCredential.uid}/advanced`);

        await setDoc(authenticationRef, {
            auid: AUID
        })

        await updateProfile(userCredential, {
            displayName: randomName
        })

        return true;
    } catch (error) {
        console.error("Error saving account data:", error);
        throw error;
    }
}

export async function createUserWithEmailAndPassword(email, password) {
    try {
        const userCredential = await _createUserWithEmailAndPassword(auth, email, password);
        await saveAccountData(userCredential.user);
        return { success: true, user: userCredential.user };
    } catch (error) {
        console.error('Error creating user:', error);
        return { success: false, error }
    }
}