import React from 'react'
import { Outlet } from 'react-router-dom'
import AuthHeader from '../../components/navigation/AuthHeader'
import Footer from '../../components/navigation/Footer'
import './Auth.scss';

export default function AuthLayout() {
  return (
    <>
      <AuthHeader />
      <Outlet />
      <Footer />
    </>
  )
}
