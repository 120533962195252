import React from 'react';
import { AuthProvider } from './context/Auth';
import { AlertsProvider } from './context/Alerts';
import { SettingsProvider } from './context/Settings';
import { ModalsProvider } from './context/Modals';
import { ContentListsProvider } from './context/ContentLists';
import { BuyPremiumProvider } from './context/BuyPremium';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { SnackbarProvider } from 'notistack';
import SlideTransition from './utils/transition/SlideTransition';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/es';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ParallaxProvider } from 'react-scroll-parallax';

dayjs.extend(utc);

function App({ children }) {
  return (
    <ParallaxProvider>
      <SettingsProvider>
        <AuthProvider>
          <SnackbarProvider maxSnack={3} TransitionComponent={SlideTransition} autoHideDuration={3000}>
            <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs}>
              <BuyPremiumProvider>
                <PayPalScriptProvider
                  options={{
                    clientId: String(process.env.REACT_APP_PAYPAL_CLIENT_ID),
                    components: 'buttons',
                    currency: "USD",
                  }}
                >
                  <ModalsProvider>
                    <AlertsProvider>
                      <ContentListsProvider>
                        {children}
                      </ContentListsProvider>
                    </AlertsProvider>
                  </ModalsProvider>
                </PayPalScriptProvider>
              </BuyPremiumProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </AuthProvider>
      </SettingsProvider>
    </ParallaxProvider>
  );
}

export default App;
