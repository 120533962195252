import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { auth, firestore, storage } from "../services/firebase/accounts";
import { onAuthStateChanged } from "firebase/auth";
import { SettingsContext } from "./Settings";
import signOutFn from "../utils/firebase/signOut.tsx";
import { collection, getDocs } from "firebase/firestore";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ account, setAccount ] = useState(undefined);
    const [ profile, setProfile ] = useState(null);

    const [ existsAccount, setExistsAccount ] = useState(undefined);

    const [ refreshing, setRefreshing ] = useState(true);
    const [ refreshingProfile, setRefreshingProfile ] = useState(false);
    const [ adsLength, setAdsLength ] = useState(null);
    const [ userLoading, setUserLoading ] = useState(false);
    const { notAllowAdult } = useContext(SettingsContext);

    // console.log(account)

    const handleSelectProfile = () => {
        setRefreshing(true);
        setProfile(null);
        localStorage.removeItem('current_profile');
    }

    const fastRefresh = () => {
        setRefreshing(true);
        setTimeout(() => {
          setRefreshing(false);
        }, 500)
    }

    async function signOut() {
        signOutFn({
            setAccount: setAccount,
            setProfile: setProfile
        }).then(() => {
            setRefreshing(true);

            setTimeout(() => {
                setRefreshing(false)
            }, 200)
        })
    }

    const checkIfCollectionExists = useCallback(async (collectionName) => {
        try {
            const collectionRef = collection(firestore, collectionName);
            const snapshot = await getDocs(collectionRef);
            return !snapshot.empty;
        } catch (error) {
            console.error('Error verificando la colección:', error);
            return;
        }
    }, []);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            try {
                if (user) {
                    setAccount(user);
                    const collectionExists = await checkIfCollectionExists(user.uid);
                    setExistsAccount(collectionExists);
                    setProfile(undefined);
                } else {
                    setAccount(null);
                    setProfile(null);
                    setRefreshing(false);
                }
            } catch (error) {
                console.error('error', error)
            }
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                account,
                setAccount,
                profile,
                setProfile,
                refreshing,
                setRefreshing,
                handleSelectProfile,
                signOut,
                refreshingProfile,
                setRefreshingProfile,
                fastRefresh,
                existsAccount,
                setExistsAccount
                /* adsLength,
                userLoading, */
                /* getUserData,*/
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}