import React from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Comments from '../../../../components/actions/Comments'
import { useParams } from 'react-router-dom'

export default function AnimeComments({ anime }) {
    const params = useParams();
    return (
        <LazyLoadComponent>
            <Comments 
                id={params.id.split('-')[0]}
                name={anime.name}
            />
        </LazyLoadComponent>
    )
}
