import { Avatar, Button, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import AoButton from '../../buttons/Button';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/Auth';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../services/firebase/accounts';
import { updateProfile } from 'firebase/auth';
import { ModalsContext } from '../../../context/Modals';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const data = [
    /* {
        "name": "Testers",
        "images":[
            {
                "ch": "uwu1",
                "url": "https://i.ibb.co/PjMpJt8/5971fc01f42feb9f1be8a8eeb5d4d696.jpg"
            },
        ]
    }, */
    {
        "name": "Oshi no Ko",
        "images":[
            {
                "ch": "Ai",
                "url": "https://i.ibb.co/vBnPzLt/Ai.jpg"
            },
            {
                "ch": "Aquamarine",
                "url": "https://i.ibb.co/ng4jQ0c/Aquamarine.jpg"
            },
            {
                "ch": "Ruby",
                "url": "https://i.ibb.co/vVytLMK/Ruby.jpg"
            }
        ]
    },
    {
        "name": "Demon Slayer: Kimetsu no Yaiba",
        "images":[
            {
                "ch": "Nezuko",
                "url": "https://i.ibb.co/jDSsrtT/Nezuko.jpg"
            },
            {
                "ch": "Tanjiro",
                "url": "https://i.ibb.co/Y0HrzCg/Tanjiro.jpg"
            },
            {
                "ch": "Inosuke",
                "url": "https://i.ibb.co/vc7rN6G/Inosuke.jpg"
            },
            {
                "ch": "Zenitsu",
                "url": "https://i.ibb.co/6FNc6YT/Zenitsu.jpg"
            },
            {
                "ch": "Tomioka",
                "url": "https://i.ibb.co/qd4hdy6/Tomioka.jpg"
            },
            {
                "ch": "Rengoku",
                "url": "https://i.ibb.co/dQq9Dy4/Rengoku.jpg"
            },
            {
                "ch": "Shinobu",
                "url": "https://i.ibb.co/xmZz39t/Shinobu.jpg"
            },
            {
                "ch": "Uzui",
                "url": "https://i.ibb.co/xMBryDJ/Uzui.jpg"
            },
            {
                "ch": "Mitsuri",
                "url": "https://i.ibb.co/3YLvbDZ/Mitsuri.jpg"
            },
            {
                "ch": "Tokito",
                "url": "https://i.ibb.co/HYXQd1S/Tokito.jpg"
            },
            {
                "ch": "El patron",
                "url": "https://i.ibb.co/LtPSBD7/El-patron.jpg"
            },
            {
                "ch": "Kibutsuji",
                "url": "https://i.ibb.co/v4pFkLN/Kibutsuji.jpg"
            },
            {
                "ch": "Doma",
                "url": "https://i.ibb.co/LZWrwgj/Doma.jpg"
            }
        ]
    },
    {
        "name": "Chainsaw Man",
        "images":[
            {
                "ch": "Power",
                "url": "https://i.ibb.co/6wvSpMF/Power.jpg"
            },
            {
                "ch": "Makima",
                "url": "https://i.ibb.co/W3LxzbM/Makima.jpg"
            },
            {
                "ch": "Kobeni",
                "url": "https://i.ibb.co/6XwHSGQ/Kobeni.jpg"
            },
            {
                "ch": "Himeno",
                "url": "https://i.ibb.co/Yd48cc2/Himeno.jpg"
            },
            {
                "ch": "Denji",
                "url": "https://i.ibb.co/WGSrQr7/Denji.jpg"
            },
            {
                "ch": "Aki",
                "url": "https://i.ibb.co/yPVSffC/Aki.jpg"
            },
            {
                "ch": "Angel Devil",
                "url": "https://i.ibb.co/bJ4v2KM/Angel-Devil.jpg"
            }
        ]
    },
    {
        "name": "Bocchi the Rock!",
        "images":[
            {
                "ch": "Kita",
                "url": "https://i.ibb.co/SP8c4rd/Kita.jpg"
            },
            {
                "ch": "Hirori",
                "url": "https://i.ibb.co/WFBG1Vx/Hitori.jpg"
            },
            {
                "ch": "Nijika",
                "url": "https://i.ibb.co/Zzdb4zD/Nijika.jpg"
            },
            {
                "ch": "Ryo",
                "url": "https://i.ibb.co/9bxFRv4/Ryo.jpg"
            }
        ]
    },
    {
        "name": "Kono Suba!",
        "images":[
            {
                "ch": "Megumin",
                "url": "https://i.ibb.co/yntyQpJ/Megumin.jpg"
            },
            {
                "ch": "Aqua",
                "url": "https://i.ibb.co/Y8F89nJ/Aqua.jpg"
            },
            {
                "ch": "Darkness",
                "url": "https://i.ibb.co/DbPQCCB/Darkness.jpg"
            },
            {
                "ch": "Kazuma",
                "url": "https://i.ibb.co/YhSndB2/Kazuma.jpg"
            }
        ]
    },
    {
        "name": "Spy X family",
        "images":[
            {
                "ch": "Yor",
                "url": "https://i.ibb.co/VCF7wcj/Yor.jpg"
            },
            {
                "ch": "Loid",
                "url": "https://i.ibb.co/JkTr7Sk/Loid.jpg"
            },
            {
                "ch": "Anya",
                "url": "https://i.ibb.co/1qVzz76/Anya.jpg"
            }
        ]
    }
]

export default function SwiperAvatars({ image, setImage, loading, setLoading }) {
    const { t } = useTranslation();

    const handleImage = (url) => {
        setImage(url);
    }

    return (
        <div className={`swiper-customize ${loading && 'loading'}`}>
            {data.map((doc, i) => (
                <div key={i} className='section'>
                    <h3>{doc.name}</h3>
                    <div className="swiper-layout">
                        <div className={`swiper-button button-prev-${i}`}>
                            <AoButton 
                                icon={<ArrowForwardIos style={{rotate: '180deg'}} />}
                            />
                        </div>
                        <Swiper
                            slidesPerView={"auto"}
                            className='swiper'
                            navigation={{
                                nextEl: `.button-next-${i}`,
                                prevEl: `.button-prev-${i}`,
                                disabledClass: 'disabled',
                                clickable: true,
                            }}
                            modules={[Pagination, Navigation]}
                        >
                            {doc.images.map((doc, i) => (
                                <SwiperSlide key={i}>
                                    <Button className='button' onClick={() => handleImage(doc.url)}>
                                        <div className="img">
                                            <LazyLoadImage 
                                                src={doc.url}
                                                width={76}
                                                height={76}
                                                effect='blur'
                                                alt={`Aodesu | ${doc.ch}`}
                                            />
                                        </div>
                                        <span>{doc.ch}</span>
                                    </Button>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={`swiper-button button-next-${i}`}>
                            <AoButton 
                                variant="primary"
                                icon={<ArrowForwardIos />}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
