import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getYugaAuth } from '@yugacore/auth';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    databaseURL: process.env.REACT_APP_DATABASE_URL
};

const yugaConfig = {
    appId: process.env.REACT_APP_YUGA_APP_ID,
    client_secret: process.env.REACT_APP_YUGA_CLIENT_SECRET
}

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);
export const userFirestore = getFirestore(app, 'user');
export const usersFirestore = getFirestore(app, 'users');

export const animeEsFirestore = getFirestore(app, 'anime-es');
export const animeEnFirestore = getFirestore(app, 'anime-en');

export const functions = getFunctions(app);

export const database = getDatabase(app);

export const storage = getStorage(app);
export const userStorage = getStorage(app, 'gs://users-aodesu');
export const accountStorage = getStorage(app, 'gs://accounts-aodesu');

export const animeEsStorage = getStorage(app, 'gs://anime-es')
export const animeEnStorage = getStorage(app, 'gs://anime-en')

export const isFirebaseAppInitialized = getApps().length === 0 ? false : true;

export const yugaAuth = getYugaAuth(yugaConfig, firebaseConfig, app)

export default app;