import { createContext, useEffect, useState } from "react";
import i18n from "../services/i18n";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [ loading, setLoading ] = useState(false);

    const [ theme, setTheme ] = useState();

    const [ submitting, setSubmitting ] = useState(false);

    const [ R17, setR17 ] = useState(false);
    const [ Rmd, setRmd ] = useState(false);
    const [ Rx, setRx ] = useState(false);

    function changeTheme(value){
        const html = document.getElementById('html');
        const body = document.getElementById('body');

        if (value === "sp") {
            setTheme('sp');
            localStorage.setItem('theme', 'sp');
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                html.style.setProperty('--color-scheme', 'dark');
                body.setAttribute('class', '');
                body.classList.add(`theme-dark`);
            } else {
                html.style.setProperty('--color-scheme', 'light');
                body.setAttribute('class', '');
                body.classList.add(`theme-light`);
            }
        } else {
            setTheme(value);
            localStorage.setItem('theme', value);
            html.style.setProperty('--color-scheme', value);
            body.setAttribute('class', '');
            body.classList.add(`theme-${value}`);
        }
    }

    useEffect(() => {
        const html = document.getElementById('html');
        html.setAttribute('lang', i18n.language);
    }, [i18n]);

    useEffect(() => {
        const html = document.getElementById('html');
        const body = document.getElementById('body');
        const local = localStorage.getItem('theme');

        function systemPreference() {
            setTheme('sp');
            localStorage.setItem('theme', 'sp');
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                html.style.setProperty('--color-scheme', 'dark');
                body.setAttribute('class', '');
                body.classList.add(`theme-dark`);
            } else {
                html.style.setProperty('--color-scheme', 'light');
                body.setAttribute('class', '');
                body.classList.add(`theme-light`);
            }
        }

        if (local) {
            if (local === "sp") {
                systemPreference();
            } else {
                setTheme(local);
                html.style.setProperty('--color-scheme', local);
                body.setAttribute('class', '');
                body.classList.add(`theme-${local}`);
            }
        } else {
            systemPreference();
        }

    }, [theme])

    const handleR17 = () => {
        setR17(!R17);
        localStorage.setItem('R-17+', !R17);
    }

    const handleRmd = () => {
        setRmd(!Rmd);
        localStorage.setItem('R+', !Rmd);
    }

    const handleRx = () => {
        setRx(!Rx);
        localStorage.setItem('Rx', !Rx);
    }

    const notAllowAdult = () =>{
        setR17(false);
        setRmd(false);
        setRx(false);

        localStorage.setItem('R-17+', false);
        localStorage.setItem('R+', false);
        localStorage.setItem('Rx', false);
    }

    useEffect(() => {
        const r17 = localStorage.getItem('R-17+');
        const rmd = localStorage.getItem('R+');
        const rx = localStorage.getItem('Rx');

        if (r17) {
            setR17(r17 === 'true' || r17 === true && true);
        } else {
            setR17(false);
        }

        if (rmd) {
            setRmd(rmd === 'true' || rmd === true && true);
        } else {
            setRmd(false);
        }

        if (rx) {
            setRx(rx === 'true' || rx === true && true);
        } else {
            setRx(false);
        }
    }, []);

    window.addEventListener("resize", () => {
        const body = document.querySelector('body');
        body.style.setProperty('--innerHeight', `${window.innerHeight}px`);
    })

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.setProperty('--innerHeight', `${window.innerHeight}px`);
    }, [])

    return (
        <SettingsContext.Provider
            value={{
                notAllowAdult,
                R17,
                Rmd,
                Rx,
                handleR17,
                handleRmd,
                handleRx,
                theme,
                changeTheme,
                submitting,
                setSubmitting,
                settingsLoading: loading,
                setSettingsLoading: setLoading
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}