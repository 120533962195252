import React, { useContext, useEffect, useState } from 'react'
import ReproductorLoading from './ReproductorLoading';
import { AuthContext } from '../../../context/Auth';
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { ArrowBackRounded, Close, ClosedCaption, RecordVoiceOver, Settings } from '@mui/icons-material';
import { LazyLoadImage } from '../../../components/loading';
import { useTranslation } from 'react-i18next';
import { Modal, ModalButtons, useModalManager } from '../../../components/modals';
import AoButton from '../../../components/buttons/Button';

const SelectReproductor = ({ type, data, setVideo, video, close }) => {
    const { t } = useTranslation();

    const [ localVideo, setLocalVideo ] = useState('');

    const handleSaveChange = () => {
        if (!localVideo) {
            return;
        }

        setVideo(localVideo);
        close();
    }

    return (
        <div
            style={{
                display: 'flex',
                flexFlow: 'column'
            }}
        >
            {(data.premium || data.premium === null) &&
                <>
                    <AoButton 
                        type={"select"}
                        className={`theme ${video === data.premium && 'active'}`}
                        action={() => {
                            /* if (data.premium === null) {
                                openOnlyPremium();
                            } else {
                                handleCurrentVideo(data.premium);
                            } */
                        }}
                        title={t('words.premium')}
                        disabled={data.premium === null ? true : false}
                    />
                    <div className="divider" style={{ marginBottom: '1rem'}}></div>
                </>
            }
            {data.others &&
                <>
                    <span className='font p-small margin-bottom-5'>Estos reproductores pueden contener ventanas emergentes externas a nuestro sitio.</span>
                    {data.others.map((reproductor, i) => {
                        const url = reproductor;
                        const match = reproductor.replace(/.+\/\/|www.|\..+/g, '');
                        return (
                            <AoButton 
                                key={i}
                                type={"select"}
                                className={`theme ${!localVideo ? video === url && 'active' : localVideo === url && 'active' }`}
                                action={() => setLocalVideo(url)}
                                title={match}
                            />
                        )
                    })}
                </>
            }
            <ModalButtons 
                buttons={[
                    {
                        label: t('words.confirm'),
                        variant: 'high',
                        onClick: handleSaveChange,
                        disabled: localVideo ? false : true
                    }
                ]}
            />
        </div>
    )
}

export default function Reproductor({ episode, episodeLoading }) {
    const { t } = useTranslation();
    const { account, profile } = useContext(AuthContext);
    const { openModal, closeModal } = useModalManager();

    const [ help, setHelp ] = useState(false);
    
    /* const videos = episode.videos ? episode.videos : null; */

    const [ video, setVideo ] = useState('');
    const [ loading, setLoading ] = useState(true);
    

    /* function autoReproductor() {
        if (account === undefined) {
            setVideo(videos[0].others[0])
            setLoading(false);
            return;
        }

        setVideo(videos[0].others[1])
        setLoading(false);
        return;
    } */

    function handleBack() {
        setVideo('');
        setLoading(true);
    }

    function openReproductorSelector(type, data) {
        openModal('reproductor-selector', (
            <Modal
                close={() => closeModal('reproductor-selector')}
                title={t(`categories.langs.${type}-long`)}
            >
                <SelectReproductor 
                    type={type}
                    data={data}
                    video={video}
                    setVideo={setVideo}
                />
            </Modal>
        ))
    }

    useEffect(() => {
        const finder = localStorage.getItem('h-reproductor');

        if (finder) {
            setHelp(false);
        } else {
            setHelp(true);
        }
    }, [])

    /* useEffect(() => {
        if (videos) {
            if (!video) {
                console.log(1)
                autoReproductor();
            }
        }
    }, [videos, loading]) */

    if (episodeLoading) return <ReproductorLoading />;
    
    return (
        <div className='video-layout'>
            {video ? 
                <div className={`reproductor ${loading ? 'loading' : ''}`}>
                    <div className="reproductor-msg loader">
                        <div className="loading progress"><CircularProgress className='progress-circular' /></div>
                    </div>
                    
                    <iframe 
                        className='video'
                        src={video}
                        allow='autoplay'
                        allowFullScreen
                        onLoad={() => setLoading(false)}
                    />

                    <div className="button-section">
                        <Button onClick={handleBack} className='button button-text'>
                            <ArrowBackRounded />
                            <span className="title">{episode.info.name}</span>
                        </Button>
                        {help &&
                            <Button 
                                className="button help" style={{
                                    top: '100%',
                                    left: '0',
                                    maxWidth: '400px',
                                    width: 'max-content'
                                }}
                                onClick={() => {
                                    setHelp(false);
                                    localStorage.setItem('h-reproductor', 'true')
                                }}
                            >
                                <span>¡Aquí puedes volver!</span>
                                <Close />
                            </Button>
                        }
                    </div>
                </div>
                :
                <div className="video-settings">
                    <div className="background">
                        <LazyLoadImage 
                            src={episode.info.cover}
                        />
                    </div>
                    <div className="layout">
                        <div className="content">
                            <h4>Selecciona tu reproductor</h4>
                            <div className="buttons">
                                {episode.videos.map((data) => (
                                    <Button 
                                        className='button' key={data.type}
                                        onClick={() => openReproductorSelector(data.type, { premium: data.premium, others: data.others })}
                                    >
                                        {data.type === 'sub' && <ClosedCaption />}
                                        {data.type === 'dub' && <RecordVoiceOver />}
                                        <h5>{t(`categories.langs.${data.type}-long`)}</h5>
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
