import React, { useContext, useEffect, useRef, useState } from 'react'
import { Avatar, Button, ClickAwayListener, Grow, Popper, Tooltip, useMediaQuery } from '@mui/material';
import { ArrowDropDown, Menu, BookmarkBorder, Settings, Login, HowToVote, History, Logout, AutoAwesome, Cached, Close, AccountCircleOutlined, DarkMode, Language as LanguageIcon, ArrowBack, Search, Bookmark, Download } from '@mui/icons-material';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo';
import { useTranslation } from 'react-i18next';
import AoIconButton from '../buttons/IconButton';
import AoButton from '../buttons/Button';
import { AuthContext } from '../../context/Auth';
import { signOut } from 'firebase/auth';
import { auth } from '../../services/firebase/accounts';
import { AlertsContext } from '../../context/Alerts';
import PremiumLogo from '../../images/Premium';
import { ModalsContext } from '../../context/Modals';
import Language from '../../page/settings/language/Language';
import SearchIcon from '../../images/Search';
import SearchFilledIcon from '../../images/SearchFilled';
import animeGenres from '../../services/animeGenres';
import { t } from 'i18next';
import Logotipo from '../../images/Logotipo';
import { Modal, useModalManager } from '../modals';
import HeaderUserMenu from './header/HeaderUserMenu';

export default function Header({ change, refresh }) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [ updateAvailable, setUpdateAvailable ] = useState(false);
  const [ scrolled, setScrolled ] = useState(false);
  
  const match = useMediaQuery('(min-width: 30em)');

  const handleUpdate = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      });
    } else {
      window.location.reload();
    }
  };
  

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              setUpdateAvailable(true);
            }
          });
        });
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 16 * 2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.addEventListener('scroll', handleScroll);
    };
  }, [])

  if (location.pathname.split("/")[1] === "premium") {
    return (
      <header className={`header ${scrolled && 'visible'} ${location.pathname.split('/')[1]}`}>
        <div className="section">
          <div className="logo">
            <Link to="/" title={t('header.principal page')}>
              <Logo />
            </Link>
          </div>
        </div>
        <section className='section' style={{ gap: '.5rem'}}>
          {user ?
            <UserMenu refresh={refresh} />
          :
            <nav className="nav no-hide" style={{ padding: '.6rem 0'}}>
              <AoButton 
                variant={"outline uppercase"}
                action={() => {
                  navigate('/auth/login');
                  sessionStorage.setItem('back-path', location.pathname);
                }}
                title={t('header.user-menu.login.title')}
              />
            </nav>
          }
        </section>
      </header>
    )
  }
  
  return (
    <header className={`header ${scrolled && 'visible'} page-${location.pathname.split('/')[1]}`}>
      <div className='section'>
        <div>
          <AoIconButton 
            content={<Menu />}
            title={"Menu"}
            action={change}
          />
        </div>
        <div className="logo">
          <Link to="/" title={t('header.principal page')}>
            {match ? 
              <Logo />
              :
              <Logotipo />
            }
          </Link>
        </div>
        <HeaderNav />
      </div>
      <div className='section' style={{ gap: '.5rem'}}>
        <div className="options gap-5">
          {/* {!user?.badges.includes("premium") &&
            <AoButton 
              title={t('header.buy premium')}
              alt={t('header.buy premium')}
              icon={<PremiumLogo />}
              variant="premium"
              type={"link"}
              to={"/premium"}
            />
          } */}
          {updateAvailable &&
            <Tooltip title='¡Actualización disponible!'>
              <Button
                className='button color-green'
                onClick={handleUpdate}
              >
                <div className="button-icon">
                  <Download />
                </div>
              </Button>
            </Tooltip>
          }
          <Button
            className='button'
            component={NavLink}
            to="/search"
            alt={t('header.search')}
            title={t('header.search')}
          >
            <div className="button-icon">
              <SearchIcon className="outlined" />
              <SearchFilledIcon className="filled" />
            </div>
          </Button>
          {/* {match &&
            <Button
              className='button'
              component={NavLink}
              to="/lists"
              alt={t('header.lists')}
              title={t('header.lists')}
            >
              <div className="button-icon">
                <BookmarkBorder className="outlined" />
                <Bookmark className="filled" />
              </div>
            </Button>
          } */}
          <UserMenu refresh={refresh} />
        </div>
      </div>
    </header>
  )
}

const HeaderNav = () => {
  const anchorRef = useRef(null);
  const [ openGenres, setOpenGenres ] = useState(false);

  const handleCloseGenres = (event) => {
    if (event) {
      if(anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
    }
    setOpenGenres(false);
  }

  const handleToggle = () => {
    setOpenGenres((prevOpen) => !prevOpen);
  };

  return (
    <>
      <nav className="nav" >
        <button ref={anchorRef} onClick={handleToggle}>{t('header.genres')} <ArrowDropDown /></button>
      </nav>
      <Popper
        open={openGenres}
        anchorEl={anchorRef.current}
        placement='bottom-start'
        disablePortal
      >
        <div>
          <ClickAwayListener onClickAway={handleCloseGenres}>
            <div className="header-menu genres">
              <div className="header-menu_title">{t('header.genres')}</div>
              <div className="header-menu_body">
                {animeGenres.map((genre, i) => (
                  <AoButton 
                    key={i}
                    title={t(`categories.genres.${genre.id}`)}
                    icon={genre.icon}
                    type={"link"}
                    to={`/genres/${genre.id}`}
                    alt={t(`categories.genres.${genre.id}`)}
                    action={handleCloseGenres}
                  />
                ))}
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </Popper>
    </>
  )
}

function UserMenu() {
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);

  const { openModal, closeModal } = useModalManager();

  const [ open, setOpen ] = useState(false);

  const matched = useMediaQuery('(max-width: 799px)');
  
  const anchorRef = useRef(null);

  const handleOpenUserMenu = () => {
    if (matched) {
      openModal('user-menu', (
        <Modal
          close={() => closeModal('user-menu')}
          borderRadius={20}
          noPadding
        >
          <HeaderUserMenu />
        </Modal>
      ))
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  }

  const handleClose = () => {
    setOpen(false);
  }
  
  return(
    <>
      <Button 
        title={t('header.avatar')}
        onClick={handleOpenUserMenu}
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className='button icon'
        style={{ padding: '.25rem'}}
      >
        {profile ?
          <Avatar className='avatar image' alt={profile.displayName} src={profile.avatarURL} />
          :
          <Avatar className='avatar image'>
            <AccountCircleOutlined sx={{ width: 32, height: 32}} />
          </Avatar>
        }
      </Button>
      {!matched &&
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='bottom-end'
          disablePortal
        >
          <div>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <HeaderUserMenu close={handleClose} />
              </div>
            </ClickAwayListener>
          </div>
        </Popper>
      }
    </>
  )
}