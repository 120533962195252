import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/Auth'
import { LazyLoadImage } from '../../loading';
import AoButton from '../../buttons/Button';
import { AutoAwesome, DarkMode, Login, Settings, Language, HowToVote, Circle, Logout, Sync, SyncAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { BasicButton, ButtonGroup } from '../../buttons';
import { Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AccountSelector from '../../modals/dist/AccountSelector';
import { Modal, useModalManager } from '../../modals';
import ThemeSelector from '../../../page/settings/appearance/ThemeSelector';
import LanguageSelector from '../../../page/settings/language/Language';

export default function HeaderUserMenu({ close }) {
  const { t } = useTranslation();
  const { account, profile, signOut, handleSelectProfile } = useContext(AuthContext);

  const { openModal, closeModal } = useModalManager();

  const navigate = useNavigate();
  const location = useLocation();

  const openAccountSelector = () => {
    openModal('change-account', (
      <Modal
        title='Cambiar de cuenta'
        close={() => closeModal('change-account')}
      >
        <AccountSelector />
      </Modal>
    ))
    close();
  }

  const openThemeSelector = () => {
    close();
    openModal('theme-selector', (
      <Modal
        title='Cambiar tema'
        close={() => closeModal('theme-selector')}
      >
        <ThemeSelector zone />
      </Modal>
    ))
  }

  const openLanguageSelector = () => {
    close();
    openModal('language-selector', (
      <Modal
        title='Cambiar idioma'
        close={() => closeModal('language-selector')}
      >
        <LanguageSelector zone />
      </Modal>
    ))
  }

  const handleNavigate = (to) => {
    close();
    navigate(to);
  }

  const handleProfile = () => {
    close();
    handleSelectProfile();
  }

  const handleSignOut = () => {
    close();
    signOut();
  }

  const SpecialHeader = () => {
    if (account) {
      return (
        <>
          <Button onClick={handleProfile} className='user'>
            <div className="photo">
              <LazyLoadImage 
                src={profile?.avatarURL}
                width={55}
                height={55}
                isRadius
              />
            </div>
            <div className="data">
              <span className="name">{profile?.displayName}</span>
              <span className="username">{`@${profile?.userName}`}</span>
            </div>
            <div className='info'>
              <SyncAlt />
            </div>
            <span className='alt'>Cambiar perfil</span>
          </Button>
        </>
      )
    }

    return (
      <div className='section'>
        <div className='no-logged flex flow-column gap-5'>
          <BasicButton 
            onClick={() => handleNavigate(`/auth/register?redirect_uri=${encodeURIComponent(location.pathname)}`)}
            icon={<AutoAwesome />}
            title={t('header.user-menu.register.title')}
            description={t('header.user-menu.register.desc')}
          />
          <BasicButton 
            onClick={() => handleNavigate(`/auth/login?redirect_uri=${encodeURIComponent(location.pathname)}`)}
            icon={<Login />}
            title={t('header.user-menu.login.title')}
            description={t('header.user-menu.login.desc')}
          />
          {/* <AoButton 
            icon={<AutoAwesome />}
            action={() => handleNavigate(`/auth/register?redirect_uri=${encodeURIComponent(location.pathname)}`)}
            title={t('header.user-menu.register.title')}
            description={t('header.user-menu.register.desc')}
          /> */}
          {/* <AoButton 
            icon={<Login />}
            action={() => handleNavigate(`/auth/login?redirect_uri=${encodeURIComponent(location.pathname)}`)}
            title={t('header.user-menu.login.title')}
            description={t('header.user-menu.login.desc')}
          /> */}
        </div>
      </div>
    )
  }

  return (
    <div className='header-menu'>
      <SpecialHeader />
      <div className="section">
        <div className="nav">
          <Button onClick={() => handleNavigate('/settings')} className='button'>
            <Settings />
            <span>{t('settings.title')}</span>
          </Button>
          <Button onClick={() => handleNavigate('/settings/request')} className='button'>
            <HowToVote />
            <span>{t('settings.nav.request')}</span>
          </Button>
        </div>
      </div>
      {account &&
        <>
          <div className="section">
            <div className="nav">
              {/* <Button className='button' onClick={openAccountSelector}>
                <Sync />
                <span>{t('header.user-menu.change-account')}</span>
              </Button> */}
              <Button onClick={handleSignOut} className='button'>
                <Logout />
                <span>{t('header.user-menu.logout')}</span>
              </Button>
            </div>
          </div>
        </>
      }
      <div className="options">
        <ButtonGroup 
          buttons={[
            {
              icon: <DarkMode />,
              title: t('settings.nav.appearance'),
              onClick: openThemeSelector
            },
            {
              icon: <Language />,
              title: t('settings.nav.language'),
              onClick: openLanguageSelector
            }
          ]}
        />
      </div>
      <div className='footer-link'>
        <Link className='button link' to="/">Política de Privacidad</Link>
        <Circle style={{ fontSize: '.3rem'}} />
        <Link className='button link' to="/">Terminos de uso</Link>
      </div>
    </div>
  )
}
