import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/Auth'
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore, userFirestore } from '../../../services/firebase/firebase';
import CircularLoading from '../../loading/CircularLoading';
import { LazyLoadImage } from '../../loading';
import { Button } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import { selectProfile } from '../../../utils/firebase/profile';
import { useNavigate } from 'react-router-dom';

export async function getProfiles() {
    const getProfilesFirestore = async () => {
        const profilesRef = doc(firestore, `${auth.currentUser.uid}/profiles`);
        const profilesSnap = await getDoc(profilesRef);

        if (profilesSnap.exists()) {
            const data = {
                profiles: profilesSnap.data().list,
                timestamp: Date.now()
            }

            localStorage.setItem('profiles', JSON.stringify(data));
            return data.profiles;
        } else {
            return false;
            throw new Error({ message: 'Error fetching your profiles.'})
        }

    };

    const local = localStorage.getItem('profiles');

    if (local) {
        const data = JSON.parse(local);

        if (!data.timestamp) {
            return await getProfilesFirestore();
        } else {
            return data.profiles; 
        }
    } else {
        return await getProfilesFirestore();
    }
}

const ProfileManager = ({ 
    profileManager, 
    setProfileManager,
    profiles,
    handleProfile,
    setting,
}) => {
    const navigate = useNavigate();

    const handleCreateProfile = () => {
        navigate('/auth/profile-create')
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    zIndex: 3,
                    inset: 0,
                    background: 'rgba(0,0,0,.5)',
                    transition: '.25s ease all',
                    opacity: setting ? 1 : 0,
                    visibility: setting ? 'visible' : 'hidden'
                }}
            > 
                <CircularLoading determinate={!setting} />
            </div>
            <div className="flex flow-column padding-10" style={{ gap: '2rem', position: 'relative', width: '100%' }}> 
                <div>
                    <h2 style={{ fontSize: '1.75rem', textAlign: 'center'}} className='font-branch'>Edita tu perfil</h2>
                </div>
                <div className='flex justify-center' style={{ flexFlow: 'row wrap'}}>
                    {profiles.map((profile) => (
                        <Button 
                            className='button profile-selector' 
                            key={profile.pid}
                            onClick={() => handleProfile(profile, true)}
                        >
                            <div className='avatar'>
                                <LazyLoadImage
                                    width={100}
                                    height={100}
                                    src={profile.avatarURL}
                                    isRadius
                                />
                                <div className="badge">
                                    <Edit />
                                </div>
                            </div>
                            <div className='name'>
                                {profile.displayName}
                            </div>
                        </Button>
                    ))}
                    {profiles.length < 6 &&
                        <Button 
                            className='button profile-selector' 
                            onClick={handleCreateProfile}
                        >
                            <div className='avatar svg' style={{ width: 100, height: 100}}>
                                <Add />
                            </div>
                            <div className='name'>
                                Crear Perfil
                            </div>
                        </Button>
                    }
                    
                </div>
                <div className='flex justify-center'>
                    <Button
                        className='button outline rounded'
                        style={{ paddingInline: '1rem'}}
                        onClick={() => setProfileManager(false)}
                    >
                        Volver
                    </Button>
                </div>
            </div>
        </div>
    )
}

const ProfileW = () => {
    const navigate = useNavigate();
    const { signOut } = useContext(AuthContext);

    const handleCreateProfile = () => {
        navigate('/auth/profile-create')
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <div className="flex flow-column padding-10" style={{ gap: '2rem', position: 'relative', width: '100%', maxWidth: 500 }}> 
                <div style={{ textAlign: 'center'}} className='flex flow-column gap-5'>
                    <h2 style={{ fontSize: '1.75rem'}} className='font-branch'>¡Hola!</h2>
                    <span className='font-style normal'>¡Crea tu perfil para iniciar con tu aventura!</span>
                </div>
                <div className='flex justify-center' style={{ flexFlow: 'row wrap'}}>
                    
                    <Button 
                        className='button profile-selector' 
                        onClick={handleCreateProfile}
                    >
                        <div className='avatar svg' style={{ width: 100, height: 100}}>
                            <Add />
                        </div>
                        <div className='name'>
                            Crear Perfil
                        </div>
                    </Button>
                </div>
                <div className="divider with-o">
                    <span>o</span>
                </div>
                <div className='flex justify-center'>
                    <Button
                        onClick={() => {
                            signOut()
                            navigate('/auth/login')
                        }}
                        className='button outline rounded full-width'
                    >
                        Cerrar sesión
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default function ProfileSelector({ setProfileSelector, noModal }) {
    const navigate = useNavigate();
    const { account, profile, setProfile, setRefreshing } = useContext(AuthContext);

    const bgDefault = 'https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/background.jpg?alt=media&token=296fdaa0-b50d-4902-b256-f45e838957af';
    const [ background, setBackground ] = useState('https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/background.jpg?alt=media&token=296fdaa0-b50d-4902-b256-f45e838957af');

    const [ profiles, setProfiles ] = useState([]);
    const [ profileManager, setProfileManager ] = useState(false);
    const [ profileW, setProfileW ] = useState(false);

    const [ setting, setSetting] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const [ error, setError ] = useState(false);

    const handleProfile = async (obj, isEdit) => {
        setSetting(true);

        try {
            const result = await selectProfile({ profile: obj })
            setProfile(result.profile);
            setRefreshing(false);
            if (isEdit) {
                navigate('/settings/profile')
            }
        } catch (error) {
            console.error(error)
        }

        /* await setProfileData(obj)
        .then(() => {
            setProfileSelector(false);
            if (isEdit) {
                setProfileAction('edit');
            }
        })
        .catch((error) => {
            console.error(error)
        }) */
    };

    useEffect(() => {
        const asyncFn = async () => {
            await getProfiles(account)
            .then((response) => {
                if (!response) {
                    setProfileW(true);
                    setLoading(false);
                } else {
                    setProfiles(response);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error)
                setError(true);
                setLoading(false)
            })
        }

        asyncFn();

    }, [account, loading, setting, profile]);

    if (loading) return <CircularLoading />;

    if (error) return <div>Error loading profiles</div>;

    if (profileManager) return <ProfileManager setting={setting} profiles={profiles} handleProfile={handleProfile} profileManager={profileManager} setProfileManager={setProfileManager} />
    
    if (profileW) return <ProfileW />

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    zIndex: 3,
                    inset: 0,
                    background: 'rgba(0,0,0,.5)',
                    transition: '.25s ease all',
                    opacity: setting ? 1 : 0,
                    visibility: setting ? 'visible' : 'hidden'
                }}
            > 
                <CircularLoading determinate={!setting} />
            </div>
            <div style={{ position: 'absolute', inset: 0, zIndex: 0 }}>
                <div style={{ zIndex: 2, width: '100%', height: '100%', opacity: .8, backgroundColor: '#000', position: 'absolute'}}></div>
                <div style={{ zIndex: 1, width: '100%', height: '100%', position: 'absolute'}}>
                    {profiles.map((profile) => {
                        if (profile.bannerURL) {
                            return (
                                <img 
                                    key={`image-${profile.pid}`}
                                    src={profile.bannerURL}
                                    style={{ filter: 'blur(10px)', position: 'absolute', transition: '.5s ease all', width: '100%', height: '100%', objectFit: 'cover' }}
                                    className={`${profile.bannerURL === background ? 'show' : 'hide'}`}
                                />
                            )
                        }
                    } )}
                    <LazyLoadImage 
                        src={background}
                        width={'100%'}
                        style={{ position: 'absolute', transition: '.5s ease all', filter: 'blur(10px)'}}
                        height={'100%'}
                        className={`${bgDefault === background ? 'show' : 'hide'}`}
                    />
                </div>
            </div>
            <div className="flex flow-column padding-10" style={{ gap: '2rem', position: 'relative', width: '100%' }}> 
                {!noModal &&
                    <div>
                        <h2 style={{ fontSize: '1.75rem', textAlign: 'center'}} className='font-branch'>¿Quíen va a ser el protagonista?</h2>
                    </div>
                }
                <div className='flex justify-center' style={{ flexFlow: 'row wrap'}}>
                    {profiles.map((profile) => (
                        <Button 
                            className='button profile-selector' 
                            key={profile.pid}
                            onClick={() => handleProfile(profile)}
                            onMouseEnter={() => setBackground(profile.bannerURL)}
                        >
                            <div className='avatar'>
                                <LazyLoadImage
                                    width={100}
                                    height={100}
                                    src={profile.avatarURL}
                                    isRadius
                                />
                            </div>
                            <div className='name'>
                                {profile.displayName}
                            </div>
                        </Button>
                    ))}
                </div>
                <div className='flex justify-center'>
                    <Button
                        className='button outline rounded'
                        style={{ paddingInline: '1rem'}}
                        onClick={() => setProfileManager(true)}
                    >
                        <span className='title'>Gestionar perfiles</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}
