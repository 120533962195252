import React from 'react'

interface SwitchButtonProps {
  title: string;
  desc: string;
  variant: 'compact';
  value: boolean;
  onClick: VoidFunction;
  disabled: boolean;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ title, disabled, desc, variant, value, onClick }) => {
  
  const handleClick = () => {
    if (disabled) return null;

    onClick();
  }
  
  return (
    <div onClick={handleClick} className={`button switch ${disabled ? 'disabled' : ''} ${value ? 'active' : ''} ${variant ? variant : 'compact'}`}>
      <div role='button' className='switch-clicker'>
        <div className='title'>{title}</div>
        <div>
          <div className='switcher'>
            <div className="dot"></div>
          </div>
        </div>
      </div>
      <div className='desc'>
        <div>{desc}</div>
      </div>
    </div>
  )
}

export default SwitchButton;