import React from 'react'
import AnimeComments from './AnimeComments'

export default function AnimeOpinion({ anime }) {
  return (
    <div>
        <AnimeComments anime={anime} />
    </div>
  )
}
