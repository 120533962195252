import React, { useContext, useRef, useState } from 'react'
import { AvatarButton } from '../../../buttons';
import { Modal, ModalButtons, useModalManager } from '../..';
import { Delete, Edit, Image, Rotate90DegreesCcw, Rotate90DegreesCw } from '@mui/icons-material';
import { AuthContext } from '../../../../context/Auth';
import AvatarEditorLib from 'react-avatar-editor'
import { Button, ButtonGroup, Slider } from '@mui/material';
import Compressor from 'compressorjs';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { accountStorage, auth } from '../../../../services/firebase/firebase';
import { updateProfile } from 'firebase/auth';
import CircularLoading from '../../../loading/CircularLoading';

interface AvatarEditorProps {
    image: string | File;
    setImage?: Function;
    close?: VoidFunction;
    closeParent?: VoidFunction;
}

const AvatarEditor: React.FC<AvatarEditorProps> = ({
    close,
    image,
    setImage,
    closeParent
}) => {
    const cropRef = useRef(null);
    const { profile } = useContext(AuthContext);

    const [ scale, setScale ] = useState(10);
    const [ rotate, setRotate ] = useState(0);

    const [ loading, setLoading ] = useState(false);

    function closeModal() {
        if (close) {
            close();
        }

        if (closeParent) {
            closeParent();
        }
    }

    const handleSave = (image: Blob | ArrayBuffer) => {
        if (setImage) {
            setImage(image);
            closeModal();
            return;
        }

        const userRef = ref(accountStorage, `${profile.auid}/avatar.jpeg`);
        uploadBytes(userRef, image)
        .then((snapshot) => {
            getDownloadURL(snapshot.ref)
            .then((url) => {
                // @ts-ignore: Unreachable code error
                updateProfile(auth.currentUser, {
                    photoURL: url
                }).then(() => {
                    closeModal();
                })
            })
        })
    }

    const handleCompress = async () => {
        setLoading(true);
    
        if (cropRef.current) {
            // @ts-ignore: Unreachable code error
            const dataUrl = cropRef.current.getImageScaledToCanvas().toDataURL();
            const result = await fetch(dataUrl);
            const blob = await result.blob();
            new Compressor(blob, {
                quality: .8,
                width: 200,
                height: 200,
                resize: 'cover',
                convertTypes: 'image/png',
                convertSize: 50000,
                success: (compressedResult) => {
                    handleSave(compressedResult);
                }
            })
        }
      }

    return (
        <>
            {loading &&
                <CircularLoading type='fs' determinate={false} />
            }
            <div className="image-editor">
                {/* @ts-ignore */}
                <AvatarEditorLib 
                    ref={cropRef}
                    image={image}
                    style={{ width: "100%", height: "100%" }}
                    scale={scale / 10}
                    rotate={rotate}
                    width={600}
                    border={0}
                    color={[0,0,0, .6]}
                    height={600}
                    borderRadius={1000}
                    className="canvas"
                />
                <div className="cutter"></div>
            </div>
            <div className='avatar-controls'>
                <div className="slider">
                    <Image />
                    <Slider 
                        min={10}
                        max={50}
                        size='small'
                        defaultValue={scale}
                        // @ts-ignore: Unreachable code error
                        onChange={(e) => setScale(e.target.value)}
                        valueLabelDisplay='off'
                    />
                    <Image style={{ fontSize: '2rem'}} />
                </div>
                <ButtonGroup className='buttons-group'>
                    <Button onClick={() => setRotate(rotate + -90)}><Rotate90DegreesCcw /></Button>
                    <Button onClick={() => setRotate(rotate + 90)}><Rotate90DegreesCw /></Button>
                </ButtonGroup>
            </div>
            <ModalButtons 
                buttons={[
                    {
                        label: "Volver",
                        variant: "outlined",
                        onClick: close
                    },
                    {
                        label: "Subir",
                        variant: 'high',
                        onClick: handleCompress
                    }
                ]}
            />
        </>
    )
}

export default AvatarEditor;