import React, { useContext } from 'react'
import { AuthContext } from '../../../../context/Auth'
import i18n from '../../../../services/i18n';

export default function AccountInfo() {
    const { account } = useContext(AuthContext);

    console.log(account)

    return (
        <section className='settings-section'>
            <h3 className="font-branch">Información</h3>
            <div className="flex flow-column info-layout">
                <div className="info-body">
                    <h4>Nombre de la cuenta</h4>
                    <span>{account.displayName}</span>
                </div>
                <div className="info-body">
                    <h4>Correo electrónico</h4>
                    <span>{account.email}</span>
                </div>
                <div className="divider"></div>
                <div className="info-body">
                    <h4>Creación de la cuenta</h4>
                    <span>{new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full', timeStyle: 'long'}).format(account.metadata.createdAt)}</span>
                </div>
                <div className="info-body">
                    <h4>Último inicio de sesión</h4>
                    <span>{new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full', timeStyle: 'long'}).format(account.metadata.lastLoginAt)}</span>
                </div>
            </div>
        </section>
    )
}
