interface getAgeProps {
    timestamp: number;
}

interface getAgeResult {
    age: number;
}

function getAge({ timestamp }: getAgeProps): getAgeResult {
    if (timestamp) {
        const today = new Date().getTime();
        const birthDate = new Date(timestamp).getTime();
        const diff = today - birthDate;
        const ageNumber = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
        return { age: ageNumber};
    }
    return { age: 0 };
}

export default getAge;