import React, { useContext, useState } from 'react'
import i18n from '../../../services/i18n'
import AoButton from '../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';
import { AuthContext } from '../../../context/Auth';

export const langs = [
    {
        code: 'es',
        flag: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/langs%2Fes.webp?alt=media&token=f46b1ffa-63e7-4fa3-8ab8-5fe96b776e15",
        name: "Español"
    },
    {
        code: 'en',
        flag: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/langs%2Fen.webp?alt=media&token=611e31ad-76bb-4819-bac8-28d3c0430010",
        name: "English"
    },
]


/* {
    code: 'ja',
    flag: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/langs%2Fjp.webp?alt=media&token=69b2fe88-8ed7-4e43-bb1d-f8833e0421db",
    name: "日本語"
} */

export default function LanguageSelector({ zone, close }) {
    const { t } = useTranslation();
    const { fastRefresh } = useContext(AuthContext);
    const [ selectedLanguage, setSelectedLanguage ] = useState(i18n.language);

    const changeLanguage = (value) => {
        i18n.changeLanguage(value);
        setSelectedLanguage(value);
    }
  return (
    <>
    <div 
        className="settings-section"
        style={{
            margin: zone ? '0 -.5rem' : 0
        }}
    >
        {!zone && <h3 className="section-title margin-bottom-3">Idioma</h3>}
        
        <div className='language flex flow-column gap-2'>
            {!zone && <span className='font p-small margin-bottom-5'>{t('lang.select-lang')}</span>}
            {langs.map((lang, i) => (
                <AoButton 
                    key={i}
                    variant={"lang"}
                    type={"select"}
                    className={selectedLanguage === lang.code && 'active'}
                    action={() => {
                        changeLanguage(lang.code);
                        if (zone) {
                            fastRefresh();
                            close();
                        }
                    }}
                    title={lang.name}
                    langCode={lang.code}
                    flag={lang.flag}
                />
            ))}
        </div>
    </div>
    </>
  )
}
