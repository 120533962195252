import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/Auth';
import { LazyLoadImage } from '../../../components/loading';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Brush, Edit } from '@mui/icons-material';
import i18n from '../../../services/i18n';
import SwitchButton from '../../../components/buttons/SwitchButton.tsx';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import countries from '../../../services/countries/countries.jsx';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, userFirestore } from '../../../services/firebase/firebase.js';
import { SettingsContext } from '../../../context/Settings.jsx';
import { enqueueSnackbar } from 'notistack';
import { Modal, ModalButtons, useModalManager } from '../../../components/modals';
import AvatarPicker from '../../../components/modals/dist/user-image/AvatarPicker.tsx';
import NoUser from '../NoUser.jsx';
import CircularLoading from '../../../components/loading/CircularLoading.jsx';
import { ColorExtractor } from 'react-color-extractor';
import SEO from '../../../services/seo.jsx';

const ManageData = ({ 
    close, 
    modalData, 
    setModalData, 
    modalEData, 
    setModalEData,
    countryValue,
    setCountryValue
}) => {
    const { account, profile } = useContext(AuthContext);
    const { setSettingsLoading } = useContext(SettingsContext);
    
    const [ modalLoading, setModalLoading ] = useState(false);

    const handleSubmit = async () => {
        setModalLoading(true);

        const docRef = doc(firestore, `${account.uid}/profiles/${profile.puid}/info`);
        const docSnap = await getDoc(docRef);

        let newArr = docSnap.data().data[0];

        if (modalData.key === 'age') {
            newArr.age = {
                private: modalEData.private
            }
        }

        if (modalData.key === "birthdate") {
            newArr.birthdate = {
                private: modalEData.private,
                value: modalEData.value
            }
        }

        if (modalData.key === 'country') {
            newArr.country = {
                label: modalEData.label,
                private: modalEData.private,
                value: modalEData.value,
            }
        }

        
        updateDoc(docRef, {
            data: [newArr]
        })
        .then(() => {
            handleClose();
            setTimeout(() => {
                enqueueSnackbar('Los cambios se realizaron.', { variant: 'default' });
                setSettingsLoading(true);
            }, 300)
        })
    }

    const handlePrivate = () => {
        setModalEData({ ...modalEData, private: !modalEData.private });
    };
    

    const handleClose = () => {
        setTimeout(() => {
            setModalData({
                key: '',
                value: '',
                private: false
            });
            setModalEData({
                key: '',
                value: '',
                private: false
            });
        }, 200);
    };
    
    return (
        <>
            <div>
                {modalData.key === 'displayName' &&
                    <TextField 
                        fullWidth
                        className='input'
                        style={{ marginBottom: '1rem' }}
                        label={modalData.key}
                        focused
                        onChange={(e) => setModalEData({...modalEData, value: e.target.value})}
                        placeholder={modalData.value}
                    />
                }
                {modalData.key === 'birthdate' &&
                    <DatePicker 
                        value={dayjs(modalEData.value)} 
                        style={{ marginBottom: '1rem' }}
                        onChange={(newValue) => setModalEData({...modalEData, value: newValue.valueOf()})} 
                        className='input date-picker' 
                        timezone='UTC'
                        slotProps={{
                            textField: {
                                helperText: 'Si tu fecha de nacimiento es pública solo mostraremos el día y mes de nacimiento, el año esta excluido.'
                            }
                        }}
                    />
                }
                {modalData.key === 'country' &&
                    <Autocomplete
                        options={countries}
                        autoHighlight
                        value={countries.find(option => option.code === modalEData.value) || null}
                        inputValue={countryValue}
                        onChange={(event, newValue) => {
                            setModalEData({ ...modalEData, value: newValue ? newValue.code : '', label: newValue ? newValue.label : '' });
                            setCountryValue(newValue ? newValue.label : '');
                        }}
                        onInputChange={(event, newInputValue) => {
                        setCountryValue(newInputValue);
                        }}
                        getOptionLabel={(option) => option.label || ''}
                        renderOption={(props, option) => (
                        <Button {...props} className='button select-option' >
                            <LazyLoadImage
                            width={30}
                            height={21}
                            src={`https://flagcdn.com/w80/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w160/${option.code.toLowerCase()}.png 2x`}
                            />
                            {option.label}
                        </Button>
                        )}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            className='input date-picker'
                            label="Escoge un país"
                            inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            }}
                        />
                        )}
                    />
                }
                <div style={{ marginBottom: '2rem', marginInline: '.25rem'}}>
                    {modalData.key !== 'displayName' &&
                        <SwitchButton 
                            title="Hacer privado"
                            desc='Activa esta opción para cambiar la visibilidad de este dato de público a privado.'
                            value={modalEData.private}
                            onClick={handlePrivate}
                        />
                    }
                </div>
            </div>
            <ModalButtons 
                buttons={[
                    {
                        label: "Cancelar",
                        variant: "outlined",
                        onClick: close
                    },
                    {
                        label: "Guardar",
                        variant: "high",
                        onClick: handleSubmit,
                        loading: modalLoading,
                        disabled: (modalEData.value.toString().length > 1 && modalData.value !== modalEData.value) || (modalData.private !== modalEData.private) ? false : true
                    },
                ]}
            />
        </>
    )
}

const UserProfile = () => {
    const { account, profile } = useContext(AuthContext);
    const { openModal, closeModal } = useModalManager();

    const [ coverColor, setCoverColor] = useState();
    const [ selectedLanguage ] = useState(i18n.language);
    const [ userAvatar, setUserAvatar ] = useState('');

    const [ modalData, setModalData ] = useState({
        key: '',
        value: '',
        private: false 
    });

    const [ modalEData, setModalEData ] = useState({
        key: '',
        value: '',
        private: false 
    });

    console.log(profile)

    const [ countryValue, setCountryValue ] = useState('');

    
    const privateItems = Object.entries(profile.info)
        .filter(([key, value]) => value.private)
        .map(([key, value]) => ({ key, ...value }));

    const publicItems = Object.entries(profile.info)
        .filter(([key, value]) => !value.private)
        .map(([key, value]) => ({ key, ...value }));


    const handleOpenAvatar = () => {
        openModal('avatar-picker', (
            <Modal
                close={() => closeModal('avatar-picker')}
                variant='fh'
            >
                <AvatarPicker />
            </Modal>
        ))
    }

    

    const handleOpen = (obj) => {
        setModalData(obj);
        setModalEData({
            key: obj.key,
            value: obj.key === 'birthdate' || obj.key === 'country' ? obj.value : '',
            private: obj.private,
            label: obj.key === 'country' ? obj.label : ''
        });

        if (obj.key === 'country') {
            setCountryValue(obj.label);
        }
    };


    const ProfileSettingButton = ({ item }) => {
        return (
            <section className='flex justify-between'>
                <div>
                    <h5>{item.key}</h5>
                    <span className='data'>
                        {item.key === "birthdate" && new Intl.DateTimeFormat(selectedLanguage, { dateStyle: 'long', timeZone: 'GMT'}).format(item.value)}
                        {item.key === 'age' && item.value}
                        {item.key === 'country' && item.label}
                        {item.key === 'displayName' && item.value}
                    </span>
                </div>
                {!item.noEdit &&
                    <Button onClick={() => handleOpen(item)} className='button highligh'>
                        <span>Editar</span>
                    </Button>
                }
            </section>
        );
    };

    useEffect(() => {
        if (modalData.key) {
            openModal('profile-settings', (
                <Modal
                    title={`Editar ${modalData.key}`}
                    close={() => closeModal('profile-settings')}
                >
                    <ManageData 
                        modalData={modalData}
                        setModalData={setModalData}
                        modalEData={modalEData}
                        setModalEData={setModalEData}
                        countryValue={countryValue}
                        setCountryValue={setCountryValue}
                    />
                </Modal>
            ))
        } else {
            closeModal('profile-settings');
        }
    }, [modalData, modalEData]);

    if (!account) {
        return (
            <div>hola</div>
        )
    }

    return (
        <div className='profile-layout'>
            <SEO 
                title={"Perfil"}
                description={"Personaliza tu perfil."}
            />  
            <div className='profile-header'>
                <Button className='button profile-banner' style={{ background: coverColor && coverColor[0]}}>
                    <LazyLoadImage 
                        src={profile.coverURL}
                        style={{ background: coverColor && coverColor[0]}}
                    />
                    <div className="hover">
                        <Edit />
                    </div>
                </Button>
                <div className='flex gap-big' style={{ marginTop: '-3.25rem', marginLeft: '1rem'}}>
                    <Button onClick={handleOpenAvatar} className='button profile-avatar'>
                        <ColorExtractor 
                            src={`${profile.avatarURL}`}
                            getColors={colors => setCoverColor(colors)}
                        />
                        <LazyLoadImage 
                            src={`${profile.avatarURL}`}
                            isRadius
                        />
                        <div className="hover"><Edit /></div>
                        <div className="badge"><Brush /></div>
                    </Button>
                    <div className='flex align-center' style={{ paddingTop: '3.25rem'}}>
                        <span className='font-branch text' style={{ fontSize: '1.5rem'}}>{profile.displayName}</span>
                    </div>
                </div>
            </div>
            {profile.info.error ? 
            <div style={{ margin: '2rem 0'}}>
                <h3 className='font-branch text font-style color-negative' style={{ marginBottom: '1rem'}}>Ha ocurrido un error al cargar tu información.</h3>
                <span className='font-style medium color-subdued'>Si el problema persiste, te recomendamos rellenar tu información desde cero.</span>
            </div>
            :
            <>
                <div className='profile-section'>
                    <h4 className='font-branch' style={{ padding: '.5rem'}}>Información Pública</h4>
                    <ProfileSettingButton item={{ key: 'displayName', value: profile.displayName, private: false, noEdit: true }} />
                    {publicItems.length > 0 &&
                        <div style={{ margin: '.5rem 0'}} className="divider"></div>
                    }
                    {publicItems.map((item) => (
                        <ProfileSettingButton key={item.key} item={item} />
                    ))}
                </div>
                <div className='profile-section'>
                    <h4 className='font-branch' style={{ padding: '.5rem'}}>Información Privada</h4>
                    {privateItems.length < 1 &&
                        <div style={{ padding: '.5rem'}} className='font-style color-negative'>No hay información aquí</div>
                    }
                    {privateItems.map((item) => (
                        <ProfileSettingButton key={item.key} item={item} />
                    ))}
                </div>
            </>
            }
        </div>
    );
}


export default function Profile() {
    const { account, profile} = useContext(AuthContext);

    if (!account) return <NoUser text={"Necesitas una cuenta para personalizar tu perfil."} />

    if (!profile) return <CircularLoading />

    return <UserProfile />;
}
