import React from 'react'
import ThemeSelector from './ThemeSelector'
import SEO from '../../../services/seo'

export default function Appearance() {
    return (
        <div>
            <SEO 
                title={"Apariencia"}
                description={"Personaliza tu perfil."}
            /> 
            <ThemeSelector />
        </div>
    )
}
