import React, { useContext, useEffect } from 'react'
import { SettingsContext } from '../../../context/Settings'
import { Button } from '@mui/material';
import AoButton from '../../../components/buttons/Button';
import { AutoMode, DarkMode, LightMode } from '@mui/icons-material';
import SEO from '../../../services/seo';
import { useTranslation } from 'react-i18next';
import { Modal, useModalManager } from '../../../components/modals';

const ThemeAnimation = ({ close }) => {
  
  useEffect(() => {
    setTimeout(() => {
      close();
    }, 1000)
  }, [])
  
  return (
    <></>
  )
}

export default function ThemeSelector({ zone }) {
  const { t } = useTranslation();
  const { theme, changeTheme } = useContext(SettingsContext);
  const { openModal, closeModal } = useModalManager();

  const changeThemeFn = (v) => {
    openModal('theme-transition', (
      <Modal
        variant='fs'
        severity='important'
        close={() => closeModal('theme-transition')}
      >
        <ThemeAnimation />
      </Modal>
    ))

    setTimeout(() => {
      changeTheme(v);
    }, 500)
  }

  if (zone) {
    return (
      <div className='flex flow-column w100'>
        <AoButton 
          variant={"theme"}
          type={"select"}
          className={`${theme === "sp" && 'active'}`}
          action={() => changeThemeFn('sp')}
          title="Preferencia del sistema"
          icon={<AutoMode />}
        />
        <AoButton 
          variant={"theme"}
          type={"select"}
          className={`${theme === "light" && 'active'}`}
          action={() => changeThemeFn('light')}
          title="Claro"
          icon={<LightMode />}
        />
        <AoButton 
          variant={"theme"}
          type={"select"}
          className={`${theme === "dark" && 'active'}`}
          action={() => changeThemeFn('dark')}
          title="Oscuro"
          icon={<DarkMode />}
        />
      </div>
    )
  }

  return (
    <>
      <div className="settings-section">
        <h3 className='section-title'>Tema</h3>
        <div className="flex flow-row gap-bigger">
          <Button
            className={`button theme-selector ${theme === "sp" && 'active'}`}
            onClick={() => changeThemeFn('sp')}
          >
            <div className="preview sp">
              <div></div>
              <div></div>
            </div>
            <span className='theme-name'>Automático</span>
          </Button>
          <Button
            className={`button theme-selector ${theme === "light" && 'active'}`}
            onClick={() => changeThemeFn('light')}
          >
            <div className="preview light"></div>
            <span className='theme-name'>Claro</span>
          </Button>
          <Button
            className={`button theme-selector ${theme === "dark" && 'active'}`}
            onClick={() => changeThemeFn('dark')}
          >
            <div className="preview dark"></div>
            <span className='theme-name'>Oscuro</span>
          </Button>
        </div>
      </div>
    </>
  )
}
