import React, { useContext, useEffect, useState } from 'react'
import ProfileSelector, { getProfiles } from '../../../../components/modals/dist/ProfileSelector'
import { Button } from '@mui/material';
import { LazyLoadImage } from '../../../../components/loading';
import { AuthContext } from '../../../../context/Auth';
import { useNavigate } from 'react-router-dom';
import { SettingsContext } from '../../../../context/Settings';
import { Edit } from '@mui/icons-material';
import { selectProfile } from '../../../../utils/firebase/profile';

export default function Profiles() {
  const navigate = useNavigate();
  const { setSubmitting } = useContext(SettingsContext);
  const { setProfile, setProfileData, setRefreshing } = useContext(AuthContext);
  const [ profiles, setProfiles ] = useState([]);

  const handleProfile = async (obj) => {
    setSubmitting(true);

    try {
      const result = await selectProfile({ profile: obj, setRefreshing: setRefreshing })
      setProfile(result.profile);
      navigate('/settings/profile');
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.error(error)
    }
  }

  useEffect(() => {
    const asyncFn = async () => {
      await getProfiles()
      .then((response) => {
        setProfiles(response)
      })
      .catch((error) => {
        
        console.error(error.code)
      })
    }
    asyncFn();
  }, [])


  return (
    <section className='settings-section'>
      <h3 className='font-branch'>Gestionar perfiles</h3>
      <div className='flex' style={{ marginTop: '1rem', overflowX: 'scroll', paddingBottom: '1rem'}}>
        {profiles ?
          <>
            {profiles.map((profile) => (
              <Button 
                className='button profile-selector' 
                key={profile.pid}
                style={{
                  minWidth: '7rem'
                }}
                onClick={() => handleProfile(profile)}
              >
                <div className='avatar'>
                  <LazyLoadImage
                    width={75}
                    height={75}
                    src={profile.avatarURL}
                    isRadius
                  />
                  <div className='badge'><Edit /></div>
                </div>
                <div className='name' style={{ fontSize: '1rem'}}>
                    {profile.displayName}
                </div>
              </Button>
            ))}
          </>
          :
          <>
          </>
        }
      </div>
    </section>
  )
}
