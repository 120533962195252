import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/navigation/Header'
import Tabs from '../components/navigation/Tabs'
import Sidebar from '../components/navigation/Sidebar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './Navigation.scss';
import HeaderAlert from '../components/alerts/HeaderAlert'
import { AlertsContext } from '../context/Alerts'
import { AuthContext } from '../context/Auth'
import { useTranslation } from 'react-i18next'
import AuthHeader from '../components/navigation/AuthHeader'
import { ModalsContext } from '../context/Modals'
import Modal from '../components/modals/Modal.tsx'
import CustomizeSelector, { CustomizeAvatar, CustomizeBanner } from '../components/modals/CustomizeSelector'
import Share from '../components/modals/dist/Share.jsx'
import Embed from '../components/modals/Embed'
import ChangeAccount from '../components/modals/ChangeAccount'
import { CircularProgress, useMediaQuery } from '@mui/material'
import OnlyPremium from '../components/modals/OnlyPremium'
import Footer from '../components/navigation/Footer'
import { BuyPremiumContext } from '../context/BuyPremium';
import { BuyPremium } from '../components/modals/BuyPremium';
import AdsModal from '../components/modals/AdsModal';
import { Close } from '@mui/icons-material'
import AoIconButton from '../components/buttons/IconButton'
import ProfileSelector from '../components/modals/dist/ProfileSelector.jsx'

export default function Layout() {
  const location = useLocation();
  const { alerts, addAlert } = useContext(AlertsContext);
  const { user, refreshing, setRefreshing } = useContext(AuthContext);
  const { modal } = useContext(BuyPremiumContext);
  const { adModal, adModalData, adModalState, closeAdModal, onlyPremium, onlyPremiumState, closeOnlyPremium, changeAccount, changeAccountState, closeChangeAccount, embed, embedState, closeEmbed, customizeSelectorType, share, shareState, shareLink, closeShare, customizeSelector, customizeSelectorState, closeCustomizeSelector } = useContext(ModalsContext);
  const { t } = useTranslation();
  const [ sidebar, setSidebar ] = useState(false);
  const mobile = useMediaQuery('(min-width: 50em)');
  const animeLandingMatch = useMediaQuery('(min-width: 1630px)');
  
  const handleSidebar = () => {
      setSidebar(!sidebar);
  }

  const handleRefresh = () => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 500)
  }

  useEffect(() => {
    if (user) {
      if (!user?.emailVerified) {
        addAlert(t('header.noVerifiedEmail'), "pending", "filled", false, "/auth/verify-email", t('header.verifyEmail'), "verify-email");
      }
    }
  }, [user]);

  useEffect(() => {
    if (!refreshing) {
      const root = document.querySelector('html');
      
      root.scrollTop = 0;
    }
  }, [location, refreshing]);

  return (
      <>
        <Header change={handleSidebar} refresh={handleRefresh} />
          <div className="alerts">
            {alerts.map((alert) => (
              <HeaderAlert key={alert.id}
                title={alert.title}
                id={alert.id}
                type={alert.type}
                variant={alert.variant}
                close={alert.close}
                link={alert.link}
                linkText={alert.linkText}
              />
            ))}
          </div>
          <Sidebar state={sidebar} change={handleSidebar} />
          <Outlet /> 
          <Footer />
          {!mobile && <Tabs />}
          {/* {adModal &&
            <Modal
              title={adModalData.title}
              close={closeAdModal}
              visible={adModalState}
            >
              <AdsModal />
            </Modal>
          }
          {customizeSelector && 
            <Modal 
              title={customizeSelectorType === "avatar" ? "Selección de avatar" : 'Selección de imagen de cabecera'} 
              close={closeCustomizeSelector} 
              visible={customizeSelectorState}
              modals={customizeSelectorType === "avatar" ? 
              <CustomizeAvatar title={"Selección de avatar"} /> 
              : 
              <CustomizeBanner title={"Selección de imagen de cabecera"} />}
            >
              <CustomizeSelector type={customizeSelectorType} />
            </Modal>
          }
          {embed &&
            <Modal 
              title={t('words.video')}
              close={closeEmbed}
              variant={"embed"}
              visible={embedState}
            >
              <Embed />
            </Modal>
          }
          {changeAccount &&
            <Modal 
              title={t('words.change account')}
              close={closeChangeAccount}
              variant={"change-account"}
              visible={changeAccountState}
            >
              <ChangeAccount />
            </Modal>
          }
          {onlyPremium &&
            <Modal
              title={t('modal.need-premium.title')}
              close={closeOnlyPremium}
              visible={onlyPremiumState}
            >
              <OnlyPremium />
            </Modal>
          }
          {modal &&
            <BuyPremium />
          } */}
      </>
  )
}
