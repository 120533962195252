import React from 'react'
import { hasExpired } from '../time'

export default function verifyCacheResponse(response, headerField) {
    if (response && response.headers.get(headerField ? headerField : 'expire') && !hasExpired(response.headers.get(headerField ? headerField : 'expire')).expired) {
        return true;
    } else {
        return false;
    }
}
