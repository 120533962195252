import { Circle, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, useModalManager } from '../../../components/modals';

const MoreInfo = ({ anime, season, close }) => {
    const { t } = useTranslation();
    return (
        <div className='info'>
            <div className="more-info active flex flow-column gap-10">
                <div className="tags tags-and-rating">
                    {season.rating !== null &&
                        <>
                            <span className={`rating ${season.rating}`}>{t(`words.ratings.${season.rating}`)}</span>
                            <Circle sx={{fontSize: '.3rem'}} />
                        </>
                    }
                    {anime.tags.map((tag, i) => (
                        <span key={i}>{t(`categories.langs.${tag}-long`)}</span>
                    ))}
                </div>
                <p className='font-style color-subdued'>{anime.synopsis}</p>
                <div className="divider"></div>
                <Button style={{ borderRadius: 8, marginBottom: '.5rem'}} onClick={close} className="show-less button highlight"><ExpandLess /></Button>
            </div>
        </div>
    )
}

export default function AnimeMoreInfo({ anime, season }) {
    const { t } = useTranslation();
    const { openModal, closeModal } = useModalManager();
    const [ isOpen, setIsOpen ] = useState(false);
    const matched = useMediaQuery('(max-width: 50em)');    

    const handleOpen = () => {
        if (matched) {
            openModal('anime-more-info', (
                <Modal
                    title={anime.name}
                    close={() => closeModal('anime-more-info')}
                >
                    <MoreInfo anime={anime} season={season} />
                </Modal>
            ))
        } else {
            setIsOpen(true);
        }
    }
    
    const handleClose = () => {
        if (matched) {
            closeModal('anime-more-info')
        }
        setIsOpen(false);
    }

    useEffect(() => {
        handleClose();
    }, [season])

    if (isOpen) {
        return (
            <div className='info'>
                <div className="button highlight more-info active">
                    <div className="tags tags-and-rating">
                        {season.rating !== null &&
                            <>
                                <span className={`rating ${season.rating}`}>{t(`words.ratings.${season.rating}`)}</span>
                                <Circle sx={{fontSize: '.3rem'}} />
                            </>
                        }
                        {anime.tags.map((tag, i) => (
                            <span key={i}>{t(`categories.langs.${tag}-long`)}</span>
                        ))}
                    </div>
                    <p>{anime.synopsis}</p>
                    <div className="divider"></div>
                    <Button onClick={handleClose} className="show-less button"><ExpandLess /></Button>
                </div>
            </div>
        )
    }

    // console.log(season)

    return (
        <div className='info'>
            <Button onClick={handleOpen} className="button highlight more-info">
                <div className="tags tags-and-rating">
                    {season.rating !== null &&
                        <>
                            <span className={`rating ${season.rating}`}>{season.rating}</span>
                            <Circle sx={{fontSize: '.3625rem'}} />
                        </>
                    }
                    {anime.tags.map((tag, i) => (
                        <span key={i}>{t(`categories.langs.${tag}`)}</span>
                    ))}
                </div>
                <p>{anime.synopsis}</p>
                <div className="divider"></div>
                <div className="show-more"><ExpandMore /></div>
            </Button>
        </div>
    )
}
