import React, { useContext } from 'react'
import { AccountInfo, AccountManager, Profiles } from './sections'
import { AuthContext } from '../../../context/Auth'
import NoUser from '../NoUser';
import SEO from '../../../services/seo';

export default function Account() {
  
  const { account } = useContext(AuthContext);

  if (!account) return <NoUser text="¡Necesitas una cuenta!" />

  return (
    <div className='profile-layout'>
      <SEO 
        title={"Cuenta"}
        description={"Personaliza tu perfil."}
      /> 
      <AccountManager />
      <AccountInfo />
      <Profiles />
    </div>
  )
}
