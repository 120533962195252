import React, { useContext, useEffect, useRef, useState } from 'react'
import AoAvatarEditor from '../../components/form/AvatarEditor'
import Form from '../../components/form/Form';
import AoIconButton from '../../components/buttons/IconButton';
import { Modal, useModalManager } from '../../components/modals';
import {Input} from '../../components/form';
import { useTranslation } from 'react-i18next';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { auth, firestore, storage } from '../../services/firebase/accounts';
import AoButton from '../../components/buttons/Button';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';
import { LazyLoadImage } from '../../components/loading';
import { Button } from '@mui/material';
import { AddAPhoto, Edit, Person } from '@mui/icons-material';
import { AvatarEditor, AvatarUpload } from '../../components/modals/dist/user-image';
import { accountStorage, userFirestore } from '../../services/firebase/firebase';
import { saveAccountData } from '../../services/firebase/auth';

export default function CompleteAuth() {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { account, getUserData, setExistsAccount } = useContext(AuthContext);
    const { openModal, closeModal } = useModalManager();

    const [ selectedPhoto, setSelectedPhoto ] = useState(null);
    
    const [ username, setUsername ] = useState('');
    const [ canUse, setCanUse ] = useState(false);

    const [ delayHandler, setDelayHandler ] = useState(null);
    const [ searchAlert, setSearchAlert ] = useState('');
    const [ disabled, setDisabled ] = useState(true);

    const [ uploading, setUploading ] = useState(false);

    const handleInputClick = (e) => {
        e.preventDefault();
        if (inputRef.current){
            inputRef.current.click();
        }
    }

    const handleImgChange = (e) => {
        const files = e.target.files;

        function resetInput() {
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }

        if (files.length > 0) {
            const file = URL.createObjectURL(e.target.files[0]);
            openModal('avatar-picker', (
                <Modal
                    title='Escoger avatar'
                    maxWidth={400}
                    close={() => closeModal('avatar-picker')}
                >
                    <AvatarEditor 
                        image={file}
                        closeParent={() => closeModal('avatar-picker')}
                        setImage={setSelectedPhoto}
                    />
                </Modal>
            ))

            setTimeout(() => {
                resetInput();
            }, 50);
        } else {
            resetInput();
        }
    }

    const handleUsername = (value) => {
        const pattern = new RegExp(/^[0-9a-zA-Z.\sáéíñóúüÁÉÍÑÓÚÜ_-]+$/g);

        setUsername(value);
        clearTimeout(delayHandler);
        setCanUse(false);

        if (value.length < 1) {
            setSearchAlert("");
            return;
        }

        if (!pattern.test(value)) {
            setSearchAlert("no-specials");
            return;
        }

        if (value.length < 4) {
            setSearchAlert("too-less");
            return;
        }

        if (value.length > 15) {
            setSearchAlert("too-much");
            return;
        }

        if (value.length > 3 && value.length < 16) {
            setSearchAlert("success");
            setCanUse(true);
        }
    }

    const handleUpload = async () => {
        setUploading(true);

        try {
            const accountRef = doc(firestore, `${account.uid}/advanced`);
            const accountSnap = await getDoc(accountRef);

            if (accountSnap.exists()) {
                const data = accountSnap.data();

                const userPhoto = ref(accountStorage, `${data.auid}/avatar.jpeg`);
                const photoResponse = await uploadBytes(userPhoto, selectedPhoto)

                const photoURL = await getDownloadURL(photoResponse.ref);

                await updateProfile(account, {
                    displayName: username,
                    photoURL: photoURL
                })
                
                setExistsAccount(true);

                navigate('/');
            } else {
                await saveAccountData(auth.currentUser);

                await handleUpload();
            }
        } catch (error) {
            console.error('Error', error)
        }
    }

    useEffect(() => {
        if (canUse && selectedPhoto) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [username, canUse, selectedPhoto]);

    return (
        <>
            <div className={`complete-auth auth-with-background ${uploading ? 'loading' : ''}`}>
                <div className="background">
                    <LazyLoadImage 
                        src='https://images6.alphacoders.com/134/thumb-1920-1346619.jpeg'
                    />
                </div>
                <div className="content">
                    <form 
                        className="content-body"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleUpload();
                        }}    
                    >
                        <h1>Cuenta </h1>
                        <span></span>
                        <div className='avatar'>
                            <Button 
                                className='button profile-avatar'
                                onClick={handleInputClick}
                            >
                                {selectedPhoto ?
                                    <LazyLoadImage 
                                        src={URL.createObjectURL(selectedPhoto)}
                                        isRadius
                                        cached
                                    />
                                    :
                                    <Person />
                                }
                                <div className="hover">
                                    <Edit />
                                </div>
                                <div className="badge">
                                    <AddAPhoto />
                                </div>
                            </Button>
                            <input 
                                type='file'
                                className='hide'
                                accept="image/*"
                                id="input-avatar"
                                ref={inputRef}
                                onChange={handleImgChange}
                            />
                        </div>
                        <div className="flex flow-column gap-big">
                            <Input 
                                type="text"
                                variant={1}
                                name="accountname"
                                value={username}
                                onChange={(e) => handleUsername(e.target.value)}
                                label={`${t('words.accountname')}`}
                                helperText={searchAlert ? t(`alerts.accountname.auth/${searchAlert}`) : undefined}
                                required={true}
                            />
                        </div>
                        <div className="buttons">
                            <AoButton 
                                title={t('words.save')}
                                variant={"special-2 size-big"}
                                style={{width: '100%'}}
                                disabled={disabled}
                                submit={true}
                                loading={uploading}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
