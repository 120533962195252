interface ExpirationProps {
    value: number;
    unit: 'days' | 'months' | 'years';
}

interface ExpirationResult {
    expire: number;
}

function createExpiration({ value, unit }: ExpirationProps): ExpirationResult {
    const currentTime = Date.now();

    let expirationTime: number;

    switch (unit) {
        case 'days':
            expirationTime = currentTime + value * 24 * 60 * 60 * 1000; // Días a milisegundos
            break;
        case 'months':
            expirationTime = currentTime + value * 30 * 24 * 60 * 60 * 1000; // Aproximación: 30 días por mes
            break;
        case 'years':
            expirationTime = currentTime + value * 365 * 24 * 60 * 60 * 1000; // Aproximación: 365 días por año
            break;
        default:
            throw new Error('Unidad de tiempo no válida');
    }

    return { expire: expirationTime };
}

export default createExpiration;