import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from '../../components/loading';
import { Button } from '@mui/material';

export default function NoUser({ text }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [ image, setImage ] = useState('https://i.pinimg.com/originals/14/76/85/1476858b37e0e0846f2548c99e8043fd.jpg');

    const handleNavigate = () => {
        navigate(`/auth/login?redirect_uri=${encodeURIComponent(location.pathname)}`);
    }

    useEffect(() => {
        fetch('https://api.waifu.pics/sfw/bonk') // Removed mode: 'no-cors'
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json(); // Return the parsed JSON
            })
            .then((data) => {
                console.log(data); // Log the data to inspect its structure
                setImage(data.url); // Adjust based on actual response structure
            })
            .catch((err) => {
                console.error('Fetch error:', err);
            });
    }, []);

    return (
        <div className='flex justify-center flow-column align-center gap-10'>
            <span className='font-branch title font-style big'>{text}</span>
            <div className='flex flow-column gap-20' style={{ maxWidth: 400, width: '100%'}}>
                <LazyLoadImage 
                    src={image}
                    width={'100%'}
                    height={250}
                    borderRadius={10}
                />
                <Button onClick={handleNavigate} className='button high full-width'>
                    <span>Iniciar sesión</span>
                </Button>
            </div>
        </div>
    )
}
