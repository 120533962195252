import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import i18n from '../../services/i18n';
import { doc, getDoc } from 'firebase/firestore';
import SwiperHomeSections from '../swiper/SwiperHomeSections';
import { useTranslation } from 'react-i18next';
import BrowseCard from './BrowseCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getDataAndCache } from '../../utils/firebase';

const ads = [
    {
        type: "premium", 
        cover: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/ads%2Fad-0.webp?alt=media&token=a56dbf8a-cb73-4aa9-8984-0ec22457cfa4",
        to: ""
    },
    {
        type: "patreon", 
        cover: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/ads%2Fad-1.webp?alt=media&token=5d643564-8895-4f29-b815-831d89d008c2",
        to: "https://www.patreon.com/aodesu"
    },
    {
        type: "paypal", 
        cover: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/ads%2Fad-2.webp?alt=media&token=7b7178ad-c4c2-4742-82ed-cdfcee691f9c",
        to: ""
    },
]

const HomeSection = ({ data }) => {
    const [ loading, setLoading ] = useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false)
      }, 500) 
    })

    if (!data) return;
  
    if (loading) {
      return (
        <div className={`section-layout lazy ${data.episodes && 'wide'}`}>
            <div className="section-title">
                <div className="title"></div>
            </div>
            <div className="section-content">
                <div className="swiper">
                    <div className="swiper-wrapper">
                        {Array.from({ length: 6 }).map((i, index) => (
                            <div className="swiper-slide" key={index}>
                                <BrowseCard type={"lazy"} episodes={data.episodes} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
      )
    }
    return (
        <div className={`section-layout ${data.episodes && 'wide'}`}>
            <div className="section-title">
                <div className="title">
                    <h2>{data.name}</h2>
                </div>
            </div>
            <SwiperHomeSections data={data.episodes ? data.content : data.animes} episodes={data.episodes} ads={ads} id={data.id} />
        </div>
    )
}

const NoMore = () => {
    const { t } = useTranslation();
    return (
        <div className='section-layout'>
            <div className="section-content">
                <div className="section-wrapper">
                    <div className="section-wrapper__body">
                        <LazyLoadImage 
                            src='https://i.ibb.co/fVRLKj9/clean.webp'
                        />
                        <h2>{t('error.home/no-more')}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

class HomeInfiniteScroll extends React.Component {
    state = {
        items: [],
        showinItems: [],
        loading: true,
        hasMore: true
    };

    fetchMoreData = () => {
        if (this.state.showinItems.length >= this.state.items.length) {
            this.setState({ hasMore: false });
            return;
        }

        setTimeout(() => {
            this.setState({
                showinItems: this.state.showinItems.concat(this.state.items[this.state.showinItems.length], this.state.items[this.state.showinItems.length + 1])
            });
        }, 1000)
    };

    componentDidMount(){
        const self = this;
        async function getSwiper() {
            try {
                const { res } = await getDataAndCache({
                    cacheLocation: 'content',
                    cacheID: 'sections',
                    fetchLocation: 'home/swiper_sections',
                    expirationDate: {
                        value: 1,
                        unit: 'days'
                    }
                })
                const data = res.data.sort(function(a, b) {
                    return a.id - b.id
                })

                self.setState({ items: data, showinItems: [data[0]], loading: false });
                console.log(res)
            } catch (error) {
                console.error('Error al obtener contenido:', error)
            }
        }

        getSwiper();
    }

    render() {
        const Loading = () => {
            return (
                <div className="section-layout">
                    <div className="section-content">
                        <div className="section-wrapper">
                            <div className="loading-section">
                                <CircularProgress className='circular-progress' />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
          <div className='home-content'>
            {this.state.loading === false ?
                <InfiniteScroll
                    dataLength={this.state.showinItems.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<Loading />}
                    endMessage={<NoMore />}
                >
                    {this.state.showinItems.map((i, index) => (
                        <HomeSection key={index} data={i} />
                    ))}
                </InfiniteScroll>
                :
                <>
                    <div className="section-layout lazy">
                        <div className="section-title">
                            <div className="title"></div>
                        </div>
                        <div className="section-content">
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    {Array.from({ length: 6 }).map((i, index) => (
                                        <div className="swiper-slide" key={index}>
                                                <BrowseCard type={"lazy"} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
          </div>
        )
    }
}

export default HomeInfiniteScroll;