import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from '../../../../components/loading';

export default function RequestIndex() {
    const [ image, setImage ] = useState('https://i.pinimg.com/originals/14/76/85/1476858b37e0e0846f2548c99e8043fd.jpg');

    useEffect(() => {
        fetch('https://api.waifu.pics/sfw/pat') // Removed mode: 'no-cors'
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json(); // Return the parsed JSON
            })
            .then((data) => {
                console.log(data); // Log the data to inspect its structure
                setImage(data.url); // Adjust based on actual response structure
            })
            .catch((err) => {
                console.error('Fetch error:', err);
            });
    }, []);

    return (
        <div className='flex flow-column gap-10'>
            <div className='font title-normal align-center'>
                ¿Quieres ver algo pero no lo encuentras?
            </div>
            <LazyLoadImage 
                src={image}
                width={'100%'}
                height={200}
                borderRadius={10}
            />
        </div>
    )
}
