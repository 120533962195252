import React, { useEffect, useState } from 'react'
import { SeasonsLazy } from './lazyComponent'
import getDBLang from '../../../components/hooks/getDBLang';
import verifyCacheResponse from '../../../utils/verify/verifyCacheResponse';
import { useParams } from 'react-router-dom';
import { getAnimeDatabase, getDataAndCache } from '../../../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { createExpiration } from '../../../utils/time';
import { FormControl, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import { Refresh, Sort } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function AnimeSeasons({ season, setSeason, loading, setLoading }) {
    const { t } = useTranslation();
    const [ seasons, setSeasons ] = useState([]);
    const params = useParams();

    async function getSeasons() {
        const animeID = params.id.split('-')[0];

        try {
            const { res } = await getDataAndCache({
                cacheLocation: `anime`,
                cacheID: `${animeID}/s`,
                fetchLocation: `animes/${animeID}/seasons/info`,
                expirationDate: {
                    value: 3,
                    unit: 'months'
                }
            })

            setSeason(res.data[0]);
            setSeasons(res.data);
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener season:', error)
        }
    }

    const handleSeason = (event) => {
        console.log(seasons.find(x => x.id === event.target.value))
        setSeason(seasons.find(x => x.id === event.target.value));
    }

    const handleRefresh = () => {

    }

    useEffect(() => {
        if (loading) {
            getSeasons();
        }
    }, [loading, season])

    if (loading) return (
        <SeasonsLazy />
    )
    
    return (
        <div className="episode-layout-header">
            <div className="seasons">
                <FormControl size='small' fullWidth>
                    <Select
                        className='select'
                        value={season.id}
                        onChange={handleSeason}
                    >
                        {seasons.map((doc) => (
                            <MenuItem key={doc.id} className='button' value={doc.id}>
                                {`[${t('words.shorts.season')}${doc.season}] ${doc.name}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Tooltip title={t('words.refresh')}>
                    <IconButton onClick={() => handleRefresh(true)} className='IconButton'>
                        <Refresh />
                    </IconButton>
                </Tooltip>
            </div>
            {/* <div className="sort-episode">
                <FormControl size='small' fullWidth>
                    <Sort className='sort-icon '/>
                    <Select
                        value={filtered}
                        onChange={handleFilter}
                        className='select'
                    >
                        <MenuItem className='button' value={'ascending'}>De Menor a Mayor</MenuItem>
                        <MenuItem className='button' value={'descending'}>De Mayor a Menor</MenuItem>
                    </Select>
                </FormControl>
            </div> */}
        </div>
    )
}
