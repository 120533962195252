import { createBrowserRouter } from "react-router-dom";
import Layout from "../page/Layout";
import Home from "../page";
import Login from "../page/auth/Login";
import Register from "../page/auth/Register";
import Settings from "../page/settings";
import Preferences from "../page/settings/preferences";
import Language from "../page/settings/language";
import Privacy from "../page/settings/privacy";
import Ads from "../page/settings/ads";
import CompleteAuth from "../page/auth/CompleteAuth";
import User from "../page/user";
import PremiumInfo from "../page/premium";
import Community from "../page/settings/community";
import Suggest from "../page/settings/community/Suggest";
import Report from "../page/settings/community/Report";
import Anime from "../page/anime";
import Watch from "../page/watch/Watch";
import Search from "../page/search";
import Premium from "../page/settings/premium";
import History from "../page/settings/premium/History";
import Policy from "../page/settings/privacy/Policy";
import Terms from "../page/settings/privacy/Terms";
import Genres from "../page/genres/Genres";
import { Profile } from "../page/settings/profile";
import { Account } from "../page/settings/account";
import CreateProfile from "../page/auth/CreateProfile";
import AuthLayout from "../page/auth/_layout";
import Request from "../page/settings/community/request/index";
import RequestIndex from "../page/settings/community/request/Request";
import PrivateRequest from "../page/settings/community/request/PrivateRequest";
import PublicRequest from "../page/settings/community/request/PublicRequest";
import Appearance from "../page/settings/appearance";
import { AnimeLanding } from "../page/anime/AnimeLanding";
import AppLayout from "../page/AppLayout";
import AuthenticationLayout from "../page/AuthenticationLayout";

export const AoRouter = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <AuthenticationLayout />,
                children: [
                    {
                        path: "/",
                        element: <Layout />,
                        children: [
                            {
                                index: true,
                                element: <Home />
                            },
                            {
                                path: "search",
                                element: <Search />,
                                children: [
                                    {
                                        path: ":search",
                                        element: <Search />
                                    }
                                ]
                            },
                            {
                                path: "anime",
                                children: [
                                    {
                                        index: true,
                                        element: <Anime />,
                                    },
                                    {
                                        path: ":id",
                                        element: <AnimeLanding />,
                                        children: [
                                            {
                                                path: ":action"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: "watch/:id",
                                element: <Watch />
                            },
                            {
                                path: "genres/:genre",
                                element: <Genres />
                            },
                            {
                                path: "u/:uid",
                                children: [
                                    {
                                        index: true,
                                        element: <User />
                                    }
                                ]
                            },
                            {
                                path: "premium",
                                element: <PremiumInfo />
                            },
                            {
                                path: "ad",
                                children: [
                                    {
                                        path: ":ad",
                                        element: <div></div>
                                    }
                                ]
                            },
                            {
                                path: "settings",
                                element: <Settings />,
                                children: [
                                    {
                                        path: "profile",
                                        element: <Profile />
                                    },
                                    {
                                        path: "account",
                                        element: <Account />
                                    },
                                    {
                                        path: "appearance", 
                                        element: <Appearance />
                                    },
                                    {
                                        path: "language",
                                        element: <Language />
                                    },
                                    {
                                        path: "suggest",
                                        element: <Suggest />
                                    },
                                    {
                                        path: "report",
                                        element: <Report />
                                    },
                                    {
                                        path: "request",
                                        element: <Request />,
                                        children: [
                                            {
                                                index: true,
                                                element: <RequestIndex />
                                            },
                                            {
                                                path: "private",
                                                element: <PrivateRequest />
                                            },
                                            {
                                                path: "public",
                                                element: <PublicRequest />
                                            }
                                        ]
                                    },
                                    {
                                        path: "preferences",
                                        children: [
                                            {
                                                index: true,
                                                element: <Preferences />,
                                            },
                                            {
                                                path: "appearance",
                                                element: <Appearance />
                                            },
                                            {
                                                path: "language",
                                                element: <Language />
                                            }
                                        ]
                                    },
                                    {
                                        path: "privacy",
                                        children: [
                                            {
                                                index: true,
                                                element: <Privacy />
                                            },
                                            {
                                                path: "policy",
                                                element: <Policy />
                                            },
                                            {
                                                path: "terms",
                                                element: <Terms />
                                            }
                                        ]
                                    },
                                    {
                                        path: "ads",
                                        children: [
                                            {
                                                index: true,
                                                element: <Ads />
                                            }
                                        ]
                                    },
                                    {
                                        path: "premium",
                                        children: [
                                            {
                                                index: true,
                                                element: <Premium />
                                            },
                                            {
                                                path: "information",
                                                element: <PremiumInfo />
                                            },
                                            {
                                                path: "history",
                                                element: <History />
                                            },
                                            {
                                                path: "gift",
                                                element: <div>Reclamar regalo</div>
                                            }
                                        ]
                                    },
                                    {
                                        path: "community",
                                        children: [
                                            {
                                                index: true,
                                                element: <Community />
                                            },
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "auth",
                        element: <AuthLayout />,
                        children: [
                            {
                                path: "login",
                                element: <Login />
                            },
                            {
                                path: "register",
                                element: <Register />
                            },
                            {
                                path: "profile-create",
                                element: <CreateProfile />
                            },
                            {
                                path: "complete-auth",
                                element: <CompleteAuth />
                            }
                        ]
                    },
                ]
            }
        ]
    }
])