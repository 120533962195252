import React from "react";
import { Button, CircularProgress } from "@mui/material";

interface BasicButtonProps {
    title: string;
    description?: string;
    icon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    onClick?: VoidFunction;
    className?: string;
    component?: React.ElementType; 
    to?: string;
    loading?: boolean;
    variant?: 'primary' | 'secondary' | 'text' | 'high';
    type?: 'submit' | 'button' | 'reset';
    disabled?: boolean;
    fontSize?: number;
    fullWidth?: boolean;
}

const BasicButton: React.FC<BasicButtonProps> = ({
    title,
    description,
    icon,
    rightIcon,
    onClick,
    className,
    component,
    to,
    loading,
    variant,
    type,
    disabled,
    fontSize,
    fullWidth
}) => {
    return (
        <Button 
            onClick={onClick} 
            className={`button ${icon && !title ? 'icon' : ''} ${title && !description ? 'no-desc' : ''} ${fullWidth ? 'full-width' : ''} ${variant === 'high' ? 'high' : ''} ${variant ? `variant-${variant}` : ''} basic-button ${!loading ? '' : 'with-loading'} ${className} ${icon ? 'hasIcon' : ''}`}
            {...(component && { component })}
            {...(to && { to })}
            {...(type && { type })}
            {...(disabled && {disabled})}
        >
            {icon && <div className="icon" style={{ fontSize: fontSize }}>{icon}</div>}
            
            {loading &&
                <div className="progress">
                    <CircularProgress className='progress-circular' />
                </div>
            }
            <div className="body">
                <span className={`${description ? 'title' : ''}`} style={{ fontSize: fontSize }}>{title}</span>
                {description && <span className="description">{description}</span>}
            </div>
            {rightIcon && <div className="icon" style={{ fontSize: fontSize }}>{rightIcon}</div>}
        </Button>
    )
}

export default BasicButton;