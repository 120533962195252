function YugaIcon({ className }){
    return(
        <svg 
            data-encore-id="icon" 
            role="img" 
            aria-hidden="true" 
            className={`svg ${className}`}  
            viewBox="0 0 27 46"
        >
            <path d="M24.504 12.4881C25.0587 12.4881 25.5067 12.6801 25.848 13.0641C26.232 13.4481 26.424 13.8961 26.424 14.4081V32.4561C26.424 36.6801 25.2294 39.9014 22.84 42.1201C20.4507 44.3387 17.2294 45.4481 13.176 45.4481C9.16537 45.4481 5.96536 44.3387 3.57603 42.1201C1.22936 39.9014 0.0560303 36.6801 0.0560303 32.4561V14.4081C0.0560303 13.8961 0.226697 13.4481 0.56803 13.0641C0.95203 12.6801 1.42136 12.4881 1.97603 12.4881C2.5307 12.4881 2.9787 12.6801 3.32003 13.0641C3.70403 13.4481 3.89603 13.8961 3.89603 14.4081V32.4561C3.89603 35.5281 4.7067 37.8747 6.32803 39.4961C7.99203 41.0747 10.2747 41.8641 13.176 41.8641C16.12 41.8641 18.424 41.0747 20.088 39.4961C21.752 37.8747 22.584 35.5281 22.584 32.4561V14.4081C22.584 13.8961 22.7547 13.4481 23.096 13.0641C23.4374 12.6801 23.9067 12.4881 24.504 12.4881ZM18.744 6.66405C17.8907 6.66405 17.2294 6.45072 16.76 6.02405C16.3334 5.55472 16.12 4.91472 16.12 4.10405V3.46405C16.12 2.65338 16.3547 2.03472 16.824 1.60805C17.2934 1.13872 17.9547 0.904053 18.808 0.904053C19.6187 0.904053 20.2374 1.13872 20.664 1.60805C21.1334 2.03472 21.368 2.65338 21.368 3.46405V4.10405C21.368 4.95738 21.1334 5.59739 20.664 6.02405C20.1947 6.45072 19.5547 6.66405 18.744 6.66405ZM7.73603 6.66405C6.8827 6.66405 6.22136 6.45072 5.75203 6.02405C5.32536 5.55472 5.11203 4.91472 5.11203 4.10405V3.46405C5.11203 2.65338 5.3467 2.03472 5.81603 1.60805C6.28536 1.13872 6.92536 0.904053 7.73603 0.904053C8.58936 0.904053 9.22936 1.13872 9.65603 1.60805C10.1254 2.03472 10.36 2.65338 10.36 3.46405V4.10405C10.36 4.95738 10.1254 5.59739 9.65603 6.02405C9.1867 6.45072 8.5467 6.66405 7.73603 6.66405Z" fill="white"/>
        </svg>
    )
}

export default YugaIcon;