import { CircularProgress } from '@mui/material'
import React from 'react'

export default function ReproductorLoading() {
    return (
        <div className="video-layout">
            <div className="reproductor">
                <div className="reproductor-msg">
                    <div className="loading progress"><CircularProgress className='progress-circular' /></div>
                </div>
            </div>
        </div>
    )
}
