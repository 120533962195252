import { collection, getDocs, query, where } from 'firebase/firestore';
import { usersFirestore } from '../../services/firebase/firebase';

interface VerifyUserNameProps {
  text: string;
}

interface VerifyUserNameResult {
  success: boolean;
  message: string;
  isValid: boolean;
  isValidating: boolean;
  code: number;
}

const verifyUserName = (() => {
  let typingTimer: NodeJS.Timeout;
  const pattern = new RegExp(/^[0-9a-zA-Z.áéíñóúüÁÉÍÑÓÚÜ_-]+$/);

  return ({ text }: VerifyUserNameProps): Promise<VerifyUserNameResult> => {
    return new Promise((resolve, reject) => {
      clearTimeout(typingTimer);

      if (text.includes("desu") || text.includes('aodesu')) {
        return reject({
          success: false,
          isValid: false,
          isValidating: false,
          message: 'El texto contiene "desu", lo cual no es permitido.',
        });
      }

      if (text.length < 4) {
        return reject({
          success: false,
          isValid: false,
          isValidating: false,
          message: 'Corto.',
          code: 4
        });
      }

      if (text.length > 15) {
        return reject({
          success: false,
          isValid: false,
          isValidating: false,
          message: 'El texto es demasiado largo.',
          code: 2
        });
      }

      if (text.length > 3 && text.length < 17) {
        typingTimer = setTimeout(async () => {
          try {
            if (!pattern.test(text)) {
              return reject({
                success: true,
                isValid: false,
                isValidating: false,
                message: 'El texto tiene caracteres no permitidos',
                code: 3
              });
            }

            const userRef = collection(usersFirestore, `users`);
            const lowerCaseUsername = text.toLocaleLowerCase();

            const q = query(userRef, where('data', 'array-contains', lowerCaseUsername));
            const querySnapshot = await getDocs(q);

            const isEmpty = querySnapshot.empty;

            console.log(isEmpty)

            if (!isEmpty) {
              return reject({
                success: true,
                isValid: false,
                isValidating: false,
                message: 'El usuario está tomado',
                code: 1
              });
            } else {
              return resolve({
                success: true,
                isValid: true,
                isValidating: false,
                message: '¡Correcto!',
                code: 0
              });
            }
          } catch (error) {
            return reject({
              success: false,
              isValid: false,
              isValidating: false,
              message: 'Error en la validación',
              code: 5
            });
          }
        }, 1000);
      }
    });
  };
})();

export default verifyUserName;
