import React, { useState } from 'react';
// @ts-ignore
import { LazyLoadImage as LazyLoadImageComponent } from 'react-lazy-load-image-component';

interface LazyLoadImageProps {
    className?: string;
    style?: object;
    figureStyle?: object;
    src: string;
    srcSet?: string;
    width?: number;
    height?: number;
    isRadius?: boolean;
    borderRadius?: number;
    aspectRatio?: number;
    blur?: number;
    alt?: string;
    onLoad?: VoidFunction;
    cached?: boolean;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({ 
    className, 
    style, 
    figureStyle,
    src, 
    srcSet,
    width, 
    height, 
    isRadius, 
    borderRadius, 
    aspectRatio, 
    blur, 
    alt,
    onLoad,
    cached,
    ...props
}) => {
    const [loaded, setLoaded] = useState(false);

    const url = () => {
        if (cached) {
            return `${src}`;
        }

        return `${src}?t=${Date.now()}`;
    }

    return (
        <div 
            style={{ 
                width: width ? width : '100%', 
                height: height ? height : '100%', 
                borderRadius: borderRadius ? borderRadius : isRadius ? 1000 : 0,
                aspectRatio: aspectRatio && aspectRatio, 
                ...style
            }} 
            className={`lazy image ${blur ? `blur-${blur}` : ''} ${loaded && 'loaded'} ${className}`}>
            <figure
                style={{
                    ...figureStyle
                }}
            >
                <picture className={`image-body`}>
                    <source srcSet={url()} type='image' />
                    {/* @ts-ignore */}
                    <LazyLoadImageComponent 
                        src={url()}
                        srcSet={url()}
                        alt={alt}
                        onLoad={(e) => { 
                            let image = e.target as HTMLImageElement; 

                            if (image.naturalWidth < 10) {
                                setLoaded(false);
                                return;
                            }

                            if (onLoad) {
                                onLoad();
                            }
                            
                            setLoaded(true);
                        }}
                    />
                </picture>
            </figure>
        </div>
    );
}

export default LazyLoadImage;