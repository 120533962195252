import { Firestore } from "firebase/firestore";
import getDBLang from "../../components/hooks/getDBLang";
import { animeEnFirestore, animeEnStorage, animeEsFirestore, animeEsStorage } from "../../services/firebase/firebase";

interface AnimeDatabase {
    firestore: Firestore;
    storage: object;
}

function getAnimeDatabase(): AnimeDatabase {
    const lang = getDBLang();

    if (lang === 'es') {
        return {
            firestore: animeEsFirestore,
            storage: animeEsStorage,
        }
    } else {
        return {
            firestore: animeEnFirestore,
            storage: animeEnStorage,
        }
    }
}

export default getAnimeDatabase;