import React, { useCallback, useContext, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/Auth';
import { Modal, ModalManagerProvider, useModalManager } from '../components/modals';
import { ProfileSelector } from '../components/modals/dist';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../services/firebase/firebase';
import { loadProfile } from '../utils/firebase/profile';
import { hasExpired } from '../utils/time';
import { CircularProgress } from '@mui/material';

export default function AuthenticationLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, existsAccount, refreshing, refreshingProfile, profile, setProfile, setRefreshing } = useContext(AuthContext);
    const { openModal, closeModal } = useModalManager();

    const handleCloseProfileSelector = () => {
        closeModal('profile-selector');
    }

    const handleOpenProfileSelector = () => {
        openModal('profile-selector', (
        <Modal 
            variant='fs'
            close={handleCloseProfileSelector}
        >
            <ProfileSelector />
        </Modal>
        ))
    }

    const loadProfileFromLocalStorage = async () => {
        const localData = localStorage.getItem('current_profile');
        if (localData) {
            const data = JSON.parse(localData);
            if (!hasExpired(data.expire).expired) {
                // console.log(1)
                const response = await loadProfile({ profile: data.profile, setRefreshing });
                setProfile(response);
            } else {
                // console.log(2)
                // console.log('Perfil en localStorage expirado.');
                handleOpenProfileSelector();
            }
        } else {
            // console.log(3)
            handleOpenProfileSelector();
        }
    };

    useEffect(() => {
        const handleProfileLogic = async () => {
            try {

                if (existsAccount === undefined) {
                    return;
                }

                if (refreshingProfile) return;

                if (account === null) {
                    setProfile(null);
                    setRefreshing(false);
                    return;
                }

                if (location.pathname.split('/')[1] === 'auth') {                    
                    if (existsAccount) {
                        if (location.pathname.split('/')[2] === 'profile-create') {
                            setRefreshing(false);
                            handleCloseProfileSelector();
                            return;
                        }
                        navigate('/');
                        return;
                    }

                    if (location.pathname.split('/')[2] === 'profile-create' || location.pathname.split('/')[2] === 'complete-auth') {
                        if (account === null) {
                            navigate('/');
                        }
                    }
                    
                    setRefreshing(false);
                    return;
                }

                if (account) {
                    setRefreshing(true);
                }

                if (!existsAccount) {
                    navigate('/auth/complete-auth');
                    return;
                }

                if (profile === undefined) {
                    await loadProfileFromLocalStorage();
                    return;
                }

                if (profile === null) {
                    handleOpenProfileSelector();
                    return;
                }

                if (profile) {
                    handleCloseProfileSelector();
                    setRefreshing(false);
                    return;
                }
            } catch (error) {
                console.error('Error manejando la lógica del perfil.')
            }
        }

        handleProfileLogic();
    }, [account, profile, refreshing, location.pathname]);

    useEffect(() => {
    }, [location.pathname])

    if (refreshing) {
        return (
            <div className="refreshing">
                <div className="progress">
                    <CircularProgress className='circular-progress' />
                </div>
            </div>
        )
    }

    return <Outlet />
}
