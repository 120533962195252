import React from 'react';
import { Button } from "@mui/material";

interface ButtonProps {
    onClick: VoidFunction;
    title: string;
    icon?: React.ReactNode;
}

interface ButtonGroupProps {
    buttons: ButtonProps[];
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
    buttons
}) => {
    return (
        <div className='flex w100 gap-3 padding-5'>
            {buttons.map((doc, i) => (
                <Button 
                    className='button button-group' 
                    key={i}
                    onClick={doc.onClick}
                    style={{
                        borderTopLeftRadius: i === 0 ? 100 : 5, // Radio en el borde izquierdo del primer botón
                        borderBottomLeftRadius: i === 0 ? 100 : 5, // Radio en el borde izquierdo del primer botón
                        borderTopRightRadius: i === buttons.length - 1 ? 100 : 5, // Radio en el borde derecho del último botón
                        borderBottomRightRadius: i === buttons.length - 1 ? 100 : 5, // Radio en el borde derecho del último botón
                    }}
                >
                    {doc.icon}
                    <span>{doc.title}</span>
                </Button>
            ))}
        </div>
    )
}

export default ButtonGroup;