interface VerifyNameProps {
  text: string;
}

interface VerifyNameResult {
  success: boolean;
  message: string;
  isValid: boolean;
  isValidating: boolean;
  code: number;
}

const verifiyName = (() => {
  let typingTimer: NodeJS.Timeout;
  const pattern = new RegExp(/^[0-9a-zA-Z.áéíñóúüÁÉÍÑÓÚÜ_\-\s]+$/);

  return ({ text }: VerifyNameProps): Promise<VerifyNameResult> => {
    return new Promise((resolve, reject) => {
      clearTimeout(typingTimer);
      
      if (text.includes("desu") || text.includes('aodesu')) {
        return reject({
          success: false,
          isValid: false,
          isValidating: false,
          message: 'El texto contiene "desu", lo cual no es permitido.',
        });
      }

      if (text.length < 4) {
        return reject({
          success: false,
          isValid: false,
          isValidating: false,
          message: 'Corto.',
          code: 4
        });
      }

      if (text.length > 15) {
        return reject({
          success: false,
          isValid: false,
          isValidating: false,
          message: 'El texto es demasiado largo.',
          code: 2
        });
      }

      typingTimer = setTimeout(() => {
        if (!pattern.test(text)) {
          return reject({
            success: true,
            isValid: false,
            isValidating: false,
            message: 'El texto tiene caracteres no permitidos',
            code: 3
          });
        }
  
        return resolve({
          success: true,
          isValid: true,
          isValidating: false,
          message: '¡Perfecto!',
          code: 0
        })
      }, 1000);
    })
  }
})();

export default verifiyName;